var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "basic-panel",
        _vm.panelDirection,
        _vm.panelClasses,
        {
          "bookmarks-shown": _vm.bookmarksShown,
          "draggable-panel": _vm.draggablePanel,
          opened: _vm.panelOpened
        }
      ]
    },
    [
      _vm.draggablePanel
        ? _c("BasicButton", {
            attrs: {
              buttonClasses: ["panel-handle", "draggable-handle"],
              iconName: "iconDrag"
            }
          })
        : _vm._e(),
      _vm.handleIcon
        ? _c("BasicButton", {
            attrs: {
              buttonClasses: ["panel-handle", "click-handle"],
              clickAction: _vm.togglePanel,
              clickParams: ".panel-wrapper." + _vm.panelDirection,
              iconName: _vm.handleIcon
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          class: [
            "panel-wrapper",
            {
              opened: _vm.panelOpened
            }
          ]
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }