import MapCanvas from "./components/Thoughts/MapCanvas";
import Timeline from "./components/Timeline/Timeline";

export const routes = [
  { path: "", component: MapCanvas, redirect: "/thought" },
  { name: "browseThought", path: "/thought", component: MapCanvas, children: [
    { name: "thought", path: ":id", component: MapCanvas },
  ] },
  { path: "/timeline", component: Timeline, name: "timeline" },
  { path: "*", redirect: "/"},
];
