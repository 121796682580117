var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FenixTimeline", {
    attrs: {
      data: _vm.timelineData,
      categories: _vm.categories,
      companies: _vm.companies,
      customFilters: _vm.customFilters,
      eventTypes: _vm.eventTypes,
      subcategories: _vm.subcategories,
      searchKeyword: _vm.searchKeyword,
      searchInputFocus: _vm.searchInputFocus,
      timelineTitle: _vm.timelineTitle
    },
    on: { passYearData: _vm.onYearDataRequest }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }