import * as getters from "./searchGetters";
import * as mutations from "./searchMutations";
import * as actions from "./searchActions";

export default {
  state: {
    mobileSearchSelected: false,
    searchKeyword: undefined,
    searchResult: undefined,
    mobileSearchShown: false,
  },
  getters,
  mutations,
  actions,
}
