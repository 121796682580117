import * as d3 from "d3";
import { isEmpty } from "lodash";

export default {
  methods: {
    setLinkPaths(selection) {
      const linksPlaceholder = d3.select(".map-canvas .thoughts-group .thought-links-placeholder");

      if(linksPlaceholder.empty()) {
        d3.select(".map-canvas .thoughts-group")
          .append("g")
          .classed("thought-links-placeholder", true);
      }

      d3.selectAll(selection)
        .each(itm => {
          this.renderRelations(itm);
        });
    },
    renderLinks(sourceId, targetId) {
      d3.select(".thought-link-" + sourceId + "-" + targetId).remove();
      d3.select(".map-canvas .thoughts-group")
      .select(".thought-links-placeholder")
      .append("path")
      .style("fill", "none")
      .attr("class", "thought-link-" + sourceId + "-" + targetId)
      .attr("d", () => {
        const source = d3.select(".map-canvas .thoughts-group").select(".thought-node-" + sourceId);
        const target = d3.select(".map-canvas .thoughts-group").select(".thought-node-" + targetId);

        if(!source.empty() && !target.empty()) {
          const sourceProps = this.getSelectionProps(source);
          const targetProps = this.getSelectionProps(target);
          const sourceParentContainer = d3.select(d3.select(source.node().parentNode).node().parentNode);
          const targetParentContainer = d3.select(d3.select(target.node().parentNode).node().parentNode);

          let targetX;
          let sourceX;
          let targetY;
          let sourceY;
          let cFactorA;
          let cFactorB;
          let cFactorC;

          sourceX = sourceProps.x + sourceProps.width * 0.5;
          sourceY = sourceProps.y;
          targetX = targetProps.x + targetProps.width * 0.5;
          targetY = targetProps.y - 300;

          if(targetParentContainer.classed("thought-children") && sourceParentContainer.classed("main-thought")) {
            const hasRelatedSiblings = source.datum().data.gates.has_siblings;
            const sourceXgap = hasRelatedSiblings ? sourceProps.width * 0.5 : sourceProps.width * 0.5 - 1;

            sourceX = sourceProps.x + sourceXgap;
            sourceY = sourceProps.y - 20;
            targetX = targetProps.x + targetProps.width * 0.5 - 1;
            targetY = targetProps.y - 60;
          }

          if(targetParentContainer.classed("thought-parents") && sourceParentContainer.classed("main-thought")) {
            const hasRelatedSiblings = source.datum().data.gates.has_siblings;
            const sourceXgap = hasRelatedSiblings ? sourceProps.width * 0.5 : sourceProps.width * 0.5 - 1;

            sourceX = sourceProps.x + sourceXgap;
            sourceY = sourceProps.y - 60;
            targetX = targetProps.x + targetProps.width * 0.5 - 1;
            targetY = targetProps.y - 20;
          }

          if(targetParentContainer.classed("thought-related-siblings") && sourceParentContainer.classed("thought-parents")) {
            sourceX = sourceProps.x + sourceProps.width * 0.5;
            sourceY = sourceProps.y - 20;
            targetX = targetProps.x + targetProps.width;
            targetY = targetProps.y - 41.5;
          }

          if(targetParentContainer.classed("thought-related-siblings") && sourceParentContainer.classed("main-thought")) {
            sourceX = sourceProps.x;
            sourceY = sourceProps.y - 41.5;
            targetX = targetProps.x + targetProps.width;
            targetY = targetProps.y - 41.5;
          }

          if(source.datum().isOpen && sourceParentContainer.classed("thought-parents") && targetParentContainer.classed("thought-siblings")) {
            sourceX = sourceProps.x + sourceProps.width * 0.5 - 1;
            sourceY = sourceProps.y - 20;
            targetX = targetProps.x + targetProps.width * 0.5 - 1;
            targetY = targetProps.y - 59;
          }

          if(!source.datum().isOpen && sourceParentContainer.classed("thought-parents") && targetParentContainer.classed("thought-siblings")) {
            return "";
          }

          const thoughtsGroupTransform = this.getSelectionTransform(d3.select(".thoughts-group"));
          const thoughtsGroupTranslate = thoughtsGroupTransform.translate;

          if(!isEmpty(thoughtsGroupTransform)) {
            if(thoughtsGroupTranslate) {
              sourceX -= Number(thoughtsGroupTranslate[0]);
              targetX -= Number(thoughtsGroupTranslate[0]);
              sourceY -= Number(thoughtsGroupTranslate[1]);
              targetY -= Number(thoughtsGroupTranslate[1]);
            }
          }

          cFactorA = ((targetX + sourceX) * 0.5)
          cFactorB = ((targetX + sourceX) * 0.5)
          cFactorC = sourceY

          if(
            targetParentContainer.classed("thought-children") && sourceParentContainer.classed("thought-parents") ||
            targetParentContainer.classed("thought-parents") && sourceParentContainer.classed("thought-parents")
          ) {
            return "";
          }

          return "M" + sourceX + "," + sourceY
          + "C" + cFactorA  + "," + targetY
          + " " + cFactorB + "," + cFactorC
          + " " + targetX + "," + targetY;
        }
      });

      d3.select(".map-canvas .thoughts-group")
        .select(".thoughts-placeholder")
        .raise();
    },
    renderRelations(itm) {
      if(itm.selected) {
        itm.data.relations.forEach(relItm => {
          this.renderLinks(itm.data.thought_id, relItm.data.thought_id);
        });
      } else {
        if(itm.thoughtParent) {
          if(itm.data.relations) {
            itm.data.relations.forEach(relItm => {
              if(relItm.data.thought_id !== this.$route.params.id) {
                this.renderLinks(itm.data.thought_id, relItm.data.thought_id);
              }
            });
          }
        }

        d3.selectAll(this.thoughtLinkSelection)._groups[0]
          .forEach(itm => {
            if(!d3.select(itm).attr("d")) {
              d3.select(itm).remove();
            }
          });
      }
    }
  }
}
