import * as d3 from "d3";
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as generalConfig from "../../../configs/generalConfig";


export default {
  computed: {
    ...mapGetters([
      "getMainSettingsInfo",
      "getPanelsInfo"
    ])
  },
  watch: {
    getPanelsInfo(panelsInfo) {
      this.panelsInfo = panelsInfo;
      this.setCurrentStepSettings();
    }
  },
  methods: {
    ...mapActions([
      "closeMobileMenu",
      "setMobileSearchSelected"
    ]),
    ...mapMutations([
      "setUIresets",
      "setUItransformations",
      "setMobileUItransformations"
    ]),
    resetMobileStepOne()  {
      this.setMobileSearchSelected(false);
      this.setSearchKeyword(undefined);
      this.setMobileSearchShown(false);
      this.restoreMobileFeature(".thoughts-search");
      this.restoreMobileFeature(".app-header .bookmark-cta-search");
      d3.select(".tour-overlay.intermediate")
        .classed("step-1", false);
    },
    resetMobileStepTwo() {
      this.restoreMobileFeature(".header-button-mobile-home");
    },
    resetMobileStepThree() {
      if(!this.goingForward || this.isClosing) {
        this.toggleMobileMenu();
      }

      this.restoreMobileFeature(".mobile-header-menu");
    },
    resetMobileStepFour() {
      if(this.isClosing) {
        this.toggleMobileMenu();
      }
    },
    resetMobileStepFive() {
      if(this.isClosing) {
        this.toggleMobileMenu();
      }
    },
    resetMobileStepSix() {
      if(this.goingForward || this.isClosing) {
        this.toggleMobileMenu();
      }
      this.restoreMobileFeature(".mobile-header-menu");
    },
    resetMobileStepSeven() {
      d3.select(".tour-overlay.intermediate")
        .classed("step-7", false);
      d3.select(".labeled-button.panel-handle.click-handle.right .button-label")
        .style("opacity", 1);
      d3.select(".labeled-button.panel-handle.click-handle.right .basic-icon")
        .style("opacity", 1);

      this.setCurrentMobilePanel(undefined);
      this.updatePanelHeight(".basic-mobile-panel.left", 0);
      this.setMobilePanelHeight({
        panelInstance: "left",
        height: 0
      });

      this.restoreMobileFeature(".mobile-panels-content");
    },
    resetMobileStepEight() {
    },
    resetMobileStepNine() {
      d3.select(".tour-overlay.intermediate")
        .classed("step-9", false);
      d3.select(".labeled-button.panel-handle.click-handle.left .button-label")
        .style("opacity", 1);
      d3.select(".labeled-button.panel-handle.click-handle.left .basic-icon")
        .style("opacity", 1);

      const closeDetailsPanelTO = setTimeout(() => {
        this.updatePanelHeight(".basic-mobile-panel.right", 0);
        this.setMobilePanelHeight({
          panelInstance: "right",
          height: 0
        });
        this.setCurrentMobilePanel(undefined);
        clearTimeout(closeDetailsPanelTO);
        this.restoreMobileFeature(".mobile-panels-content");
      }, 100);
    },
    setMobileTourUI() {
      if(this.getMainSettingsInfo["show_timeline_button"]) {
        const UItransformations = [
          this.setMobileStepZero,
          this.setMobileStepOne,
          this.setMobileStepTwo,
          this.setMobileStepThree,
          this.setMobileStepFour,
          this.setMobileStepFive,
          this.setMobileStepSix,
          this.setMobileStepSeven,
          this.setMobileStepEight,
          this.setMobileStepNine,
          this.setFinalStep,
        ];

        this.setUItransformations(UItransformations);
      } else {
        const UItransformations = [
          this.setMobileStepZero,
          this.setMobileStepOne,
          this.setMobileStepTwo,
          this.setMobileStepThree,
          this.setMobileStepFour,
          this.setMobileStepSix,
          this.setMobileStepSeven,
          this.setMobileStepEight,
          this.setMobileStepNine,
          this.setFinalStep,
        ];

        this.setUItransformations(UItransformations);
      }
    },
    setMobileTourResets() {
      if(this.getMainSettingsInfo["show_timeline_button"]) {
        this.UIresets = [
          undefined,
          this.resetMobileStepOne,
          this.resetMobileStepTwo,
          this.resetMobileStepThree,
          this.resetMobileStepFour,
          this.resetMobileStepFive,
          this.resetMobileStepSix,
          this.resetMobileStepSeven,
          this.resetMobileStepEight,
          this.resetMobileStepNine,
        ];

        this.setUIresets(this.UIresets);
      } else {
        this.UIresets = [
          undefined,
          this.resetMobileStepOne,
          this.resetMobileStepTwo,
          this.resetMobileStepThree,
          this.resetMobileStepFour,
          this.resetMobileStepSix,
          this.resetMobileStepSeven,
          this.resetMobileStepEight,
          this.resetMobileStepNine,
        ];

        this.setUIresets(this.UIresets);
      }
    },
    setMobileStepZero() {

    },
    setMobileStepOne()  {
        if(this.getMobileMenuShown) {
          this.toggleMobileMenu();
        }
        this.setMobileSearchSelected(true);
        this.setMobileSearchShown(true);
        this.setSearchKeyword(this.getTourInfo.steps[1]['search_term']);
        this.searchThoughtByString(this.getTourInfo.steps[1]['search_term']);

        const stepOneTO = setTimeout(() => {
          this.highlightMobileFeature(".thoughts-search");
          this.highlightMobileFeature(".app-header .bookmark-cta-search");
          d3.select(".tour-overlay.intermediate")
            .classed("step-1", true);

            clearTimeout(stepOneTO);
        });

        const dialogArrow = d3.select(".dialog-arrow");
        const searchInput = d3.select(".app-header .bookmark-cta-search");
        const searchInputProps = searchInput.node().getBoundingClientRect();
        const dialogBox = d3.select(".dialog-box");

        dialogArrow
          .classed("hide", false)
          .classed("ninety-negative", false)
          .classed("zero", true)
          .classed("zero-negative", false)
          .classed("ninety", false)
          .style("width", "300px")
          .style("top", "45px")
          .style("left", `${searchInputProps.left + 15}px`);

        dialogBox
          .style("bottom", null)
          .style("top", "59px")
          .style("right", "20px");
    },
    setMobileStepTwo()  {
      const dialogArrow = d3.select(".dialog-arrow");
      const homeButton = d3.select(".header-button-mobile-home");
      const homeButtonProps = homeButton.node().getBoundingClientRect();
      const dialogBox = d3.select(".dialog-box");

      dialogArrow
        .classed("hide", false)
        .classed("ninety-negative", false)
        .classed("zero", true)
        .classed("ninety", false)
        .style("width", "300px")
        .style("top", "45px")
        .style("left", `${homeButtonProps.left + 7}px`);

      dialogBox
        .style("top", "59px")
        .style("left", "auto")
        .style("right", "20px");

      const mobileStepTwoTO = setTimeout(() => {
        this.closeMobileMenu();
        this.highlightMobileFeature(".header-button-mobile-home");
        clearTimeout(mobileStepTwoTO);
      }, 300);
    },
    setMobileStepThree()  {
      this.setMobileMenuSteps()
        .then(() => {
          this.setMobileStepsThreeToSix(".header-button-pinned");
          this.restoreMobileFeature(".bookmark-cta-search");
        });
    },
    setMobileStepFour()  {
      this.setMobileMenuSteps()
        .then(() => {
          this.setMobileStepsThreeToSix(".header-button-history");
        });
    },
    setMobileStepFive() {
      this.setMobileMenuSteps()
        .then(() => {
          if(window.innerHeight < 560) {
            d3.select(".dialog-box")
              .classed("height", "250px")
          } else {
            d3.select(".dialog-box")
              .style("height", "auto")
          }

          this.setMobileStepsThreeToSix(".header-button-timeline");
        })
    },
    setMobileStepSix() {
      this.setMobileMenuSteps()
        .then(() => {
          this.setMobileStepsThreeToSix(".header-button-tour");
        });
    },
    setMobileStepSeven() {
      const closeMobileMenuTO = setTimeout(() => {
        this.closeMobileMenu();
        clearTimeout(closeMobileMenuTO);
      })

      d3.select(".tour-overlay.intermediate")
        .classed("step-7", true);
      d3.select(".labeled-button.panel-handle.click-handle.right .button-label")
        .style("opacity", 0.3);
      d3.select(".labeled-button.panel-handle.click-handle.right .basic-icon")
        .style("opacity", 0.3);

      this.setMobilePanelHeight({
        panelInstance: "left",
        height: window.innerHeight
      });

      this.setCurrentMobilePanel("left");
      this.updatePanelHeight(".basic-mobile-panel.left", window.innerHeight);
      this.highlightMobileFeature(".mobile-panels-content");

      const dialogArrow = d3.select(".dialog-arrow");
      const dialogBox = d3.select(".dialog-box");
      dialogArrow
        .classed("hide", false)
        .classed("zero", false)
        .classed("zero-negative", true)
        .classed("ninety", false)
        .style("width", "25px")
        .style("left", "25vw")
        .style("top", null)
        .style("right", null)
        .style("bottom", "30px");

      dialogBox
        .style("height", "auto")
        .style("width", "300px")
        .style("left", "20px")
        .style("top", null)
        .style("bottom", "46px");
    },
    setMobileStepEight() {
      const mobileStepEightTO = setTimeout(() => {
        const dialogArrow = d3.select(".dialog-arrow");
        const dialogBox = d3.select(".dialog-box");

        dialogArrow
          .classed("hide", true);

        dialogBox
          .style("width", "300px")
          .style("right", null)
          .style("left", null)
          .style("top", null)
          .style("bottom", null);

        clearTimeout(mobileStepEightTO);
      }, 300);
    },
    setMobileStepNineA() {
      const stepNineTimeout = setTimeout(() => {
        d3.select(".tour-overlay.intermediate")
          .classed("step-9", true);
        d3.select(".labeled-button.panel-handle.click-handle.left .button-label")
          .style("opacity", 0.3);
        d3.select(".labeled-button.panel-handle.click-handle.left .basic-icon")
          .style("opacity", 0.3);

        this.setMobilePanelHeight({
          panelInstance: "right",
          height: window.innerHeight
        });
        this.setCurrentMobilePanel("right");
        this.updatePanelHeight(".basic-mobile-panel.right", window.innerHeight);
        this.highlightMobileFeature(".mobile-panels-content");

        clearTimeout(stepNineTimeout);
      }, 300);
    },
    setMobileStepNine() {
      const dialogArrow = d3.select(".dialog-arrow");
      const dialogBox = d3.select(".dialog-box");
      dialogArrow
        .classed("hide", false)
        .classed("zero", false)
        .classed("zero-negative", true)
        .classed("ninety", false)
        .style("width", "25px")
        .style("right", "25vw")
        .style("top", null)
        .style("left", null)
        .style("bottom", "30px");

      dialogBox
        .style("width", "300px")
        .style("right", "20px")
        .style("left", null)
        .style("top", null)
        .style("bottom", "46px");

      const detailsThoughtId = this.getMainSettingsInfo["show_timeline_button"]
        ? this.getTourInfo.steps[9]['details_guid']
        : this.getTourInfo.steps[8]['details_guid'];

      this.$router.push({ name: "thought", params: { id: detailsThoughtId } })
        .then(() => {
          this.setMobileStepNineA();
        })
        .catch(err => {
          this.setMobileStepNineA();
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (err.name != "NavigationDuplicated") {
            // But print any other errors to the console
            console.error(err);
          }
        });
    },
    setFinalStep() {
    },
    setMobileStepsThreeToSix(featureClass) {
      const dialogArrow = d3.select(".dialog-arrow");
      const featureInstance = d3.select(featureClass);
      const featureInputProps = featureInstance.node().getBoundingClientRect();
      const dialogBox = d3.select(".dialog-box");
      const dialogBoxTop = featureInputProps.top + 70;

      dialogArrow
        .classed("zero", true)
        .classed("zero-negative", false)
        .classed("ninety", false)
        .style("left", "123px")
        .style("right", null)
        .style("bottom", null)
        .style("top", `${dialogBoxTop - 14}px`);

        dialogBox
        .style("width", "300px")
        .style("right", null)
        .style("left", "30px")
        .style("bottom", null)
        .style("top", `${dialogBoxTop}px`);
    },
    setMobileMenuSteps(){
      return new Promise(resolve => {
        const setMobileMenuStepsTO = setTimeout(() => {
          if(!this.getMobileMenuShown) {
            this.toggleMobileMenu();
          }
          this.highlightMobileFeature(".mobile-header-menu");
          clearTimeout(setMobileMenuStepsTO);

          return resolve();
        }, 300);
      });
    },
    highlightMobileFeature(feature) {
      d3.select(feature)
        .classed("tour-highlight", true)
    },
    restoreMobileFeature(feature) {
      d3.select(feature)
      .classed("tour-highlight", false)
    },
    updatePanelHeight(selection, panelHeight) {
      const panelSelection = d3.select(selection + " .panel-wrapper");

      if(panelHeight > 0) {
        panelSelection.style("display", "block");

        panelSelection
          .transition()
          .duration(generalConfig.panelTransitionTime)
          .style("height", (panelHeight - 62) + "px")
          .style("top", "-" + (panelHeight - 62) + "px")
          .on("end", () => {
            d3.select(selection).node().scrollTop = 0;
            d3.select(selection + " .close-button").style("display", "flex");
          })
      } else {
        d3.select(selection + " .close-button").style("display", "none");

        panelSelection
          .transition()
          .duration(generalConfig.panelTransitionTime)
          .style("height", "40px")
          .style("top", panelHeight + "px")
          .on("end", () => {
            panelSelection.style("display", "none");
          })
      }
    },
  }
}
