import * as d3 from "d3";
import * as generalConfig from "../../../configs/generalConfig";

export default {
  methods: {
    positionSiblings(siblingsData) {
      const parentSelection = d3.select(".thought-node-" + siblingsData.parentId);
      const parentData = parentSelection.datum();
      const mainThoughtElem = d3.select(".main-thought");
      const mainThoughtElemProps = this.getSelectionProps(mainThoughtElem);
      const siblingNodes = d3.selectAll(".thought-siblings-" + siblingsData.parentId + " " + this.thoughtNodeSelection);
      const siblingsWrapper = d3.selectAll(".thought-siblings-" + siblingsData.parentId + " .thoughts-wrapper");
      const childrenWrapperProps = this.getSelectionProperties(".thought-children .thoughts-wrapper");

      if(!siblingNodes.empty() && parentData.isOpen) {
        const widestSiblingProps = this.getSelectionProps(this.getWidestThought(siblingNodes));
        const rowScreenCount = Math.floor((window.innerHeight - 62) * 0.5 / 43) + 1;
        const offX = childrenWrapperProps.width < mainThoughtElemProps.width
          ? (mainThoughtElemProps.x + mainThoughtElemProps.width) + 100
          : childrenWrapperProps.x + childrenWrapperProps.width + 100;
        const nodesOffset = {
          x: offX - this.getZoomInfo.x,
          y: mainThoughtElemProps.y - 37 - this.getZoomInfo.y
        };

        this.breakIntoRows(siblingsWrapper, rowScreenCount, widestSiblingProps, nodesOffset);
      } else {
        this.collapseSiblings(siblingsData);
      }
    },
    collapseSiblings(siblingsData) {
      const parentSelection = d3.select(".thought-node-" + siblingsData.parentId);
      const siblingNodes = d3.selectAll(".thought-siblings-" + siblingsData.parentId + " " + this.thoughtNodeSelection);
      const parentSelectionProps = this.getSelectionProps(parentSelection);
      const siblingsLinks = d3.selectAll(".thought-links-placeholder path[class^=\"" + siblingsData.parentId + "\"]");
      
      siblingNodes
        .transition()
        .duration(generalConfig.thoughtsExpansionTime)
        .ease(d3.easeQuadInOut)
        .attr("transform", () => {
          const nodeX = (parentSelectionProps.x + parentSelectionProps.width * 0.5) - this.getZoomInfo.x;
          const nodeY = (parentSelectionProps.y - 37) - this.getZoomInfo.y;

          return "translate(" + nodeX + "," + nodeY +") scale (0)";
        })
        .on("end", () => {
          siblingsLinks.remove();
          d3.selectAll(".thought-siblings-" + siblingsData.parentId).remove();
        });
    }
  }
}