/* COPY TEXTS */
export const addPinnedButtonTitle = "ADD PINNED";
export const duplicatePinnedWarningTitle = " ITEM ALREADY PINNED";
export const duplicatePinnedWarningMessage = " already exists in the Pinned items list and won't be added to avoid duplicates.";
export const removePinnedConfirmationTitle = "REMOVE A PINNED ITEM";
export const removePinnedConfirmationMessage = " will be permanently deleted from your pinned items list.<br><br>Are you sure you want to remove it?";
export const deleteHistoryButtonTitle = "DELETE HISTORY";
export const clearHistoryConfirmationTitle = "DELETE YOUR HISTORY";
export const clearHistoryConfirmationMessage = "Your history items are about to be permanently removed from this section.<br><br>Are you sure you want to continue with this action?";

/* TRANSITIONS (in ms) */
export const thoughtsExpansionTime = 250;
export const thoughtsFadeTime = 250;
export const panelTransitionTime = 250;
export const panningTime = 250;