import { forEach, forOwn } from "lodash";
import { fireStoreDatabase } from "../../../configs/firebase/databaseConfig";
import "firebase/firestore";
import * as d3 from "d3";

export const fetchSkinInfo = ({commit}) => {
  const userPreferences = fireStoreDatabase.firestore().collection("app");
  userPreferences.doc(process.env.VUE_APP_CHANNEL_PREFIX)
  .get().then(queryDoc => {
      const skinGroups = queryDoc.data()[process.env.VUE_APP_CHANNEL_SETTINGS_PATH]
        ? queryDoc.data()[process.env.VUE_APP_CHANNEL_SETTINGS_PATH].skin
        : [];

      const timelineSkinGroups = queryDoc.data()[process.env.VUE_APP_CHANNEL_SETTINGS_PATH]
        ? queryDoc.data()[process.env.VUE_APP_CHANNEL_SETTINGS_PATH]['timeline_skin']
        : [];

        const skinDictionary = {};
        const timelineSkinDictionary = {};

      forEach(skinGroups, skinGrp => {
        forOwn(skinGrp, (val, key) => {
          skinDictionary[key] = val;
        });
      });

      commit("mutate", {
        property: "skinSchemedConfig",
        value: skinGroups
      });

      commit("mutate", {
        property: "skinConfig",
        value: skinDictionary
      });

      forEach(timelineSkinGroups, skinGrp => {
        forOwn(skinGrp, (val, key) => {
          timelineSkinDictionary[key] = val;
        });
      });

      commit("mutate", {
        property: "timelineSkinSchemedConfig",
        value: timelineSkinGroups
      });

      commit("mutate", {
        property: "timelineSkinConfig",
        value: timelineSkinDictionary
      });
    });
}

export const setSkinConfigSubscription = ({commit}) => {
  const userPreferences = fireStoreDatabase.firestore().collection("app");

  const skinConfigSubscription = userPreferences.doc(process.env.VUE_APP_CHANNEL_PREFIX)
    .onSnapshot(queryDoc => {
      const skinGroups = queryDoc.data()[process.env.VUE_APP_CHANNEL_SETTINGS_PATH]
        ? queryDoc.data()[process.env.VUE_APP_CHANNEL_SETTINGS_PATH].skin
        : [];
        const timelineSkinGroups = queryDoc.data()[process.env.VUE_APP_CHANNEL_SETTINGS_PATH]
        ? queryDoc.data()[process.env.VUE_APP_CHANNEL_SETTINGS_PATH]['timeline_skin']
        : [];
      const skinDictionary = {};
      const timelineSkinDictionary = {};

      forEach(skinGroups, skinGrp => {
        forOwn(skinGrp, (val, key) => {
          skinDictionary[key] = val;
        });
      });

      commit("mutate", {
        property: "skinSchemedConfig",
        value: skinGroups
      });

      commit("mutate", {
        property: "skinConfig",
        value: skinDictionary
      });

      commit("mutate", {
        property: "currentSkinConfigSubscription",
        value: skinConfigSubscription
      });

      forEach(timelineSkinGroups, skinGrp => {
        forOwn(skinGrp, (val, key) => {
          timelineSkinDictionary[key] = val;
        });
      });

      commit("mutate", {
        property: "timelineSkinSchemedConfig",
        value: timelineSkinGroups
      });

      commit("mutate", {
        property: "timelineSkinConfig",
        value: timelineSkinDictionary
      });
    });
}

// eslint-disable-next-line
export const setSkinVariables = ({commit}, payload) => {
  forOwn(payload, (obj, key) => {
    d3.select(":root").style(`--${key}`, `${obj.value}`);
  });
}

// eslint-disable-next-line
export const setTimelineSkinVariables = ({commit}, payload) => {
  forOwn(payload, (obj, key) => {
    d3.select(":root").style(`${key}`, `${obj.value}`);
  });
}
