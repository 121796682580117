<template>
  <div v-if="isFirebaseAuthenticated">
    <Tour v-if="tourShown" />
    <Loader />
    <Header/>
    <Bookmarks></Bookmarks>
    <Search />
    <TimelineSearch />
    <MobilePanelsContent></MobilePanelsContent>
    <PanelsContent></PanelsContent>
    <router-view></router-view>
    <DialogModal v-if="dialogModalShown"
      :dialogAction="dialogModalInfo.dialogAction"
      :dialogActionParams="dialogModalInfo.dialogActionParams"
      :dialogType="dialogModalInfo.dialogType"
      :dialogTitle="dialogModalInfo.dialogTitle"
      :dialogMessage="dialogModalInfo.dialogMessage"></DialogModal>
    <SlidesPreview v-if="previewPPTslides"/>
  </div>
</template>

<script>
import SlidesPreview from "./components/PowerpointConverter/SlidesPreview";
import Tour from "./components/TourMode/Tour";
import Header from "./components/Header/Header";
import Loader from "./components/Loader";
import Bookmarks from "./components/Bookmarks/Bookmarks";
import DialogModal from "./components/DialogModal";
import MobilePanelsContent from "./components/PanelsContent/MobilePanelsContent";
import PanelsContent from "./components/PanelsContent/PanelsContent";
import Search from "./components/Search/Search";
import TimelineSearch from "./components/Timeline/TimelineSearch";
import { iOSzoomEvents } from "./mixins/iosTouchEvents";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    DialogModal,
    Loader,
    Header,
    Bookmarks,
    MobilePanelsContent,
    PanelsContent,
    Search,
    TimelineSearch,
    SlidesPreview,
    Tour
  },
  computed: {
    ...mapGetters([
      "getAuthRedirect",
      "getFirebaseAuthenticated",
      "getModalInstance",
      "getModalShown",
      "getSkinConfig",
      "getTourShown",
      "getTourStatus"
    ])
  },
  data() {
    return {
      dialogModalInfo: {},
      dialogModalShown: false,
      isFirebaseAuthenticated: false,
      previewPPTslides: false,
      tourShown: false
    }
  },
  name: "app",
  mixins: [iOSzoomEvents],
  watch: {
    getAuthRedirect(val) {
      if(val) {
        this.redirectToApp();
      }
    },
    getFirebaseAuthenticated(val) {
      this.isFirebaseAuthenticated = val;
    },
    getModalShown(val) {
      this.dialogModalShown = val;
    },
    getModalInstance(val) {
      this.dialogModalInfo = val;
    },
    getSkinConfig(skinConfig) {
      if(skinConfig["page-favicon"].value) {
        let link = document.querySelector("link[rel*='icon']") || document.createElement('link');

        // Set the attributes of the favicon
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = skinConfig["page-favicon"].value;

        // Append the favicon to the `head`
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      if(skinConfig["page-title"].value) {
        document.title = skinConfig["page-title"].value;
      }
    },
    getTourShown(val) {
      this.$forceUpdate();
      this.tourShown = val;
    },
    getTourStatus(payload) {
      if(!payload.viewed) {
        this.setTourShown(true);
      }
    }
  },
  mounted() {
    this.preventTouchZoom();
    this.setMapLoaded(false);

    if(!this.$auth.loading) {
      this.appInit();
    }
  },
  methods: {
    ...mapActions([
      "setMapLoaded",
      "setTourShown",
      "setUserRoles",
    ]),
    appInit() {
    },
    redirectToApp() {
      const redirectId = localStorage.getItem("redirectId");

      if(redirectId) {
        this.$router.replace({ name: "thought", params: { id: redirectId } })
          .then(() => {
            localStorage.removeItem("redirectId");
          })
          .catch(err => {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if (err.name != "NavigationDuplicated") {
              // But print any other errors to the console
              console.error(err);
            }
          });
      } else {
        this.$router.replace({ path: "/thought" })
          .then(() => {
            localStorage.removeItem("redirectId");
          })
          .catch(err => {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if (err.name != "NavigationDuplicated") {
              // But print any other errors to the console
              console.error(err);
            }
          });
      }
    }
  }
}
</script>

<style>
html, body {
  height: calc(var(--vh) * 100);
  width: 100vw;
  top: 0;
  left: 0;
  position: absolute;
  margin: 0px;
  overflow: hidden;
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  background-color: black;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
