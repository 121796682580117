var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "basic-mobile-panel",
        _vm.panelDirection,
        _vm.panelClasses,
        {
          "bookmarks-shown": _vm.bookmarksShown
        }
      ]
    },
    [
      _c(
        "div",
        {
          class: [
            "panel-wrapper",
            {
              opened: _vm.panelOpened
            }
          ]
        },
        [_vm._t("default")],
        2
      ),
      _c("BasicButton", {
        attrs: {
          buttonClasses: ["close-button"],
          clickAction: _vm.togglePanel,
          iconName: _vm.closeIcon
        }
      }),
      _vm.tourShown || (_vm.displayHandle && !_vm.getMobileSearchShown)
        ? _c("LabeledButton", {
            attrs: {
              buttonClasses: [
                "panel-handle",
                "mobile-panel-button",
                _vm.panelClasses
              ],
              buttonLabel: _vm.panelInstance,
              clickAction: _vm.togglePanel,
              clickParams: ".panel-wrapper." + _vm.panelDirection,
              iconName: _vm.handleIcon
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }