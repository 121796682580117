import * as d3 from "d3";
import * as generalConfig from "../../../configs/generalConfig";
import deviceDetection from "../../../mixins/deviceDetection";

export default {
  mixins: [deviceDetection],
  methods: {
    resetScrollTop(selection) {
      if(!d3.select(selection).empty()) {
        d3.select(selection).node().scrollTop = 0;
      }
    },
    setDragBehaviour(selection) {
      const draggableHandle = d3.select(selection).select(".draggable-handle");

      if(!draggableHandle.empty()) {
        const panelSelectionArea = d3.select(selection + " .panel-wrapper");
        const clickHandle = d3.select(selection + " .button.click-handle");
        const dragHandle = d3.select(selection + " .button.draggable-handle");

        draggableHandle.call(
          d3.drag()
            .on("start", () => {
              d3.selectAll(".panels-content .thought-details").classed("dragging", true);
            })
            .on("drag", () => {
              if(d3.event.x > 30) {
                dragHandle.style("left", (d3.event.x - 25) + "px");
                clickHandle.style("left", (d3.event.x - 27) + "px");
                panelSelectionArea.style("left", (d3.event.x + 5) + "px");
                panelSelectionArea.style("width", (window.innerWidth - d3.event.x - 5) + "px");
              } else {
                dragHandle.style("left", "2px");
                clickHandle.style("left", "0px");
                panelSelectionArea.style("left", "33px");
                panelSelectionArea.style("width", "calc(100vw - 35px)");
              }
            })
            .on("end", () => {
              d3.selectAll(".panels-content .thought-details").classed("dragging", false);

              if(d3.event.x > window.innerWidth - 200) {
                this.togglePanel();
                draggableHandle.on('mousedown.drag', null);
              }
              this.centerMap();
            })
        );
      }
    },
    updatePanelHeight(selection, panelHeight) {
      const panelSelection = d3.select(selection + " .panel-wrapper");

      if(panelHeight > 0) {
        panelSelection.style("display", "block");
        const newPanelHeight = panelHeight - 62;
        const newPanelTop = panelHeight - 62;

        panelSelection
          .transition()
          .duration(generalConfig.panelTransitionTime)
          .style("height", `${newPanelHeight}px`)
          .style("top", `-${newPanelTop}px`)
          .on("end", () => {
            this.resetScrollTop(selection + " .panel-wrapper");
            d3.select(selection + " .close-button").style("display", "flex");
          })
      } else {
        d3.select(selection + " .close-button").style("display", "none");

        panelSelection
          .transition()
          .duration(generalConfig.panelTransitionTime)
          .style("height", "40px")
          .style("top", panelHeight + "px")
          .on("end", () => {
            panelSelection.style("display", "none");
          })
      }
    },
    updatePanelWidth(selection, panelWidth) {
      const panelSelection = d3.select(selection + " .panel-wrapper");
      const clickHandle = d3.selectAll(selection + " .button.click-handle");
      const dragHandle = d3.selectAll(selection + " .button.draggable-handle");
      const isLeftPanel = d3.select(selection).classed("left");

      const leftPanelX = 0;
      const leftPanelW = window.innerWidth === panelWidth ? window.innerWidth - 30 : panelWidth;

      const widthSum = window.innerWidth - panelWidth;

      const rightPanelX = widthSum;
      const rightPanelW = panelWidth > 0 ? window.innerWidth - panelWidth : 0;

      const panelW = isLeftPanel ? leftPanelW : rightPanelW;
      const panelX = isLeftPanel ? leftPanelX : rightPanelX;

      const dragButtonLeftX = window.innerWidth === panelWidth ? window.innerWidth - 30 : panelW;
      const dragButtonRightX = window.innerWidth === panelWidth ?  0 : panelX - 32;

      const dragButtonX = isLeftPanel ? dragButtonLeftX : dragButtonRightX;

      clickHandle
        .transition()
        .duration(generalConfig.panelTransitionTime)
        .style("left", dragButtonX + "px");

      dragHandle
        .transition()
        .duration(generalConfig.panelTransitionTime)
        .style("left", (panelX - 30) + "px");

      panelSelection
        .transition()
        .duration(generalConfig.panelTransitionTime)
        .style("left", panelX + "px")
        .style("width", panelW + "px")
        .on("end", () => {
          if(panelWidth === 0) {
            panelSelection.attr("style", null);
            clickHandle.attr("style", null);
            dragHandle.attr("style", null);
            this.resetScrollTop(selection + " .panel-wrapper");
            this.centerMap();
          }

          this.setDragBehaviour(selection);

          if(isLeftPanel && panelWidth > 0) {
            this.resetScrollTop(".left .panel-wrapper .search-list");
          }
        });
    }
  }
}