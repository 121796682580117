import * as getters from "./userPreferencesGetters";
import * as mutations from "./userPreferencesMutations";
import * as actions from "./userPreferencesActions";

export default {
  state: {
    bookmarksShown: false,
    currentBookmarksSection: "",
    currentUserPreferencesSnapshot: {},
    currentUserPreferencesSubscription: {},
    mainSettingsInfo: {},
    userRoles: "none"
  },
  getters,
  mutations,
  actions,
}
