var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-modal" }, [
    _c("div", { staticClass: "dialog-arrow" }),
    _c("div", { staticClass: "dialog-box" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c(
          "div",
          { staticClass: "close-button", on: { click: _vm.closeDialog } },
          [
            _vm.dialogType !== "welcome" && _vm.dialogType !== "end"
              ? _c("BasicIcon", { attrs: { iconName: "iconClose" } })
              : _vm._e()
          ],
          1
        ),
        _c("h2", {
          staticClass: "dialog-title no-select",
          domProps: { innerHTML: _vm._s(_vm.dialogTitle) }
        })
      ]),
      _c("p", {
        staticClass: "dialog-message no-select",
        domProps: { innerHTML: _vm._s(_vm.dialogMessage) }
      }),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "div",
          { staticClass: "dialog-actions" },
          [
            _vm.dialogType === "welcome"
              ? _c("LabeledButton", {
                  attrs: {
                    buttonLabel: "NO THANKS",
                    buttonClasses: ["type-b", "dialog-cancel-cta"],
                    clickAction: _vm.closeDialog
                  }
                })
              : _vm._e(),
            _vm.dialogType === "welcome"
              ? _c("LabeledButton", {
                  attrs: {
                    buttonLabel: "LET'S GO",
                    buttonClasses: ["type-b", "dialog-action-cta"],
                    clickAction: _vm.setStepForward,
                    clickParams: _vm.dialogActionParams
                  }
                })
              : _vm._e(),
            _vm.dialogType === "step"
              ? _c("LabeledButton", {
                  attrs: {
                    buttonLabel: "BACK",
                    buttonClasses: ["type-b", "dialog-back-cta"],
                    clickAction: _vm.setStepBackward
                  }
                })
              : _vm._e(),
            _vm.dialogType === "step"
              ? _c("LabeledButton", {
                  attrs: {
                    buttonLabel: "NEXT",
                    buttonClasses: ["type-b", "dialog-action-cta"],
                    clickAction: _vm.setStepForward,
                    clickParams: _vm.dialogActionParams
                  }
                })
              : _vm._e(),
            _vm.dialogType === "end"
              ? _c("LabeledButton", {
                  attrs: {
                    buttonLabel: "RESTART THE TOUR",
                    buttonClasses: ["type-b", "dialog-back-cta"],
                    iconName: "iconRestartTour",
                    clickAction: _vm.restartTour
                  }
                })
              : _vm._e(),
            _vm.dialogType === "end"
              ? _c("LabeledButton", {
                  attrs: {
                    buttonLabel: "START EXPLORING",
                    buttonClasses: ["type-b", "dialog-action-cta"],
                    clickAction: _vm.closeDialog,
                    clickParams: _vm.dialogActionParams
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "dialog-info no-select" }, [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.dialogInfo) } })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }