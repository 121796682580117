var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", { staticClass: "map-canvas" }, [
    _c(
      "g",
      { staticClass: "thoughts-group" },
      [_c("Thoughts"), _c("ThoughtsHierarchyTree")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }