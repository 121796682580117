<template>
<div
v-if="!isTimelineRoute"
:class="[
  'panels-content',
  {
    'bookmarks-shown': bookmarksShown
  }
]">
  <BasicPanel
    :openIcon="leftPanelOpenIcon"
    :closeIcon="leftPanelCloseIcon"
    :panelDirection="'left'"
    :panelClasses="['companies']">
    <!-- Featured Search Panel Content -->
    <FeaturedSearchContent></FeaturedSearchContent>
  </BasicPanel>

  <BasicPanel
    :openIcon="rightPanelOpenIcon"
    :closeIcon="rightPanelCloseIcon"
    :draggable-panel="true"
    :panelDirection="'right'"
    :panelClasses="['thought-details']">
    <!-- Thought Details Content -->
    <ThoughtDetailsContent
      v-if="!isMobile || rotatedMobile"></ThoughtDetailsContent>
  </BasicPanel>
</div>
</template>
<script>
import { isMobile } from 'mobile-device-detect';

import BasicPanel from "../Panel/BasicPanel";
import FeaturedSearchContent from "./FeaturedSearchContent";
import ThoughtDetailsContent from "./ThoughtDetailsContent";
import { d3Operations } from "../../mixins/d3Operations";
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      "getBookmarksShown",
      "getDeviceOrientation",
      "getWindowInfo"
    ])
  },
  data() {
    return {
      isTimelineRoute: false,
      bookmarksShown: false,
      featuredAPI: process.env.VUE_APP_FEATURED_API,
      isMobile: false,
      leftPanelCloseIcon: "iconChevronLeft",
      leftPanelOpenIcon: "iconBuilding",
      rightPanelCloseIcon: "iconChevronRight",
      rightPanelOpenIcon: "iconInfo",
      rightPanelOpened: false,
      rightOpenedWidth: 0,
      rotatedMobile: false
    }
  },
  components: {
    BasicPanel,
    FeaturedSearchContent,
    ThoughtDetailsContent
  },
  mixins: [d3Operations],
  mounted() {
    this.componentInit();
  },
  watch: {
    "$route"() {
      this.isTimelineRoute = this.$route.name === "timeline";
    },
    getBookmarksShown(bookmarksShown) {
      this.bookmarksShown = bookmarksShown;
    },
    getDeviceOrientation() {
      this.detectMobile();
    },
    getWindowInfo() {
      this.detectMobile();
    }
  },
  methods: {
    adjustLayout(bookmarkData) {
      this.bookmarksShown = bookmarkData ? true : false;
    },
    componentInit() {
      this.isTimelineRoute = this.$route.name === "timeline";
      this.detectMobile();
    },
    detectMobile() {
      this.isMobile = isMobile || window.innerWidth < 768 ? true : false;
      this.rotatedMobile = isMobile && window.innerWidth >= 768;
    }
  },
}
</script>

<style lang="scss">
@import "../../stylesheets/panels-content.module.scss";
</style>