import * as getters from "./modalsGetters";
import * as mutations from "./modalsMutations";
import * as actions from "./modalsActions";

export default {
  state: {
    modalShown: false,
    modalInstance: {}
  },
  getters,
  mutations,
  actions,
}
