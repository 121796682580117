export const getCategories = state => {
  return state.categories;
};
export const getCompanies = state => {
  return state.companies;
};
export const getEventTypes = state => {
  return state.eventTypes;
};
export const getSubcategories = state => {
  return state.subcategories;
};
export const getYearSpan = state => {
  return state.yearSpan;
};
export const getYearsMap = state => {
  return state.yearsMap;
};
export const getFilterApplied = state => {
  return state.filterApplied;
};
export const getFilterConfigs = state => {
  return state.filterConfigs;
};
export const getCustomFilters = state => {
  return state.customFilters;
};
export const getTimelineSearchKeyword = state => {
  return state.timelineSearchKeyword;
};
export const getTimelineSearchInputFocus = state => {
  return state.timelineSearchInputFocus;
};
