<template>
  <div class="dialog-modal">
    <div class="overlay"
      @click="closeDialog"></div>
    <div class="dialog-box">
      <h2 class="dialog-title" v-html="dialogTitle"></h2>

      <p class="dialog-message" v-html="dialogMessage"></p>

      <div class="dialog-actions">
        <LabeledButton
          v-if="dialogType === 'warning'"
          :buttonLabel="'CANCEL'"
          :buttonClasses="['type-b', 'dialog-cancel-cta']"
          :clickAction="closeDialog"></LabeledButton>
        <LabeledButton
          :buttonLabel="'OK'"
          :buttonClasses="['type-b', 'dialog-action-cta']"
          :clickAction="dialogAction || closeDialog"
          :clickParams="dialogActionParams"></LabeledButton>
      </div>
    </div>
  </div>
</template>

<script>
import LabeledButton from "./Buttons/LabeledButton";
import { mapActions } from 'vuex';

export default {
  components: {
    LabeledButton
  },
  data() {
    return {
    }
  },
  props: ["dialogType", "dialogTitle", "dialogMessage", "dialogAction", "dialogActionParams"],
  mounted() {
    this.componentInit();
  },
  methods: {
    ...mapActions([
      "setModalShown"
    ]),
    componentInit() {
    },
    closeDialog() {
      this.setModalShown(false);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../stylesheets/dialog-modal.module.scss";
</style>