export const fetchLocalSettings = ({commit}) => {
  const initialSettings = {
    tourStatus: {
      viewed: false
    }
  };

  const localSettings = localStorage.getItem("fgiLocalSettings");

  if(localSettings) {
    commit("mutate", {
      property: "tourStatus",
      value: JSON.parse(localSettings).tourStatus
    });
  } else {
    localStorage.setItem("fgiLocalSettings", JSON.stringify(initialSettings));

    commit("mutate", {
      property: "tourStatus",
      value: initialSettings.tourStatus
    });
  }
}
export const setTourStatus = ({commit}, payload) => {
  localStorage.setItem("fgiLocalSettings", JSON.stringify({
    tourStatus: payload
  }));

  commit("mutate", {
    property: "tourStatus",
    value: payload
  });
}
export const setPanelsStatus = ({commit}, payload) => {
  commit("mutate", {
    property: "panelsStatus",
    value: payload
  });
}