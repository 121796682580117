<template>
  <svg class="map-canvas">
    <g class="thoughts-group">
      <Thoughts></Thoughts>
      <ThoughtsHierarchyTree></ThoughtsHierarchyTree>
    </g>
  </svg>
</template>

<script>
import { filter } from "lodash";
import Thoughts from "./Thoughts.vue";
import ThoughtsHierarchyTree from "../ThoughtsHierarchyTree/ThoughtsHierarchyTree";
import { thoughtFilters } from "../../mixins/thoughtOperations";

import { mapActions, mapGetters } from "vuex";
import { forOwn } from "lodash";

export default {
  components: {
    Thoughts,
    ThoughtsHierarchyTree,
  },
  computed: {
    ...mapGetters([
      "getCurrentUserPreferencesSnapshot",
      "getSkinConfig",
      "getThoughtsRawData",
      "getWindowInfo"
    ])
  },
  data() {
    return {
      selectedThoughtId: this.$route.params.id,
      selectedThought: {},
      thoughtNodes: [],
      thoughtChildren: [],
      thoughtParents: [],
      thoughtSources: [],
      thoughtRelatedSiblings: [],
      thoughtSiblings: [],
    }
  },
  name: "thoughts-group",
  mixins: [thoughtFilters],
  mounted() {
    this.componentInit();
    this.setWindowResize();
    this.setOrientationChange();
    this.startZoom();

    this.fetchSkinInfo({
      collection: "settings"
    });

    this.setSkinConfigSubscription();

    this.setUserPreferencesSnapshotListener({
      user: this.$auth.user
    });
  },
  updated() {
    this.componentInit();
  },
  watch: {
    "$route"(to) {
      if(this.selectedThoughtId !== to.params.id) {
        this.selectedThoughtId = to.params.id;
        this.componentInit();
      }
    },
    getSkinConfig(skinConfig) {
      this.setSkinVariables(skinConfig);
    },
    getCurrentUserPreferencesSnapshot(userPreferencesSnapshot) {
      let tourVisited = false;

      forOwn(userPreferencesSnapshot.data(), (val, key) => {
        if(key === "tourSeen" && val) {
          tourVisited = true;
        }
      });

      if(!tourVisited) {
        this.setTourShown(true);
      }

      this.setTourSeen(tourVisited);
    },
    getThoughtsRawData() {
      this.navigateToSelectedThought();
    },
    getWindowInfo() {
      this.centerMap();
    }
  },
  methods: {
    ...mapActions([
      "centerMap",
      "fetchSkinInfo",
      "fetchThoughtsRawData",
      "resetZoom",
      "setMapLoaded",
      "setSkinConfigSubscription",
      "setSkinVariables",
      "setTourSeen",
      "setTourShown",
      "setOrientationChange",
      "setUserPreferencesSnapshotListener",
      "setWindowResize",
      "siftHierarchyData",
      "startZoom"
    ]),
    componentInit() {
      const requestPayload = this.selectedThoughtId ? {
        ids: [{
          id: this.selectedThoughtId
        }]
      } : { ids:[] };

      this.fetchThoughtsRawData(requestPayload);
    },
    navigateToSelectedThought() {
      this.processThoughtData();
    },
    setChildrenData(thoughtNode) {
      const thoughtChildren = [... thoughtNode.data.relations.filter(filteredItm =>
        this.filterThoughtByKey(filteredItm, thoughtNode, "thought_id_a", 1)
      )];

      thoughtChildren.forEach(itm => { itm.thoughtChild = true })
      this.thoughtChildren = this.setUniqueRecords(this.sortThoughts(thoughtChildren));
    },
    setParentsData(thoughtNode) {
      const thoughtParents = [... thoughtNode.data.relations.filter(filteredItm => {
          const parentFound = this.filterThoughtByKey(filteredItm, thoughtNode, "thought_id_b", 1);
          return parentFound && parentFound.data.gates.has_children;
        }
      )];

      thoughtParents.forEach(itm => { itm.thoughtParent = true });
      const uniqueParentValues = this.setUniqueRecords(this.sortThoughts(thoughtParents));
      this.filterByCollection(this.thoughtRelatedSiblings, uniqueParentValues);
      const filterNullData = filter(uniqueParentValues, itm => itm.data && itm.data.relations);

      this.thoughtParents = filterNullData;
    },
    setRelatedSiblingsData(thoughtNode) {
      const thoughtRelatedSiblings = [
        ... thoughtNode.data.relations.filter(filteredItm =>
        this.filterThoughtByKey(filteredItm, thoughtNode, "thought_id_a", 3)
      ),
      ... thoughtNode.data.relations.filter(filteredItm =>
        this.filterThoughtByKey(filteredItm, thoughtNode, "thought_id_b", 3)
      )];

      thoughtRelatedSiblings.forEach(itm => itm.thoughtRelatedSibling = true);

      this.thoughtRelatedSiblings = this.setUniqueRecords(this.setUniqueRecords(thoughtRelatedSiblings));
    },
    processThoughtData() {
      this.resetZoom();

      if(this.getThoughtsRawData.length === 0) {
        this.selectedThought = {...this.getThoughtsRawData[0]};
        this.showNoRecordsMessage();
        this.thoughtChildren = [];
        this.thoughtParents = [];
        this.thoughtRelatedSiblings = [];
        this.thoughtSiblings = [];
      } else {
        this.selectedThought = {...this.getThoughtsRawData[0]};
        this.selectedThought.selected = true;
        this.setRelatedSiblingsData(this.selectedThought);
        this.setParentsData(this.selectedThought);
        this.setChildrenData(this.selectedThought);
        this.pushToLocalHistory();
        const rawThoughtNodes = [...this.fetchRelatedThoughts(this.getThoughtsRawData[0])];
        this.thoughtNodes = [this.selectedThought, ...this.sortThoughts(rawThoughtNodes)];
      }

      this.siftHierarchyData({
        thoughtChildren: this.thoughtChildren,
        thoughtNodes: this.thoughtNodes,
        thoughtParents: this.thoughtParents,
        thoughtRelatedSiblings: this.thoughtRelatedSiblings,
        thoughtSiblings: this.thoughtSiblings
      });
    },
    pushToLocalHistory() {
      if(this.selectedThought.data.thought_id !== "") {
        const selectedThoughtCopy = {...this.selectedThought.data};
        delete selectedThoughtCopy.gates;
        delete selectedThoughtCopy.kind;
        delete selectedThoughtCopy.notes;
        delete selectedThoughtCopy.relations;
      }
    },
    showNoRecordsMessage() {
      this.selectedThought = {
        data: {
          thought_id: "",
          title: "no thoughts found with the specified id"
        },
        restricted: true
      }
      this.thoughtNodes = [this.selectedThought];
    }
  }
}
</script>
