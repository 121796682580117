import * as getters from "./timelineGetters";
import * as mutations from "./timelineMutations";
import * as actions from "./timelineActions";

export default {
  state: {
    categories: [],
    companies: [],
    customFilters: [],
    eventTypes: [],
    filterApplied: false,
    filterConfigs: [],
    subcategories: [],
    timelineSearchKeyword: "",
    timelineSearchInputFocus: false,
    yearsMap: {},
    yearSpan: {},
  },
  getters,
  mutations,
  actions,
}
