import { fireStoreDatabase } from "../../../configs/firebase/databaseConfig";
import "firebase/auth";
import "firebase/firestore";

export const logIntoFirebase = ({commit}, payload) => {
  return fireStoreDatabase.auth().signInWithCustomToken(payload.profile.user_id)
    .then(() => {
      commit("mutate", {
        property: "firebaseUser",
        value: payload
      });
      commit("mutate", {
        property: "firebaseAuthenticated",
        value: true
      });
    }).catch(function (err) {
      // eslint-disable-next-line
      console.error(err);
    });
}

export const redirectFromAuth = ({commit}, payload) => {
  commit("mutate", {
    property: "authRedirect",
    value: payload
  });
}

export const signOutFirebase = ({commit}) => {
  return fireStoreDatabase.auth().signOut().then(function() {
    commit("mutate", {
      property: "firebaseAuthenticated",
      value: false
    });
  });
}