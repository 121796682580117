var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showMapSearch
    ? _c(
        "div",
        { class: ["thoughts-search", { tourShown: _vm.tourShown }] },
        [
          _vm.displayResults && _vm.searchKeyword
            ? _c("BasicButton", {
                style: {
                  right: _vm.searchInputCloseRight
                },
                attrs: {
                  clickAction: _vm.closeMobileSearch,
                  buttonClasses: ["close-search"],
                  iconName: "iconClose"
                }
              })
            : _vm._e(),
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.getThoughtsByKeyword($event.target.value)
                }
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchKeyword,
                    expression: "searchKeyword"
                  }
                ],
                ref: "mobileSearchInput",
                class: {
                  hide: !_vm.showSearchInput && !_vm.mobileSearchMode
                },
                style: {
                  right: _vm.searchInputRight
                },
                attrs: {
                  name: "search",
                  type: "search",
                  "aria-autocomplete": "both",
                  "aria-haspopup": "false",
                  autocapitalize: "off",
                  autocomplete: "off",
                  autocorrect: "off",
                  placeholder: "Search"
                },
                domProps: { value: _vm.searchKeyword },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchKeyword = $event.target.value
                  }
                }
              })
            ]
          ),
          _vm.displayResults && _vm.searchKeyword
            ? _c("div", {
                staticClass: "search-overlay",
                on: {
                  click: function($event) {
                    return _vm.closeSearch()
                  }
                }
              })
            : _vm._e(),
          (_vm.displayResults && _vm.searchKeyword) || _vm.mobileSearchMode
            ? _c("div", { staticClass: "search-list" }, [
                _c(
                  "div",
                  { staticClass: "search-results-tab-group" },
                  _vm._l(_vm.searchSections, function(section) {
                    return _c(
                      "div",
                      {
                        key: section.id,
                        staticClass: "tab-button",
                        class: { selected: section.selected },
                        on: {
                          click: function($event) {
                            return _vm.toggleSelectedSection(section)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "tab-wrapper" }, [
                          _c("span", { staticClass: "tab-label" }, [
                            _vm._v(_vm._s(section.label))
                          ]),
                          _c("span", { staticClass: "tab-quantity" }, [
                            _vm._v(
                              _vm._s("(" + _vm.getSectionRecords(section) + ")")
                            )
                          ])
                        ])
                      ]
                    )
                  }),
                  0
                ),
                (_vm.selectedSection.id === 0 &&
                  _vm.thoughtsByKeyword.length === 0) ||
                (_vm.selectedSection.id === 1 &&
                  _vm.relatedThoughts.length === 0)
                  ? _c("div", { staticClass: "no-results" }, [
                      _c("span", [_vm._v(_vm._s("no results to display"))])
                    ])
                  : _vm._e(),
                _vm.selectedSection.id === 0
                  ? _c("div", { staticClass: "results-box" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.thoughtsByKeyword, function(result) {
                          return _c("li", { key: result.thought_id }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.navigateToThought(
                                      result.thought_id
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(result.title))]
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  : _vm._e(),
                _vm.selectedSection.id === 1
                  ? _c("div", { staticClass: "results-box" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.relatedThoughts, function(result) {
                          return _c("li", { key: result.thought_id }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.navigateToThought(
                                      result.thought_id
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(result.title))]
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }