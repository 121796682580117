export const mutate = (state, payload) => {
  state[payload.property] = payload.value;
};
export const resetUIresets =  (state) => {
  state.UIresets.forEach((itm) => {
    if(itm) {
      itm();
    }
  });
}
export const setUIresets =  (state, payload) => {
  state.UIresets = payload;
};
export const setUItransformations =  (state, payload) => {
  state.UItransformations = payload;
};
export const setMobileUItransformations =  (state, payload) => {
  state.mobileUItransformations = payload;
};
export const setCurrentStepSettings = (state) => {
  state.currentSteSettings = state.UItransformations[state.currentTourStep]();
};
export const resetNextStepSettings = (state) => {
    state.currentSteSettings = state.UIresets[state.currentTourStep + 1]();
  // }
};
export const resetCurrentStepSettings = (state) => {
  if(state.UIresets[state.currentTourStep]) {
    state.currentSteSettings = state.UIresets[state.currentTourStep]();
  }
};
export const resetPreviousStepSettings = (state) => {
  if(state.UIresets[state.currentTourStep - 1]) {
    state.currentSteSettings = state.UIresets[state.currentTourStep - 1]();
  } else {
    return;
  }
};