<template>
  <div class="button"
    :class="buttonClasses"
    @click.stop="clickAction($event, clickParams, buttonClasses)">
    <BasicIcon :iconName="iconName"></BasicIcon>
  </div>
</template>
<script>
import BasicIcon from "../BasicIcon";

export default {
  components: {
    BasicIcon
  },
  name: 'basic-button',
  props: ["buttonClasses", "clickAction", "clickParams", "iconName"],
  mounted() {
    this.componentInit();
  },
  methods: {
    componentInit() {
    }
  }
}
</script>
<style lang="scss">
.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 7.5px;
  height: 30px;
  width: 30px;

  svg {
    height: 16px;
    width: 16px;
  }
}
</style>