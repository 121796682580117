export const getBookmarksShown = state => {
  return state.bookmarksShown;
};
export const getCurrentBookmarksSection = state  => {
  return state.currentBookmarksSection;
};
export const getCurrentUserPreferencesSnapshot = state => {
  return state.currentUserPreferencesSnapshot;
};
export const getCurrentUserPreferencesSubscription = state => {
  return state.currentUserPreferencesSubscription;
};
export const getMainSettingsInfo = state => {
  return state.mainSettingsInfo;
};
export const getUserRoles = state => {
  return state.userRoles;
};