export const fetchThoughtsRawData = ({commit}, payload) => {
  fetch(process.env.VUE_APP_THOUGHT_BY_ID_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
  .then(stream => {
    return stream.json();
  })
  .then(data => {
    commit("mutate", {
      property: "thoughtsRawData",
      value: data
    });
  })
  .catch(error => {
    console.error(error);
  });
};
export const siftHierarchyData = ({commit}, payload) => {
  commit("mutate", {
    property: "hierarchyData",
    value: payload
  });
};
export const setMapLoaded = ({commit}, payload) => {
  commit("mutate", {
    property: "mapLoaded",
    value: payload
  });
};
export const setSiblingsData = ({commit}, payload) => {
  commit("mutate", {
    property: "siblingsData",
    value: payload
  });
};
// These last two methods need to move to a different storage
// that manages the window and resize events
export const setOrientationChange = ({commit}) => {
  window.addEventListener("orientationchange", event => {
    commit("mutate", {
      property: "deviceOrientation",
      value: event
    })
  })
};
export const setWindowResize = ({commit}) => {
  window.visualViewport.addEventListener("resize", event => {
    commit("mutate", {
      property: "windowInfo",
      value: event
    })
  })
};
