<template>
  <div class="labeled-button"
    :class="buttonClasses"
    @click.stop="clickAction($event, clickParams, buttonClasses)">
    <span class="button-label" v-html="buttonLabel"></span>
    <BasicIcon v-if="iconName" :iconName="iconName"></BasicIcon>
  </div>
</template>
<script>
import BasicIcon from "../BasicIcon";

export default {
  components: {
    BasicIcon
  },
  name: 'basic-button',
  props: ["buttonClasses", "buttonLabel", "clickAction", "clickParams", "iconName"],
  mounted() {
    this.componentInit();
  },
  methods: {
    componentInit() {
    }
  }
}
</script>
<style lang="scss">
@import "../../stylesheets/buttons.module.scss"
</style>