import * as getters from "./authenticationGetters";
import * as mutations from "./authenticationMutations";
import * as actions from "./authenticationActions";

export default {
  state: {
    authRedirect: false,
    firebaseAuthenticated: false
  },
  getters,
  mutations,
  actions,
}
