import * as d3 from "d3";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "getPanelsInfo",
      "getMainSettingsInfo"
    ])
  },
  data() {
    return {
      dialogBoxStyle: {},
      panelsInfo: {},
      UIresets: []
    }
  },
  watch: {
    getPanelsInfo(panelsInfo) {
      this.panelsInfo = panelsInfo;
      this.setCurrentStepSettings();
    }
  },
  methods: {
    ...mapActions([
      "setSearchKeyword"
    ]),
    ...mapMutations([
      "setUIresets",
      "setUItransformations",
      "setMobileUItransformations",
    ]),
    resetStepOne()  {
      this.setSearchKeyword(undefined);
      this.restoreFeature(".thoughts-search");
    },
    resetStepTwo() {
      this.restoreFeature(".header-button-home");
    },
    resetStepThree() {
      this.restoreFeature(".header-button-pinned");
    },
    resetStepFour() {
      this.restoreFeature(".header-button-history");
    },
    resetStepFive() {
      this.restoreFeature(".header-button-timeline");
    },
    resetStepSix() {
      this.restoreFeature(".header-button-tour");
    },
    resetStepSeven() {
      this.setPanelWidth({
        panelInstance: "left",
        width: 0
      });

      d3.select(".dialog-box")
        .style("top", null)
        .style("left", null);

      this.restoreFeature(".basic-panel.left");
    },
    resetStepEight() {
      this.panelsInfo = {};
    },
    resetStepNine() {
      this.setPanelWidth({
        panelInstance: "right",
        width: 0
      });

      d3.select(".dialog-box")
        .style("top", null)
        .style("right", null);

      this.restoreFeature(".basic-panel.right");
    },
    setDesktopTourUI() {
      if(this.getMainSettingsInfo["show_timeline_button"]) {
        const UItransformations = [
          this.setStepZero,
          this.setStepOne,
          this.setStepTwo,
          this.setStepThree,
          this.setStepFour,
          this.setStepFive,
          this.setStepSix,
          this.setStepSeven,
          this.setStepEight,
          this.setStepNine,
          this.setFinalStep,
        ];

        this.setUItransformations(UItransformations);
      } else {
        const UItransformations = [
          this.setStepZero,
          this.setStepOne,
          this.setStepTwo,
          this.setStepThree,
          this.setStepFour,
          this.setStepSix,
          this.setStepSeven,
          this.setStepEight,
          this.setStepNine,
          this.setFinalStep,
        ];

        this.setUItransformations(UItransformations);
      }
    },
    setDesktopTourResets() {
      if(this.getMainSettingsInfo["show_timeline_button"]) {
        this.UIresets = [
          undefined,
          this.resetStepOne,
          this.resetStepTwo,
          this.resetStepThree,
          this.resetStepFour,
          this.resetStepFive,
          this.resetStepSix,
          this.resetStepSeven,
          this.resetStepEight,
          this.resetStepNine,
        ];

        this.setUIresets(this.UIresets);
      } else {
        this.UIresets = [
          undefined,
          this.resetStepOne,
          this.resetStepTwo,
          this.resetStepThree,
          this.resetStepFour,
          this.resetStepSix,
          this.resetStepSeven,
          this.resetStepEight,
          this.resetStepNine,
        ];

        this.setUIresets(this.UIresets);
      }
    },
    setStepZero() {

    },
    setStepOne()  {
      this.setSearchKeyword(this.getTourInfo.steps[1]['search_term'])
      this.searchThoughtByString(this.getTourInfo.steps[1]['search_term']);
      this.highlightFeature(".thoughts-search");

      const dialogArrow = d3.select(".dialog-arrow");
      const searchInput = d3.select(".thoughts-search input");
      const searchInputProps = searchInput.node().getBoundingClientRect();
      const dialogBox = d3.select(".dialog-box");
      const dialogBoxLeft = searchInputProps.left - 270 < 0 ? searchInputProps.left : searchInputProps.left - 270;

      dialogArrow
        .classed("hide", false)
        .classed("ninety-negative", false)
        .classed("zero", true)
        .classed("ninety", false)
        .style("width", "300px")
        .style("top", "57px")
        .style("left", `${searchInputProps.left + 30}px`);

      dialogBox
        .style("top", "69px")
        .style("left", dialogBoxLeft + "px");
    },
    setStepTwo()  {
      this.setStepsThreeToSix(".header-button-home");
      this.highlightFeature(".header-button-home");
    },
    setStepThree()  {
      this.setStepsThreeToSix(".header-button-pinned");
      this.highlightFeature(".header-button-pinned");
    },
    setStepFour()  {
      this.setStepsThreeToSix(".header-button-history");
      this.highlightFeature(".header-button-history");
    },
    setStepFive() {
      this.setStepsThreeToSix(".header-button-timeline");
      this.highlightFeature(".header-button-timeline");
    },
    setStepSix() {
      this.setStepsThreeToSix(".header-button-tour");
      this.highlightFeature(".header-button-tour");
    },
    setStepSeven() {
      if(this.panelsInfo.left) {
        const dialogArrow = d3.select(".dialog-arrow");
        const dialogBox = d3.select(".dialog-box");
        const dialogLeft = this.panelsInfo.left.width + 60;
        const dialogTop = (window.innerHeight - 166) * 0.5;

        dialogArrow
          .classed("hide", false)
          .classed("ninety", false)
          .classed("ninety-negative", true)
          .classed("zero", false)
          .style("top", `${dialogTop + 110}px`)
          .style("left", `${dialogLeft - 18}px`);

        dialogBox
          .style("top", `${dialogTop}px`)
          .style("left", `${dialogLeft}px`);

        this.resizeDialogHorizontally();

        this.fetchFeaturedSearchContent();
        this.highlightFeature(".basic-panel.left");
      }
    },
    setStepEight() {
      d3.select(".dialog-box")
        .style("width", "300px")
        .style("left", "auto");
      d3.select(".dialog-arrow")
      .classed("hide", true);
    },
    setStepNine() {
      const dialogArrow = d3.select(".dialog-arrow");
      const dialogBox = d3.select(".dialog-box");
      const dialogLeft = this.panelsInfo.right.width - 400;
      const dialogTop = (window.innerHeight - 180) * 0.5;

      dialogArrow
        .classed("hide", false)
        .classed("ninety", true)
        .classed("ninety-negative", false)
        .classed("zero", false)
        .style("top", `${dialogTop + 120}px`)
        .style("left", `${dialogLeft + 334}px`);

      dialogBox
        .style("top", `${dialogTop}px`)
        .style("left", `${dialogLeft}px`);

      if(window.innerWidth < 780) {
        dialogBox
          .style("width", "250px")
          .style("left", `${dialogLeft + 50}px`);
      } else {
        dialogBox
          .style("width", "300px")
          .style("left", `${dialogLeft}px`);
      }

      if(this.panelsInfo.right && this.panelsInfo.right.width === 0) {
        this.setPanelWidth({
          panelInstance: "right",
          width: window.innerWidth * 0.5
        });

        const detailsThoughtId = this.getMainSettingsInfo["show_timeline_button"]
        ? this.getTourInfo.steps[9]['details_guid']
        : this.getTourInfo.steps[8]['details_guid'];

        this.$router.push({ name: "thought", params: { id: detailsThoughtId } })
        .catch(err => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (err.name != "NavigationDuplicated") {
          // But print any other errors to the console
            console.error(err);
          }
        });

        this.highlightFeature(".basic-panel.right");
      }
    },
    setFinalStep() {
    },
    setStepsThreeToSix(featureClass) {
      const dialogArrow = d3.select(".dialog-arrow");
      const featureInstance = d3.select(featureClass);
      const searchInputProps = featureInstance.node().getBoundingClientRect();
      const dialogBox = d3.select(".dialog-box");
      const dialogBoxLeft = searchInputProps.left - 270;

      dialogArrow
        .classed("zero", true)
        .classed("ninety", false)
        .style("top", "57px")
        .style("left", `${searchInputProps.left + 30}px`);

      dialogBox
        .style("width", "300px")
        .style("top", "69px")
        .style("left", dialogBoxLeft + "px");
    },
    highlightFeature(feature) {
      d3.select(feature)
        .classed("tour-highlight", true);
    },
    resizeDialogHorizontally() {
      const dialogBox = d3.select(".dialog-box");

      if(window.innerWidth < 780) {
        dialogBox
          .style("width", "250px");
      } else {
        dialogBox
          .style("width", "300px");
      }
    },
    restoreFeature(feature) {
      d3.select(feature)
        .classed("tour-highlight", false);
    }
  }
}
