export const getCurrentTourStep = state => {
  return state.currentTourStep;
};
export const getTourInfo = state => {
  return state.tourInfo;
};
export const getTourInstance = state => {
  return state.tourInstance;
};
export const getTourSeen = state => {
  return state.tourSeen;
};
export const getTourShown = state => {
  return state.tourShown;
};
export const getCurrentStepSettings = state => {
  return state.currentStepSettings;
};