var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "labeled-button",
      class: _vm.buttonClasses,
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.clickAction($event, _vm.clickParams, _vm.buttonClasses)
        }
      }
    },
    [
      _c("span", {
        staticClass: "button-label",
        domProps: { innerHTML: _vm._s(_vm.buttonLabel) }
      }),
      _vm.iconName
        ? _c("BasicIcon", { attrs: { iconName: _vm.iconName } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }