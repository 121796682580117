export const setMobileSearchShown = ({commit}, payload) => {
  commit("mutate", {
    property: "mobileSearchShown",
    value: payload
  });
}
export  const setSearchKeyword = ({commit}, payload) => {
  commit("mutate", {
    property: "searchKeyword",
    value: payload
  });
}
export  const searchThoughtByString = ({commit}, payload) => {
  const requestBody = {
    query: payload
  };

  return fetch(process.env.VUE_APP_SEARCH_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(requestBody)
  })
  .then(stream => {
    return stream.json();
  })
  .then(result => {
    const resultWterm = {...result};
    resultWterm.searchTerm = payload;

    commit("mutate", {
      property: "searchResult",
      value: resultWterm
    });

    return resultWterm;
  })
  .catch(error => {
    console.error(error);
  });
}
export  const setMobileSearchSelected = ({commit}, payload) => {
  commit("mutate", {
    property: "mobileSearchSelected",
    value: payload
  });
}