var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "button",
      class: _vm.buttonClasses,
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.clickAction($event, _vm.clickParams, _vm.buttonClasses)
        }
      }
    },
    [_c("BasicIcon", { attrs: { iconName: _vm.iconName } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }