<template>
    <vue-custom-scrollbar :settings="scrollSettings">
      <div :class="'bookmarks-bar'">
        <div class="bookmark"
          v-for="(thought, index) in contentData"
          v-bind:key="thought.id"
          v-on:click="gotoThought(thought)">
          <div :class="['bookmark-wrapper', selectedSection.label]">
            <BasicIcon
              v-if="selectedSection.label === 'history'"
              :iconName="'arrowTail'"
              :iconClasses="'arrow-tail'">
            </BasicIcon>
            <img class="no-select" v-if="thought.icon" :src="thought.icon" />
            <span class="bookmark-content no-select"
              v-html="thought.title"></span>
            <BasicButton
              v-if="selectedSection.label === 'pinned'"
              :buttonClasses="['delete-pin']"
              :clickAction="deleteItemEvent"
              :clickParams="index"
              :iconName="'iconClose'"></BasicButton>
          </div>
        </div>
      </div>
    </vue-custom-scrollbar>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar';
import BasicIcon from "../BasicIcon";
import BasicButton from "../Buttons/BasicButton";
import { mapActions } from 'vuex';

export default {
  components: {
    BasicButton,
    BasicIcon,
    vueCustomScrollbar
  },
  props: ["contentData", "selectedSection", "deleteItemEvent"],
  data () {
    return {
      scrollSettings: {
        useBothWheelAxes: true
      },
    }
  },
  name: "bookmarks-bar",
  mounted() {
    this.componentInit();
  },
  methods: {
    ...mapActions([
      "setMapLoaded",
      "setBookmarksShown"
    ]),
    componentInit() {
    },
    gotoThought(thought) {
      this.setMapLoaded(false);
      this.setBookmarksShown(false);
      this.$router.push({ name: "thought", params: { id: thought.thought_id } })
        .catch(err => {
          this.setMapLoaded(true);
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (err.name != "NavigationDuplicated") {
            // But print any other errors to the console
            console.error(err);
          }
        });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../stylesheets/bookmarks.module.scss";
</style>
