import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC39ALJ0SUEkK5XvZYUO-d1awrh-H_wY2M",
  authDomain: "fenix-marketplace-data.firebaseapp.com",
  databaseURL: "https://fenix-marketplace-data.firebaseio.com",
  projectId: "fenix-marketplace-data",
  storageBucket: "fenix-marketplace-data.appspot.com",
  messagingSenderId: "688897010213",
  appId: "1:688897010213:web:9b048f8fd573787ae3edcc",
  measurementId: "G-PHR6Z7Y3E0"
};

// Get a Firestore instance
export const fireStoreDatabase = firebase
  .initializeApp(firebaseConfig)