var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tour-wrapper" },
    [
      _c("div", { staticClass: "tour-overlay" }),
      _c("div", { staticClass: "tour-overlay intermediate" }),
      _vm.tourModalActive
        ? _c("TourModal", {
            class: _vm.dialogClass,
            attrs: {
              dialogAction: _vm.dialogAction,
              dialogActionParams: _vm.dialogActionParams,
              dialogInfo: _vm.dialogInfo,
              dialogTitle: _vm.dialogTitle,
              dialogType: _vm.dialogType,
              dialogMessage: _vm.dialogMessage
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }