import * as d3 from "d3";

export const d3Operations = {
  methods: {
    getSelectionProps(selection) {
      if(!selection.empty()) {
        return selection.node().getBoundingClientRect();
      }
    },
    getSelectionProperties(selection) {
      return d3.select(selection).node().getBoundingClientRect();
    },
    getSelectionTransform(transformSelection) {
      let transformValue = transformSelection.attr("transform");
      let parsedTransform = {};

      if(transformValue) {
        for (let i in transformValue = transformValue.match(/(\w+\((\-?\d+\.?\d*e?\-?\d*,?)+\))+/g)) {
            var transformAttribute = transformValue[i].match(/[\w\.\-]+/g);
            parsedTransform[transformAttribute.shift()] = transformAttribute;
        }
      }

      return parsedTransform;
    },
    hasClassOpened(classString) {
      return d3.select(classString).classed("opened");
    },
    scrollLeftToEnd(selection) {
      setTimeout(() => {
        const container = d3.select(selection);

        if(!container.empty() && window.innerWidth > 768) {
          container._groups[0][0].scrollLeft = container._groups[0][0].scrollWidth - this.getSelectionProps(container).width;
        } else {
          this.resetScroll(selection);
        }
      }, 1000);
    },
    moveDivHorizontal(selection, value) {
      const selectedElement = d3.select(selection);

      selectedElement
        .style("left", value + "px");
    },
    changeDivWidth(selection, size) {
      const selectedElement = d3.select(selection);

      selectedElement
        .style("width", size + "px");
    },
    forcePanelClose(selection) {
      d3.select(selection).classed("opened", false);
      const panelSelection = d3.select(selection + " .panel-wrapper");
      const panelSelectionArea = d3.select(selection + " .panel-wrapper");
      const clickHandle = d3.selectAll(selection + " .button.click-handle");
      const dragHandle = d3.selectAll(selection + " .button.draggable-handle");

      dragHandle.style("left", "calc(100vw)");
      clickHandle.style("left", "calc(100vw - 30px)");
      panelSelectionArea.style("left", "calc(100vw - 25px)");
      panelSelectionArea.style("width", "0px");

      clickHandle.attr("style", null);
      dragHandle.attr("style", null);
      panelSelection.attr("style", null);
    },
    updatePanelWidth(selection, panelWidth) {
      const panelSelection = d3.select(selection + " .panel-wrapper");
      const clickHandle = d3.selectAll(selection + " .button.click-handle");
      const dragHandle = d3.selectAll(selection + " .button.draggable-handle");
      const isLeftPanel = d3.select(selection).classed("left");
      const isPanelOpen = !panelSelection.classed("opened");

      const leftPanelX = 0;
      let leftPanelW = isPanelOpen ? panelWidth : 0;
      leftPanelW = isPanelOpen && window.innerWidth === panelWidth ? window.innerWidth - 30 : leftPanelW;

      const widthSum = window.innerWidth - panelWidth;

      const rightPanelX = isPanelOpen ? widthSum : window.innerWidth;
      let rightPanelW = isPanelOpen ? window.innerWidth - panelWidth : 0;
      rightPanelW = isPanelOpen && window.innerWidth === panelWidth ? window.innerWidth : rightPanelW;

      const panelW = isLeftPanel ? leftPanelW : rightPanelW;
      const panelX = isLeftPanel ? leftPanelX : rightPanelX;

      let dragButtonLeftX = isPanelOpen ? panelW : 0;
      dragButtonLeftX = isPanelOpen && window.innerWidth === panelWidth ? window.innerWidth - 30 : dragButtonLeftX;

      let dragButtonRightX = isPanelOpen ? panelX : window.innerWidth;
      dragButtonRightX = isPanelOpen && window.innerWidth === panelWidth ?  0 : dragButtonRightX - 35;

      const dragButtonX = isLeftPanel ? dragButtonLeftX : dragButtonRightX;

      clickHandle.transition();
      dragHandle.transition();
      panelSelection.transition();

      clickHandle
        .transition()
        .duration(500)
        .style("left", dragButtonX + "px");

      dragHandle
        .transition()
        .duration(500)
        .style("opacity", 1)
        .style("left", (panelX - 30) + "px");

      panelSelection
        .transition()
        .duration(500)
        .style("left", panelX + "px")
        .style("width", panelW + "px")
        .on("end", () => {
          if(isPanelOpen) {
            if(window.innerWidth === panelWidth) {
              d3.select(selection).select(".click-handle").classed("mobile", true);
            }
          } else {
            clickHandle.attr("style", null);
            dragHandle.attr("style", null);
            panelSelection.attr("style", null);

            d3.select(selection).select(".button.panel-handle.click-handle").call(d3.drag().on("drag", null));
          }
        });
    },
    setDragBehavior(selection) {
      const panelSelection = d3.select(selection);
      const panelSelectionArea = d3.select(selection + " .panel-wrapper");
      const clickHandle = d3.selectAll(selection + " .button.click-handle");
      const dragHandle = d3.selectAll(selection + " .button.draggable-handle");

      if(panelSelection.classed("draggable-panel")) {
        const draggableHandle = panelSelection.select(".draggable-handle");

        draggableHandle.call(
          d3.drag()
            .on("drag", () => {
              if(d3.event.x > 30) {
                dragHandle.style("left", (d3.event.x - 25) + "px");
                clickHandle.style("left", (d3.event.x - 30) + "px");
                panelSelectionArea.style("left", (d3.event.x + 5) + "px");
                panelSelectionArea.style("width", (window.innerWidth - d3.event.x - 5) + "px");
              } else {
                dragHandle.style("left", "5px");
                clickHandle.style("left", "0px");
                panelSelectionArea.style("left", "35px");
                panelSelectionArea.style("width", "calc(100vw - 37px)");
              }
            })
            .on("end", () => {
            })
        );
      }
    },
    setHandleDragBehavior(selection) {
      const panelSelection = d3.select(selection);
      const panelSelectionArea = d3.select(selection + " .panel-wrapper");
      const clickHandle = d3.selectAll(selection + " .button.click-handle");
      const dragHandle = d3.selectAll(selection + " .button.draggable-handle");

      if(panelSelection.classed("draggable-panel")) {
        const draggableHandle = d3.select(selection).select(".button.panel-handle.click-handle");

        draggableHandle.call(
          d3.drag()
            .on("drag", () => {
              if(d3.event.x > 30) {
                dragHandle.style("left", (d3.event.x - 25) + "px");
                clickHandle.style("left", (d3.event.x - 30) + "px");
                panelSelectionArea.style("left", (d3.event.x + 5) + "px");
                panelSelectionArea.style("width", (window.innerWidth - d3.event.x - 5) + "px");
              } else {
                dragHandle.style("left", "5px");
                clickHandle.style("left", "0px");
                panelSelectionArea.style("left", "35px");
                panelSelectionArea.style("width", "calc(100vw - 37px)");
              }
            })
            .on("end", () => {
              if(d3.event.x + 30 > window.innerWidth - 250) {
                draggableHandle.call(d3.drag().on("drag", null));
              }
            })
        );
      }
    },
    resetScroll(selection) {
      const container = d3.select(selection);

      if(!container.empty()) {
        container._groups[0][0].scrollLeft = 0;
        container._groups[0][0].scrollTop = 0;
      }
    },
    translateElement(element, cordsX, cordsY) {
      element.attr("transform", "translate(" + cordsX + "," + cordsY + ")");
    },
    translateAndCenterElement(element) {
      const elementProps = this.getSelectionProps(element);

      const windowH = window.innerHeight;

      const elementX = window.innerWidth > elementProps.width
        ? (window.innerWidth - elementProps.width) * 0.5
        : (elementProps.width - window.innerWidth) * 0.5;

      const elementY = windowH > elementProps.height
        ? (windowH - elementProps.height) * 0.5
        : (elementProps.height - windowH) * 0.5;

      this.translateElement(element, elementX, elementY);
    }
  }
}
