var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isTimelineRoute
    ? _c(
        "div",
        {
          class: [
            "panels-content",
            {
              "bookmarks-shown": _vm.bookmarksShown
            }
          ]
        },
        [
          _c(
            "BasicPanel",
            {
              attrs: {
                openIcon: _vm.leftPanelOpenIcon,
                closeIcon: _vm.leftPanelCloseIcon,
                panelDirection: "left",
                panelClasses: ["companies"]
              }
            },
            [_c("FeaturedSearchContent")],
            1
          ),
          _c(
            "BasicPanel",
            {
              attrs: {
                openIcon: _vm.rightPanelOpenIcon,
                closeIcon: _vm.rightPanelCloseIcon,
                "draggable-panel": true,
                panelDirection: "right",
                panelClasses: ["thought-details"]
              }
            },
            [
              !_vm.isMobile || _vm.rotatedMobile
                ? _c("ThoughtDetailsContent")
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }