var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-custom-scrollbar",
    { attrs: { settings: _vm.scrollSettings } },
    [
      _c(
        "div",
        { class: "bookmarks-bar" },
        _vm._l(_vm.contentData, function(thought, index) {
          return _c(
            "div",
            {
              key: thought.id,
              staticClass: "bookmark",
              on: {
                click: function($event) {
                  return _vm.gotoThought(thought)
                }
              }
            },
            [
              _c(
                "div",
                { class: ["bookmark-wrapper", _vm.selectedSection.label] },
                [
                  _vm.selectedSection.label === "history"
                    ? _c("BasicIcon", {
                        attrs: {
                          iconName: "arrowTail",
                          iconClasses: "arrow-tail"
                        }
                      })
                    : _vm._e(),
                  thought.icon
                    ? _c("img", {
                        staticClass: "no-select",
                        attrs: { src: thought.icon }
                      })
                    : _vm._e(),
                  _c("span", {
                    staticClass: "bookmark-content no-select",
                    domProps: { innerHTML: _vm._s(thought.title) }
                  }),
                  _vm.selectedSection.label === "pinned"
                    ? _c("BasicButton", {
                        attrs: {
                          buttonClasses: ["delete-pin"],
                          clickAction: _vm.deleteItemEvent,
                          clickParams: index,
                          iconName: "iconClose"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }