// // eslint-disable-next-line
// import moment from "moment";

// // import * as filterUtils from "./filterUtils.js";
import { fireStoreDatabase } from "../../../configs/firebase/databaseConfig";
import forEach from "lodash/forEach";
import orderBy from "lodash/orderBy";

const timelineChannel = process.env.VUE_APP_CHANNEL_TIMELINE || 'timeline-dev';

// eslint-disable-next-line
export const queryTimelineSearch = ({commit}, payload) => {
  commit("mutate", {
    property: "timelineSearchKeyword",
    value: payload
  });
}

// eslint-disable-next-line
export const queryCategories = ({commit}, payload) => {
  const userPreferences = fireStoreDatabase.firestore().collection(`${timelineChannel}/settings/categories`);
  let categories = [];
  userPreferences.get()
  .then(querySnapshot => {
    querySnapshot.forEach(doc => {
        categories.push({
          categoryType:  Number(doc.id),
          label: doc.data().label,
          selected: true
        })
      })

      commit("mutate", {
        property: "categories",
        value: categories
      });
    });
}

// eslint-disable-next-line
export const queryCompanies = ({commit}, payload) => {
  const userPreferences = fireStoreDatabase.firestore().collection(`${timelineChannel}/settings/companies`);
  let companies = [];
  userPreferences.get()
  .then(querySnapshot => {
    querySnapshot.forEach(doc => {
        companies.push({
          companyId:  doc.id,
          label: doc.data().label,
          selected: true
        })
      })

      commit("mutate", {
        property: "companies",
        value: companies
      });
    });
}

// eslint-disable-next-line
export const querySubCategories = ({commit}, payload) => {
  const userPreferences = fireStoreDatabase.firestore().collection(`${timelineChannel}/settings/subcategories`);
  let eventTypes = [];


  userPreferences.get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        eventTypes.push({
          categoryType: doc.data().categoryId,
          id:  doc.data().id,
          label: doc.data().label,
        })
      })

      forEach(eventTypes, itm => {
        itm.selected = true;
      })

      const orderedItems = orderBy(eventTypes, ['categoryType'], ['asc']);

      commit("mutate", {
        property: "subcategories",
        value: orderedItems
      });
    });
}

// eslint-disable-next-line
export const queryEventTypes = ({commit}) => {
  const userPreferences = fireStoreDatabase.firestore().collection(`${timelineChannel}/settings/types`);
  let eventTypes = [];

  userPreferences.get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        eventTypes.push({
          id: Number(doc.id),
          label: doc.data().label,
          selected: true
        });
      })

      commit("mutate", {
        property: "eventTypes",
        value: eventTypes
      });
    });
}

export const queryFirestoreSourceConfigs = ({commit}) => {
  const userPreferences = fireStoreDatabase.firestore().collection(`${timelineChannel}`);
  let yearSpan = {};

  return userPreferences.get()
    .then(querySnapshot => {
      let indexCount = 0;
      querySnapshot.forEach(doc => {
        if(doc.data().year) {
          const docData = doc.data();
          const extractedYear = docData.year.substr(5, 4);

          yearSpan[indexCount] = {
            id: docData.id,
            itemCount: indexCount,
            label: extractedYear
          }

          indexCount += 1;
        }
      })

      commit("mutate", {
        property: "yearSpan",
        value: yearSpan
      });
      // console.log("yearSpan", yearSpan)
      return yearSpan;
    });
}

// eslint-disable-next-line
export const browseYear = async ({state, commit}, payload) => {
  const timelineCollection = fireStoreDatabase.firestore();
  const yearsMap = {};
  const fullRequest = timelineCollection.collection(`${timelineChannel}`).get();
  const allDocs = await fullRequest
  let countYears = 0;
  // console.log(allDocs.docs.length)
  allDocs.forEach(async doc => {
    const yearDates = timelineCollection.collection(`${timelineChannel}/${doc.data().year}/dates`).get();
    const allDates = await yearDates;
    const yearPeriods = timelineCollection.collection(`${timelineChannel}/${doc.data().year}/periods`).get();
    const allPeriods = await yearPeriods;
    const yearRanges = timelineCollection.collection(`${timelineChannel}/${doc.data().year}/ranges`).get();
    const allRanges = await yearRanges;
    yearsMap[countYears] = doc.data();
    yearsMap[countYears]["itemCount"] = countYears;
    yearsMap[countYears]["label"] = doc.data().year.substr(doc.data().year.length - 4);
    yearsMap[countYears]["dates"] = [];
    yearsMap[countYears]["periods"] = [];
    yearsMap[countYears]["ranges"] = [];
    allDates.forEach(date => createEventItem(yearsMap[countYears]["dates"], date.data()));
    allPeriods.forEach(period => createEventItem(yearsMap[countYears]["periods"], period.data()));
    allRanges.forEach(range => createEventItem(yearsMap[countYears]["ranges"], range.data()));
    if(countYears === allDocs.docs.length - 1) {
      commit("mutate", {
        property: "yearsMap",
        value: yearsMap
      });
    }
    countYears += 1;
  })
}

function createEventItem(group, data)  {
  const newData = data;
  newData.id = createUUID();
  group.push(data);
}

function createUUID(){
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

export const setFiltersApplied = ({commit}, payload) => {
  commit("mutate", {
    property: "filterApplied",
    value: payload
  });
}
// eslint-disable-next-line
export const queryFilterConfigs = ({commit}) => {
  const userPreferences = fireStoreDatabase.firestore().collection(`${timelineChannel}/settings/filters`);
  let filterConfigs = [];

  userPreferences.get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        filterConfigs.push(doc.data());
      })

      commit("mutate", {
        property: "filterConfigs",
        value: filterConfigs
      });
    });
}
// eslint-disable-next-line
export const populateFilters = async ({commit}, payload) => {
  const timelineCollection = fireStoreDatabase.firestore();
  const promises = [];
  const filtersCollection = JSON.parse(JSON.stringify(payload));

  forEach(filtersCollection, filterItem => {
    filterItem.data = []
    promises.push(timelineCollection.collection(`${timelineChannel}/settings/${filterItem.label}`).get());
  })

  const allPromises = await Promise.all(promises)
  forEach(allPromises, async (resolvedPromise, resolvedPromiseIdx) => {
    await resolvedPromise;
    forEach(resolvedPromise.docs, filterDoc => {
      let itmToPush = filterDoc.data();
      itmToPush.selected = true;
      filtersCollection[resolvedPromiseIdx].data.push(itmToPush)
    })
  })

  commit("mutate", {
    property: "customFilters",
    value: filtersCollection
  });
}

// eslint-disable-next-line
export const setTimelineSearchInputFocus = ({commit}, payload) => {
  commit("mutate", {
    property: "timelineSearchInputFocus",
    value: payload
  });
}