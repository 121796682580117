export const setModalShown = ({commit}, payload) => {
  commit("mutate", {
    property: "modalShown",
    value: payload
  });
}
export const setModalInstance = ({commit}, payload) => {
  commit("mutate", {
    property: "modalInstance",
    value: payload
  });
}