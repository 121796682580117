var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-modal" }, [
    _c("div", { staticClass: "overlay", on: { click: _vm.closeDialog } }),
    _c("div", { staticClass: "dialog-box" }, [
      _c("h2", {
        staticClass: "dialog-title",
        domProps: { innerHTML: _vm._s(_vm.dialogTitle) }
      }),
      _c("p", {
        staticClass: "dialog-message",
        domProps: { innerHTML: _vm._s(_vm.dialogMessage) }
      }),
      _c(
        "div",
        { staticClass: "dialog-actions" },
        [
          _vm.dialogType === "warning"
            ? _c("LabeledButton", {
                attrs: {
                  buttonLabel: "CANCEL",
                  buttonClasses: ["type-b", "dialog-cancel-cta"],
                  clickAction: _vm.closeDialog
                }
              })
            : _vm._e(),
          _c("LabeledButton", {
            attrs: {
              buttonLabel: "OK",
              buttonClasses: ["type-b", "dialog-action-cta"],
              clickAction: _vm.dialogAction || _vm.closeDialog,
              clickParams: _vm.dialogActionParams
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }