<template>
    <FenixTimeline
      :data="timelineData"
      :categories="categories"
      :companies="companies"
      :customFilters="customFilters"
      :eventTypes="eventTypes"
      :subcategories="subcategories"
      :searchKeyword="searchKeyword"
      :searchInputFocus="searchInputFocus"
      :timelineTitle="timelineTitle"
      @passYearData="onYearDataRequest" />
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "getCategories",
      "getCompanies",
      "getCustomFilters",
      "getEventTypes",
      "getFilterConfigs",
      "getSubcategories",
      "getSkinConfig",
      "getTimelineSearchKeyword",
      "getTimelineSearchInputFocus",
      "getTimelineSkinConfig",
      "getYearsMap",
      "getYearSpan"
    ])
  },
  data() {
    return {
      categories: [],
      companies: [],
      customFilters: [],
      eventTypes: [],
      filterConfigs: {},
      subcategories: [],
      timelineData: [],
      timelineTitle: "",
      searchKeyword: "",
      searchInputFocus: false,
      yearToRequest: undefined
    }
  },
  destroyed() {
    },
  mounted() {
    this.componentInit();
  },
  watch: {
    getEventTypes(eventTypes) {
      this.eventTypes = eventTypes;
    },
    getCategories(categories) {
      this.categories = categories;
    },
    getCompanies(companies) {
      this.companies = companies;
    },
    getFilterConfigs(filterConfigs) {
      this.filterConfigs = filterConfigs;
      this.populateFilters(filterConfigs);
    },
    getSubcategories(subcategories) {
      this.subcategories = subcategories;
    },
    getSkinConfig(skinConfig) {
      this.setSkinVariables(skinConfig);
    },
    getCustomFilters(customFilters) {
      this.customFilters = customFilters;
    },
    getTimelineSearchKeyword(val) {
      this.searchKeyword = val;
    },
    getTimelineSearchInputFocus(val) {
      this.searchInputFocus = val;
    },
    getTimelineSkinConfig(skinConfig) {
      this.timelineTitle = skinConfig["--timeline-header-title-text"].value;
      this.setTimelineSkinVariables(skinConfig);
    },
    getYearsMap: {
      handler: function(val) {
        this.timelineData = val;
      },
      deep: true
    }
  },
  methods: {
    ...mapActions([
      "browseYear",
      "fetchSkinInfo",
      "populateFilters",
      "queryCategories",
      "queryCompanies",
      "queryEventTypes",
      "queryFilterConfigs",
      "querySubCategories",
      "setMapLoaded",
      "setSkinConfigSubscription",
      "setSkinVariables",
      "setTimelineSkinVariables",
      "setUserPreferencesSnapshotListener",
    ]),
    async componentInit() {
      this.setSkinConfigs();
      await this.queryFilterConfigs();
      await this.queryEventTypes();
      await this.queryCategories();
      await this.queryCompanies();
      await this.querySubCategories();
    },
    async onYearDataRequest(payload) {
      if(payload.yearData) {
        await this.browseYear(payload.yearData);
      }
    },
    setSkinConfigs() {
      this.fetchSkinInfo({
        collection: "settings"
      });

      this.setSkinConfigSubscription();
      this.setMapLoaded(true);

      this.setUserPreferencesSnapshotListener({
        user: this.$auth.user
      });
    }
  }
}
</script>
<style></style>