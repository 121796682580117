import Vue from "vue";
import Vuex from "vuex";

import authenticationStore from "./modules/authentication/authenticationStore";
import localSettingsStore from "./modules/localSettings/localSettingsStore";
import modalsStore from "./modules/modalsStore/modalsStore";
import panelsStore from "./modules/panelsStore/panelsStore";
import searchStore from "./modules/searchStore/searchStore";
import skinStore from "./modules/skinStore/skinStore";
import thoughtsStore from "./modules/thoughtsStore/thoughtsStore";
import tourStore from "./modules/tourStore/tourStore";
import userPreferencesStore from "./modules/userPreferencesStore/userPreferencesStore";
import timelineStore from "./modules/timelineStore/timelineStore";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    authenticationStore,
    localSettingsStore,
    modalsStore,
    panelsStore,
    searchStore,
    skinStore,
    timelineStore,
    thoughtsStore,
    tourStore,
    userPreferencesStore
  }
});
