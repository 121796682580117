var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bookmarks-indicator" },
    [
      _c("BasicIcon", { attrs: { iconName: _vm.sectionData.icon } }),
      _c("span", { staticClass: "selected-section-label no-select" }, [
        _vm._v(_vm._s(_vm.sectionData.label))
      ]),
      _c("BasicIcon", {
        attrs: { iconName: "iconChevronRight", iconClasses: "chevron" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }