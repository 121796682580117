var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "featured-search" }, [
    _c(
      "div",
      { staticClass: "search-box" },
      [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.filterSearch()
              }
            }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchInputModel,
                  expression: "searchInputModel"
                }
              ],
              ref: "featuredSearchInput",
              attrs: {
                placeholder: _vm.searchPlaceHolder,
                name: "search",
                type: "search",
                "aria-autocomplete": "both",
                "aria-haspopup": "false",
                autocapitalize: "off",
                autocomplete: "off",
                autocorrect: "off"
              },
              domProps: { value: _vm.searchInputModel },
              on: {
                change: _vm.filterSearch,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchInputModel = $event.target.value
                }
              }
            })
          ]
        ),
        _vm.searchInputModel !== ""
          ? _c("BasicButton", {
              attrs: {
                clickAction: _vm.resetSearch,
                buttonClasses: ["search-companies-close-button"],
                iconName: "iconClose"
              }
            })
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      {
        class: [
          "search-list",
          {
            "bookmarks-shown": _vm.bookmarksShown
          }
        ]
      },
      _vm._l(_vm.filteredThoughts, function(thought) {
        return _c(
          "div",
          {
            key: thought.thought_id,
            staticClass: "search-item",
            on: {
              click: function($event) {
                return _vm.goToThought(thought.thought_id)
              }
            }
          },
          [_vm._v(_vm._s(thought.title))]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }