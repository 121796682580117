<template>
  <div class="dialog-modal">
    <div class="dialog-arrow"></div>
    <div class="dialog-box">
      <div class="dialog-header">
        <div class="close-button"
          @click="closeDialog">
          <BasicIcon :iconName="'iconClose'"
            v-if="dialogType !== 'welcome' && dialogType !== 'end'"></BasicIcon>
        </div>
        <h2 class="dialog-title no-select" v-html="dialogTitle"></h2>
      </div>

      <p class="dialog-message no-select" v-html="dialogMessage"></p>

      <div class="dialog-footer">
        <div class="dialog-actions">
          <LabeledButton v-if="dialogType === 'welcome'"
            :buttonLabel="'NO THANKS'"
            :buttonClasses="['type-b', 'dialog-cancel-cta']"
            :clickAction="closeDialog"></LabeledButton>
          <LabeledButton v-if="dialogType === 'welcome'"
            :buttonLabel="'LET\'S GO'"
            :buttonClasses="['type-b', 'dialog-action-cta']"
            :clickAction="setStepForward"
            :clickParams="dialogActionParams"></LabeledButton>

          <LabeledButton v-if="dialogType === 'step'"
            :buttonLabel="'BACK'"
            :buttonClasses="['type-b', 'dialog-back-cta']"
            :clickAction="setStepBackward"></LabeledButton>
          <LabeledButton v-if="dialogType === 'step'"
            :buttonLabel="'NEXT'"
            :buttonClasses="['type-b', 'dialog-action-cta']"
            :clickAction="setStepForward"
            :clickParams="dialogActionParams"></LabeledButton>

          <LabeledButton v-if="dialogType === 'end'"
            :buttonLabel="'RESTART THE TOUR'"
            :buttonClasses="['type-b', 'dialog-back-cta']"
            :iconName="'iconRestartTour'"
            :clickAction="restartTour"></LabeledButton>
          <LabeledButton v-if="dialogType === 'end'"
            :buttonLabel="'START EXPLORING'"
            :buttonClasses="['type-b', 'dialog-action-cta']"
            :clickAction="closeDialog"
            :clickParams="dialogActionParams"></LabeledButton>
        </div>

        <div class="dialog-info no-select">
          <span v-html="dialogInfo"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicIcon from "../BasicIcon";
import LabeledButton from "../Buttons/LabeledButton";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import modalConfigurations from "./mixins/modalConfigurations";
import mobileModalConfigurations from "./mixins/mobileModalConfigurations";

export default {
  components: {
    BasicIcon,
    LabeledButton
  },
  computed: {
     ...mapGetters([
      "getCurrentTourStep",
      "getMobileMenuShown",
      "getTourInfo",
      "getTourSeen",
      "getTourStatus",
      "getWindowInfo"
    ])
  },
  mixins: [modalConfigurations, mobileModalConfigurations],
  data() {
    return {
      currentStep: 0,
      goingForward: true,
      isClosing: false,
      thoughtsSearchApi: process.env.VUE_APP_SEARCH_API
    }
  },
  props: [
    "dialogAction",
    "dialogActionParams",
    "dialogInfo",
    "dialogMessage",
    "dialogTitle",
    "dialogType"
  ],
  watch: {
    getCurrentTourStep(currentStep) {
      this.currentStep = currentStep;
    },
    getWindowInfo() {
      this.closeDialog();
    }
  },
  mounted() {
    this.componentInit();
  },
  methods: {
    ...mapActions([
      "createMarketPlaceSection",
      "fetchFeaturedSearchContent",
      "searchThoughtByString",
      "setMobileSearchShown",
      "setPanelWidth",
      "setCurrentMobilePanel",
      "setCurrentStep",
      "setMobilePanelHeight",
      "setTourShown",
      "toggleMobileMenu",
      "writeSection"
    ]),
    ...mapMutations([
      "setCurrentStepSettings",
      "resetNextStepSettings",
      "resetCurrentStepSettings",
      "resetPreviousStepSettings",
      "resetUIresets"
    ]),
    componentInit() {
      if(window.innerWidth < 768) {
        this.setMobileTourUI();
        this.setMobileTourResets();

        this.setCurrentMobilePanel(undefined);
        this.updatePanelHeight(".basic-mobile-panel.right", 0);
        this.setMobilePanelHeight({
          panelInstance: "right",
          height: 0
        });
        this.updatePanelHeight(".basic-mobile-panel.left", 0);
        this.setMobilePanelHeight({
          panelInstance: "left",
          height: 0
        });
      } else {
        this.setPanelWidth({
          panelInstance: "left",
          width: 0
        });
        this.setPanelWidth({
          panelInstance: "right",
          width: 0
        });

        this.setDesktopTourUI();
        this.setDesktopTourResets();
      }
    },
    closeDialog() {
      this.isClosing = true;
      this.resetCurrentStepSettings();
      this.setCurrentStep(0);
      this.setTourShown(false);
      this.saveTourSeen();

      this.$router.push({ path: "/" }).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (err.name != "NavigationDuplicated") {
        // But print any other errors to the console
          console.error(err);
        }
      });
    },
    restartTour() {
      this.setCurrentStep(0);
    },
    setStepBackward() {
      this.goingForward = false;
      this.setCurrentStep(this.currentStep - 1);
      this.setCurrentStepSettings();
      this.resetNextStepSettings();
    },
    setStepForward() {
      this.goingForward = true;
      this.setCurrentStep(this.currentStep + 1);
      this.setCurrentStepSettings();
      this.resetPreviousStepSettings();
      this.saveTourSeen();
    },
    saveTourSeen() {
      if(!this.getTourSeen) {
        let payload = {
          user: this.$auth.user,
          data: {
            tourSeen: true
          }
        }

        return this.createMarketPlaceSection(payload);
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../stylesheets/tour-dialog-modal.module.scss";
</style>