export const getFeaturedContent = state => {
  return state.featuredContent;
};
export const getFeaturedLabel = state => {
  return state.featuredLabel;
};
export const getPanelsInfo = state => {
  return state.panelsInfo;
};
export const getMobilePanelsInfo = state => {
  return state.mobilePanelsInfo;
};
export const getCurrentMobilePanel = state => {
  return state.currentMobilePanel;
};
export const getMobileMenuShown = state => {
  return state.mobileMenuShown;
}