var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "thoughts-bookmarks" }, [
    _vm.bookmarksShown
      ? _c(
          "div",
          { staticClass: "bookmarks-wrapper" },
          [
            _c("BookmarksIndicator", {
              attrs: { sectionData: _vm.selectedSection }
            }),
            _c("BookmarksBar", {
              attrs: {
                selectedSection: _vm.selectedSection,
                contentData: _vm.bookmarksContent,
                deleteItemEvent: _vm.confirmDeletePin
              }
            }),
            _c(
              "div",
              { staticClass: "bookmark-actions" },
              [
                _c("LabeledButton", {
                  attrs: {
                    buttonLabel: _vm.selectedSection.title,
                    buttonClasses: ["type-a", "show-icon"],
                    clickAction: _vm.selectedSection.actionClick,
                    iconName: _vm.selectedSection.actionIcon
                  }
                })
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }