import { fireStoreDatabase } from "../../../configs/firebase/databaseConfig";
import "firebase/firestore";

export const fetchMainSettings = ({commit}) => {
  const userPreferences = fireStoreDatabase.firestore().collection("app");
  userPreferences.doc(process.env.VUE_APP_CHANNEL_PREFIX)
  .onSnapshot(queryDoc => {
    const mainSettingsInfo = queryDoc.data()[process.env.VUE_APP_CHANNEL_SETTINGS_PATH];
    if(mainSettingsInfo) {
      delete mainSettingsInfo.skin;
      delete mainSettingsInfo.tour;

      commit("mutate", {
        property: "mainSettingsInfo",
        value: mainSettingsInfo
      });
    }
  });
}
export const setUserPreferencesSnapshotListener = ({commit}, payload) => {
  const profile = payload.user;
  const userPreferences = fireStoreDatabase.firestore().collection("user");

  const currentSubscription = userPreferences.doc(profile.email).onSnapshot(queryDoc => {
    commit("mutate", {
      property: "currentUserPreferencesSnapshot",
      value: queryDoc
    });

    if(currentSubscription) {
      commit("mutate", {
        property: "currentUserPreferencesSubscription",
        value: currentSubscription
      });
    }
  });
}
// eslint-disable-next-line
export const setUserRoles = ({commit}, payload) => {
  const profile = payload.user;
  const userPreferences = fireStoreDatabase.firestore().collection("user");

  userPreferences
    .doc(profile.email)
    .collection("channel_access")
    .doc(process.env.VUE_APP_CHANNEL_PREFIX).get()
    .then(roles => {
      commit("mutate", {
        property: "userRoles",
        value: roles.data()
      });
    });
}
// eslint-disable-next-line
export const createMarketPlaceSection = ({commit}, payload) => {
  const userPreferences = fireStoreDatabase.firestore().collection("user");

  const profile = payload.user;
  userPreferences.doc(profile.email).update(payload.data);
}
export const setCurrentBookmarksSection = ({commit}, payload) => {
  commit("mutate", {
    property: "currentBookmarksSection",
    value: payload
  });
}
export const setBookmarksShown = ({commit}, payload) => {
  commit("mutate", {
    property: "bookmarksShown",
    value: payload
  });
}
// eslint-disable-next-line
export const writeSection = ({commit}, payload) => {
const userPreferences = fireStoreDatabase.firestore().collection("user");

  const profile = payload.user;
  userPreferences.doc(profile.email).update(payload.data);
}
