<template>
<div class="tour-wrapper">
    <div class="tour-overlay"></div>
    <div class="tour-overlay intermediate"></div>
    <TourModal
      v-if="tourModalActive"
      :class="dialogClass"
      :dialogAction="dialogAction"
      :dialogActionParams="dialogActionParams"
      :dialogInfo="dialogInfo"
      :dialogTitle="dialogTitle"
      :dialogType="dialogType"
      :dialogMessage="dialogMessage"></TourModal>
  </div>
</template>

<script>
import TourModal from "./TourModal";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    TourModal
  },
  computed: {
     ...mapGetters([
      "getCurrentTourStep",
      "getCurrentUserPreferencesSnapshot",
      "getMainSettingsInfo",
      "getTourInfo"
    ])
  },
  data () {
    return {
      dialogAction: "",
      dialogActionParams: "",
      dialogClass: "",
      dialogInfo: "",
      dialogTitle: "",
      dialogType: "",
      dialogMessage: "",
      stepCounter: 0,
      isLoaded: false,
      tourInfo: {},
      tourModalActive: false
    }
  },
  props: {
  },
  watch: {
    getTourInfo(tourInfo) {
      if(this.getMainSettingsInfo["show_timeline_button"]) {
        this.tourInfo = tourInfo;
      } else {
        const tourInfoCopy = {...tourInfo};
        tourInfoCopy.steps.splice(5,1);
        this.tourInfo = tourInfoCopy;
      }
      this.renderTourInfo(this.tourInfo);
    },
    getCurrentTourStep(currentTourStep) {
      this.stepCounter = currentTourStep;
      this.renderTourInfo(this.tourInfo);
    }
  },
  mounted() {
    this.fetchTourInfo();
  },
  methods: {
    ...mapActions([
      "fetchTourInfo",
      "setCurrentStep"
    ]),
    renderTourInfo(tourInfo) {
      this.tourModalActive = true;
      this.dialogClass = `${tourInfo.steps[this.stepCounter].type}-${this.stepCounter}`;
      this.dialogMessage = tourInfo.steps[this.stepCounter].content;
      this.dialogTitle = tourInfo.steps[this.stepCounter].title;
      this.dialogType = tourInfo.steps[this.stepCounter].type;
      this.dialogInfo = this.stepCounter > 0 && this.stepCounter < tourInfo.config.total_number - 1
      ? `${this.stepCounter} of ${tourInfo.config.steps_number} `
      : '';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../../stylesheets/tour.module.scss";
</style>
