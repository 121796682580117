var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isFirebaseAuthenticated
    ? _c(
        "div",
        [
          _vm.tourShown ? _c("Tour") : _vm._e(),
          _c("Loader"),
          _c("Header"),
          _c("Bookmarks"),
          _c("Search"),
          _c("TimelineSearch"),
          _c("MobilePanelsContent"),
          _c("PanelsContent"),
          _c("router-view"),
          _vm.dialogModalShown
            ? _c("DialogModal", {
                attrs: {
                  dialogAction: _vm.dialogModalInfo.dialogAction,
                  dialogActionParams: _vm.dialogModalInfo.dialogActionParams,
                  dialogType: _vm.dialogModalInfo.dialogType,
                  dialogTitle: _vm.dialogModalInfo.dialogTitle,
                  dialogMessage: _vm.dialogModalInfo.dialogMessage
                }
              })
            : _vm._e(),
          _vm.previewPPTslides ? _c("SlidesPreview") : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }