import * as d3 from "d3";
import * as generalConfig from "../../../configs/generalConfig";

export const centerMap = ({state}) => {
  if(!d3.select(".main-thought .thoughts-wrapper").empty()) {
    const sidePanelProps = d3.select(".basic-panel.thought-details .panel-wrapper").node().getBoundingClientRect();
    const mainThoughtProps = d3.select(".main-thought .thoughts-wrapper").node().getBoundingClientRect();

    const identityX = state.zoomInfo.x || 0;
    const identityY = state.zoomInfo.y || 0;
    // const identityK = state.zoomInfo.k || 1;

    let mapX = (((identityX - mainThoughtProps.x) - mainThoughtProps.width * 0.5) + (window.innerWidth * 0.5 - sidePanelProps.width * 0.5)) - 15;
    let mapY = (((identityY - mainThoughtProps.y) - mainThoughtProps.height) + (window.innerHeight + 120) * 0.5);

    d3.select(".map-canvas")
      .transition()
      .duration(generalConfig.panningTime)
      .call(state.zoomEvent.transform, d3.zoomIdentity.translate(mapX, mapY).scale(1));
  }
}
export const resetZoom = ({state}) => {
  d3.select(".map-canvas")
    .call(state.zoomEvent.transform, d3.zoomIdentity);
}
export const startZoom = ({commit}) => {
  const mapZoom = d3.zoom().scaleExtent([1, 1])
    .on("zoom", () => {
      d3.select(".map-canvas .thoughts-group").attr("transform", d3.event.transform)
      commit("mutate", {
        property: "zoomInfo",
        value: d3.event.transform
      });
    });

  commit("mutate", {
    property: "zoomEvent",
    value: mapZoom
  });

  d3.select(".map-canvas").call(mapZoom);
};
export const stopZoom = () => {
  d3.select(".map-canvas").on("zoom", null);
}