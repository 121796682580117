<template>
<div class="mobile-header-menu">
  <div class="header-buttons-menu-overlay"
    :class="[
        {
          closed: !mobileMenuOpen
        }
      ]"
    @click="toggleMobileMenu"></div>

  <div :class="
    [
    'header-buttons-menu',
    {
        open: mobileMenuOpen,
        closed: !mobileMenuOpen
    }
    ]">
    <LabeledButton
    v-for="button in buttonList"
    :class="['header-button-' + button.label, {selected: button.selected}]"
    :key="button.label"
    :buttonLabel="button.label"
    :buttonClasses="['type-a']"
    :clickAction="button.clickAction"
    :clickParams="button.clickParams"
    :iconName="button.icon"></LabeledButton>
  </div>
</div>
</template>
<script>
import * as d3 from "d3";
import LabeledButton from "../Buttons/LabeledButton";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    LabeledButton
  },
  computed: {
    ...mapGetters([
      "getMobileMenuShown"
    ])
  },
  data() {
    return {
      mobileMenuOpen: false,
      headerOverlayClasses: []
    }
  },
  props: {
    buttonList: Array,
  },
  watch: {
    getMobileMenuShown(isShown) {
      this.mobileMenuOpen = isShown;
      this.setCurrentMobilePanel(undefined);
      d3.select(".app-header .bookmark-cta-search")
        .classed("tour-highlight", false);
    }
  },
  mounted() {
    this.componentInit();
  },
  methods: {
    ...mapActions([
      "setCurrentMobilePanel",
      "toggleMobileMenu"
    ]),
    componentInit() {
    }
  }
}
</script>
