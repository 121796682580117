<template>
  <div :class="['thought-details', { loading }]">
    <Spinner />
    <div class="panel-area">
      <h2>
        {{ thoughtTitle }}
        <BasicButton
          v-if="isAdmin && !isMobileScreen"
          :buttonClasses="['edit-post-button']"
          :clickAction="editPost"
          :iconName="'iconEdit'"
        >
        </BasicButton>

        <BasicButton
          v-if="hasPPTconversion"
          :buttonClasses="['convert-to-ppt-button']"
          :clickAction="previewPPTconversion"
          :iconName="'iconPowerpointFile'"
        >
        </BasicButton>
      </h2>
      <div class="panel-content">
        <div v-if="thoughtNotes" :class="['thought-content', 'thought-notes']">
          <!-- <h3>Notes</h3> -->
          <div v-html="thoughtNotes"></div>
        </div>

        <div v-if="thoughtAttachments" class="thought-content">
          <!-- <h3>Attachments</h3> -->
          <ul>
            <li v-for="attachment in thoughtAttachments" :key="attachment.name">
              <img
                v-if="!attachment.is_icon && attachment.format !== '.pdf'"
                :src="attachment.location"
              />
              <span
                class="icon-link-container"
                v-if="!attachment.is_icon && attachment.format === '.pdf'"
              >
                <svg height="24" width="24"><path :d="iconPdf"></path></svg>
                <a :href="attachment.location" target="_blank">{{
                  attachment.location
                }}</a>
              </span>
            </li>
          </ul>
        </div>
        <div
          v-if="thoughtLinks && thoughtLinks.length > 0"
          class="thought-content"
        >
          <h3>External Links</h3>
          <ul class="title-icon-wrapper">
            <li v-for="externalLink in thoughtLinks" :key="externalLink.name">
              <a :href="externalLink.location" target="_blank">
                <div>
                  <p>
                    <img :src="externalLink.icon" />
                    {{ externalLink.name }}
                  </p>
                  <span>{{ externalLink.location }}</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import he from "he";
import * as d3 from "d3";
import { isEmpty } from "lodash";

import Spinner from "../Spinner";
import { d3Operations } from "../../mixins/d3Operations";
import { isMobile } from "mobile-device-detect";
import BasicButton from "../Buttons/BasicButton";
import { mapActions, mapGetters } from "vuex";
import PptxGenJS from "pptxgenjs";

export default {
  components: {
    BasicButton,
    Spinner,
  },
  computed: {
    ...mapGetters(["getHierarchyData", "getUserRoles", "getWindowInfo"]),
  },
  data() {
    return {
      loading: true,
      hasPPTconversion: false,
      iconPdf: "M0 0",
      isAdmin: false,
      isMobileScreen: false,
      selectedThoughtData: [],
      thoughtLinks: [],
      thoughtAttachments: [],
      thoughtNotes: "",
      thoughtTitle: "",
      userRoles: {},
    };
  },
  mixins: [d3Operations],
  mounted() {
    this.componentInit();

    if (!isEmpty(this.getHierarchyData)) {
      this.renderHierarchyData(this.getHierarchyData);
    }
  },
  watch: {
    $route() {
      this.loading = true;
      this.leftOpenedWidth = 260;
    },
    searchInputModel() {
      this.filterSearch();
    },
    getHierarchyData(hierarchyData) {
      setTimeout(() => {
        this.setDisplayOnLoad();
        this.hasPPTconversion =
          this.$route.params.id === "0571ad69-4e3c-4564-bcd0-7f8da0c47d6d"
            ? true
            : false;
      });

      this.setThoughtInfo(hierarchyData);
    },
    getUserRoles(userRoles) {
      this.configUserRoles(userRoles);
    }
  },
  methods: {
    ...mapActions([
      "setUserRoles"
    ]),
    componentInit() {
      this.setUserRoles({ user: this.$auth.user });

      this.iconPdf = "iconPdf";
      this.isMobileScreen = isMobile;
      this.rightOpenedWidth = window.innerWidth - window.innerWidth / 2;
      this.loading = true;

      window.addEventListener("message", (payload) => {
        d3.selectAll("iframe").style(
          "height",
          "var(--details-panel-iframe-height)"
        );
        d3.select(":root").style(
          "--details-panel-iframe-height",
          `${payload.data.frameHeight}px`
        );
      });
    },
    configUserRoles(userRoles) {
      if(userRoles) {
        this.isAdmin = userRoles.role.search("Analyst Admin") > -1
          || userRoles.role === "Engineer Admin"
          || userRoles.role === "Administrator";
      }
    },
    editPost() {
      const selectedThoughtPost = this.selectedThoughtData.thoughtNodes[0].data
        .post_id;
      const editUrl =
        process.env.VUE_APP_WP_ADMIN_PATH +
        "post.php?post=" +
        selectedThoughtPost +
        "&action=edit";

      window.open(editUrl);
    },
    previewPPTconversion() {
      const presentation = new PptxGenJS();
      d3.select(".module-box table").attr("id", "pptConversionTest");
      presentation.tableToSlides("pptConversionTest");
      presentation.writeFile("pptConversionTest0.pptx");
    },
    setDisplayOnLoad() {
      const iframeSelection = d3.selectAll(".profile_iframe");

      this.loading = iframeSelection.empty() ? false : true;

      if (!iframeSelection.empty()) {
        // eslint-disable-next-line
        document
          .querySelector(".profile_iframe")
          .addEventListener("load", () => {
            this.loading = false;
            d3.selectAll(".profile_iframe").style(
              "height",
              "calc(var(--vh) * 100 - 180px)"
            );
          });
      }
    },
    setThoughtInfo(thoughtNodesData) {
      const nodeData = thoughtNodesData.thoughtNodes[0];

      this.selectedThoughtData = thoughtNodesData;
      this.thoughtTitle = he.decode(nodeData.data.title);
      this.thoughtNotes = nodeData.data.notes;
      this.thoughtAttachments = nodeData.attachments
        ? nodeData.attachments
        : undefined;
      this.thoughtLinks = nodeData.external_links
        ? nodeData.external_links
        : undefined;
    },
    renderHierarchyData(hierarchyData) {
      let displayTimeout;
      clearTimeout(displayTimeout);

      displayTimeout = setTimeout(() => {
        this.setDisplayOnLoad();
      }, 300);

      this.setThoughtInfo(hierarchyData);
    },
  },
};
</script>

<style lang="scss">
@import "../../stylesheets/thought-details-content.module.scss";
@import "../../stylesheets/iframe.test.module.scss";
</style>