var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-header" },
    [
      _c(
        "div",
        { staticClass: "header-logo-wrapper", on: { click: _vm.goHome } },
        [
          _c("img", {
            staticClass: "logo-desktop",
            attrs: { src: _vm.headerLogo }
          }),
          _c("img", {
            staticClass: "logo-mobile",
            attrs: { src: _vm.headerLogoMobile }
          })
        ]
      ),
      _c("MobileHeaderMenu", {
        ref: "mobileMenu",
        attrs: { buttonList: _vm.headerButtonsList }
      }),
      _c(
        "div",
        { staticClass: "mobile-header-buttons-group" },
        [
          _c("LabeledButton", {
            class: "header-button-mobile-home",
            attrs: { clickAction: _vm.goHome, iconName: "iconHome" }
          }),
          _c("LabeledButton", {
            class: "header-button-hamburguer",
            attrs: {
              clickAction: _vm.toggleMobileMenuShown,
              iconName: "iconHamburguer"
            }
          })
        ],
        1
      ),
      _c("LabeledButton", {
        class: { selected: _vm.searchSelected },
        attrs: {
          buttonLabel: "SEARCH",
          buttonClasses: ["type-a", "bookmark-cta-search"],
          clickAction: _vm.toggleMobileSearch,
          iconName: "iconSearch"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }