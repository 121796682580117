<template>
  <svg
    v-if="iconPath"
    :class="['basic-icon', iconClassesData]"
    :viewBox="iconViewBox">
    <path :d="iconPath"/>
  </svg>
</template>
<script>
import { iconsMixin } from "../mixins/iconsMixin"

export default {
  data() {
    return {
      iconViewBox: "",
      iconPath: "",
      iconClassesData: ""
    }
  },
  props: ["iconName", "iconClasses"],
  mixins: [iconsMixin],
  mounted() {
    this.componentInit();
  },
  watch: {
    iconName() {
      this.componentInit()
    }
  },
  methods: {
    componentInit() {
      this.iconClassesData = this.iconClasses || "";
      this.iconPath = this[this.iconName].d || "M10 10";
      this.iconViewBox = this[this.iconName].viewBox;
    }
  }
}
</script>
<style lang="scss"></style>