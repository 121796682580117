import * as generalConfig from "../../../configs/generalConfig";
import { find, forEach } from "lodash";

export default {
  methods: {
    addLatestThoughtTo(section){
      if(this.getCurrentUserPreferencesSnapshot.data) {
        const bookmarksContent = this.getCurrentUserPreferencesSnapshot.data()[`user_${section}`][this.userPreferencesChannel];
        const newBookmarkRecords = bookmarksContent ? [...bookmarksContent] : [];

        if(section === "history") {
          const lastHistoryItem = newBookmarkRecords[newBookmarkRecords.length - 1];
          const currentHistoryItem = this.getHierarchyData.thoughtNodes[0];

          if(newBookmarkRecords.length === 0 || currentHistoryItem.data.thought_id !== lastHistoryItem.thought_id) {
            newBookmarkRecords.push(this.processHistoryItem(this.getHierarchyData.thoughtNodes[0]));
          }
        }

        if(section === "pinned") {
          if(!find(newBookmarkRecords, {thought_id: this.getHierarchyData.thoughtNodes[0].data.thought_id})) {
            newBookmarkRecords.push(this.processHistoryItem(this.getHierarchyData.thoughtNodes[0]));
          } else {
            this.setModalShown(true);
            this.setModalInstance({
              dialogTitle: "EXISTING PIN",
              dialogMessage: `<strong>${this.getHierarchyData.thoughtNodes[0].data.title}</strong> ${generalConfig.duplicatePinnedWarningMessage}`
            });
          }
        }

        const updateObject = {...this.getCurrentUserPreferencesSnapshot.data()};
        updateObject[`user_${section}`][this.userPreferencesChannel] = newBookmarkRecords;

        this.writeSection({
          user: this.$auth.user,
          data: updateObject
        });
      }
    },
    addPinnedItem() {
      this.addLatestThoughtTo("pinned");
    },
    deletePin(evt, thoughtId){
      const newBookmarkRecords = [...this.getCurrentUserPreferencesSnapshot.data()['user_pinned'][this.userPreferencesChannel]];

      forEach(newBookmarkRecords, (itm, idx) => {
        if(itm && itm.thought_id === thoughtId) {
          newBookmarkRecords.splice(idx, 1);
          return;
        }
      });

      const updateObject = {};
      updateObject['user_pinned'] = {};
      updateObject['user_pinned'][this.userPreferencesChannel] = newBookmarkRecords;

      this.writeSection({
        user: this.$auth.user,
        data: updateObject
      });

      this.setModalShown(false);
    },
    discardHistory() {
      const updateObject = {
        user_history: {}
      };

      updateObject.user_history[this.userPreferencesChannel] = [];

      this.writeSection({
        user: this.$auth.user,
        data: updateObject
      })

      this.setModalShown(false);
    },
    confirmDeletePin(evt, idx) {
      const pinToDelete = this.bookmarksContent[idx];

      this.setModalShown(true);
      this.setModalInstance({
          dialogAction: this.deletePin,
          dialogActionParams: pinToDelete["thought_id"],
          dialogType: "warning",
          dialogTitle: generalConfig.removePinnedConfirmationTitle,
          dialogMessage: "<strong>" + pinToDelete.title + "</strong>" + generalConfig.removePinnedConfirmationMessage
        });
    },
    confirmDiscardHistory() {
      this.setModalShown(true);
      this.setModalInstance({
        dialogAction: this.discardHistory,
        dialogType: "warning",
        dialogTitle: generalConfig.clearHistoryConfirmationTitle,
        dialogMessage: generalConfig.clearHistoryConfirmationMessage
      });
    }
  }
}
