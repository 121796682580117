var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mobile-header-menu" }, [
    _c("div", {
      staticClass: "header-buttons-menu-overlay",
      class: [
        {
          closed: !_vm.mobileMenuOpen
        }
      ],
      on: { click: _vm.toggleMobileMenu }
    }),
    _c(
      "div",
      {
        class: [
          "header-buttons-menu",
          {
            open: _vm.mobileMenuOpen,
            closed: !_vm.mobileMenuOpen
          }
        ]
      },
      _vm._l(_vm.buttonList, function(button) {
        return _c("LabeledButton", {
          key: button.label,
          class: [
            "header-button-" + button.label,
            { selected: button.selected }
          ],
          attrs: {
            buttonLabel: button.label,
            buttonClasses: ["type-a"],
            clickAction: button.clickAction,
            clickParams: button.clickParams,
            iconName: button.icon
          }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }