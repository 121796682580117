var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["thought-details", { loading: _vm.loading }] },
    [
      _c("Spinner"),
      _c("div", { staticClass: "panel-area" }, [
        _c(
          "h2",
          [
            _vm._v(" " + _vm._s(_vm.thoughtTitle) + " "),
            _vm.isAdmin && !_vm.isMobileScreen
              ? _c("BasicButton", {
                  attrs: {
                    buttonClasses: ["edit-post-button"],
                    clickAction: _vm.editPost,
                    iconName: "iconEdit"
                  }
                })
              : _vm._e(),
            _vm.hasPPTconversion
              ? _c("BasicButton", {
                  attrs: {
                    buttonClasses: ["convert-to-ppt-button"],
                    clickAction: _vm.previewPPTconversion,
                    iconName: "iconPowerpointFile"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "panel-content" }, [
          _vm.thoughtNotes
            ? _c("div", { class: ["thought-content", "thought-notes"] }, [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.thoughtNotes) } })
              ])
            : _vm._e(),
          _vm.thoughtAttachments
            ? _c("div", { staticClass: "thought-content" }, [
                _c(
                  "ul",
                  _vm._l(_vm.thoughtAttachments, function(attachment) {
                    return _c("li", { key: attachment.name }, [
                      !attachment.is_icon && attachment.format !== ".pdf"
                        ? _c("img", { attrs: { src: attachment.location } })
                        : _vm._e(),
                      !attachment.is_icon && attachment.format === ".pdf"
                        ? _c("span", { staticClass: "icon-link-container" }, [
                            _c(
                              "svg",
                              { attrs: { height: "24", width: "24" } },
                              [_c("path", { attrs: { d: _vm.iconPdf } })]
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: attachment.location,
                                  target: "_blank"
                                }
                              },
                              [_vm._v(_vm._s(attachment.location))]
                            )
                          ])
                        : _vm._e()
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm.thoughtLinks && _vm.thoughtLinks.length > 0
            ? _c("div", { staticClass: "thought-content" }, [
                _c("h3", [_vm._v("External Links")]),
                _c(
                  "ul",
                  { staticClass: "title-icon-wrapper" },
                  _vm._l(_vm.thoughtLinks, function(externalLink) {
                    return _c("li", { key: externalLink.name }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: externalLink.location,
                            target: "_blank"
                          }
                        },
                        [
                          _c("div", [
                            _c("p", [
                              _c("img", { attrs: { src: externalLink.icon } }),
                              _vm._v(" " + _vm._s(externalLink.name) + " ")
                            ]),
                            _c("span", [_vm._v(_vm._s(externalLink.location))])
                          ])
                        ]
                      )
                    ])
                  }),
                  0
                )
              ])
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }