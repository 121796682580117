import * as d3 from "d3";

export default {
  methods: {
    positionParents() {
      const mainThoughtElem = d3.select(".main-thought");
      const mainThoughtElemProps = this.getSelectionProps(mainThoughtElem);
      const parentNodes = d3.selectAll(".thought-parents " + this.thoughtNodeSelection);
      const parentsWrapper = d3.selectAll(".thought-parents .thoughts-wrapper");

      if(!parentNodes.empty()) {
        const widestParentProps = this.getSelectionProps(this.getWidestThought(parentNodes));
        const maxParentsColumns = Math.floor(window.innerWidth / widestParentProps.width);
        const sidePanelInstance = d3.select(".basic-panel.right .panel-wrapper");
        const sidePanelGap = sidePanelInstance.empty() ? 0 : this.getSelectionProps(sidePanelInstance).width;

        let childrenCalculatedColumns = parentNodes.size() <= maxParentsColumns && parentNodes.size()
            ? parentNodes.size()
            : maxParentsColumns;

        const parentsWrapperX = (window.innerWidth - (childrenCalculatedColumns * widestParentProps.width)) * 0.5 - sidePanelGap * 0.5;
        const parentsWrapperY = mainThoughtElemProps.y - 55 - Math.ceil(parentNodes.size() / childrenCalculatedColumns) * 47 - 100;

        this.breakIntoColumns(
          parentsWrapper,
          maxParentsColumns,
          widestParentProps,
          {x: parentsWrapperX, y: parentsWrapperY},
          {delay: 0, duration: 500}
        );
      }
    }
  }
}