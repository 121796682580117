var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoaded
    ? _c("div", { staticClass: "app-loader" }, [
        _c("svg", { class: ["loader-logo"], attrs: { viewBox: "0 0 80 80" } }, [
          _c("path", { attrs: { d: _vm.loaderIcon } })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }