import * as getters from "./panelsGetters";
import * as mutations from "./panelsMutations";
import * as actions from "./panelsActions";

export default {
  state: {
    featuredLabel: "",
    featuredContent: {},
    currentMobilePanel: undefined,
    mobileMenuShown: false,
    mobilePanelsInfo: {
      left: {
        content: "",
        height: 0
      },
      right: {
        content: "",
        height: 0
      }
    },
    panelsInfo: {
      left: {
        content: "",
        width: 0
      },
      right: {
        content: "",
        width: 0
      }
    }
  },
  getters,
  mutations,
  actions,
}
