<template>
  <div class="app-loader" v-if="!isLoaded">
    <svg
      viewBox="0 0 80 80"
      :class="['loader-logo']">
      <path :d="loaderIcon"/>
    </svg>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
// import BasicIcon from "./BasicIcon"


export default {
  components: {
    // BasicIcon
  },
  computed: {
    ...mapGetters([
      "getMapLoaded",
      "getSkinConfig"
    ])
  },
  data () {
    return {
      isLoaded: false,
      loaderIcon: ""
    }
  },
  name: 'Loader',
  props: {
  },
  watch: {
    getMapLoaded(val) {
      this.isLoaded = val;
    },
    getSkinConfig(val) {
      this.loaderIcon = val["loader-svg"].value;
    }
  },
  methods: {
    detectMapLoaded(isMapLoaded) {
      this.isLoaded = isMapLoaded;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes pulseIcon {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.app-loader {
  background-color: rgba(0,0,0,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: calc(var(--vh) * 100);
  width: 100vw;
  z-index: 100000;
}
svg.loader-logo {
  animation-duration: 1.5s;
  animation-name: pulseIcon;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  fill: #FFF;
  stroke: none;
  height: 80px;
  width: 80px;
}
</style>
