<template>
<div
  v-if="!isTimelineRoute"
  :class="[
    'mobile-panels-content',
    {
      'bookmarks-shown': bookmarksShown
    }
]">
  <BasicMobilePanel
    :handleIcon="leftPanelOpenIcon"
    :panelDirection="'bottom'"
    :panelClasses="['left']"
    :panelInstance="'companies'">
    <!-- Featured Search Panel Content -->
    <FeaturedSearchContent></FeaturedSearchContent>
  </BasicMobilePanel>

  <BasicMobilePanel
    :handleIcon="rightPanelOpenIcon"
    :panelClasses="['right']"
    :panelDirection="'bottom'"
    :panelInstance="'details'">
    <!-- Thought Details Content -->
    <ThoughtDetailsContent
      v-if="isMobile && !rotatedMobile"></ThoughtDetailsContent>
  </BasicMobilePanel>
</div>
</template>
<script>
import * as d3 from "d3";
import { isMobile } from 'mobile-device-detect';

import BasicMobilePanel from "../Panel/BasicMobilePanel";
import { d3Operations } from "../../mixins/d3Operations";
import FeaturedSearchContent from "./FeaturedSearchContent";
import ThoughtDetailsContent from "./ThoughtDetailsContent";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "getDeviceOrientation",
      "getWindowInfo"
    ])
  },
  data() {
    return {
      bookmarksShown: false,
      featuredAPI: process.env.VUE_APP_FEATURED_API,
      isMobile: false,
      isTimelineRoute: false,
      leftPanelOpenIcon: "iconBuilding",
      rightPanelOpenIcon: "iconInfo",
      rotatedMobile: false
    }
  },
  components: {
    BasicMobilePanel,
    FeaturedSearchContent,
    ThoughtDetailsContent
  },
  mixins: [d3Operations],
  mounted() {
    this.componentInit();
  },
  watch: {
    "$route"() {
      this.isTimelineRoute = this.$route.name === "timeline";
    },
    getDeviceOrientation() {
      this.componentInit();
    },
    getWindowInfo() {
      this.componentInit();
    }
  },
  methods: {
    ...mapActions([
      "setCurrentMobilePanel"
    ]),
    adjustLayout(bookmarkData) {
      this.bookmarksShown = bookmarkData ? true : false;
    },
    componentInit() {
      this.calculateViewportSize();
      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
      const isFirefoxAndroid = isAndroid && isFirefox;

      this.isMobile = window.innerWidth < 760 || isMobile && !isFirefoxAndroid ? true : false;
      this.rotatedMobile = isMobile && window.innerWidth >= 768;
    },
    calculateViewportSize() {
      let vh = window.visualViewport.height * 0.01;
      d3.select(":root").style("--vh", `${vh}px`);
    }
  },
}
</script>

<style lang="scss">
@import "../../stylesheets/mobile-panels-content.module.scss";
</style>