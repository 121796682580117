import * as getters from "./skinGetters";
import * as mutations from "./skinMutations";
import * as actions from "./skinActions";

export default {
  state: {
    currentSkinConfigSubscription: {
    },
    skinConfig: {
    },
    skinSchemedConfig: {
    },
    timelineSkinConfig: {
    },
    timelineSkinSchemedConfig: {
    }
  },
  getters,
  mutations,
  actions,
}
