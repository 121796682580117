import { filter, forEach } from "lodash";

export const thoughtFilters = {
  methods: {
    fetchRelatedThoughts(thoughtNode) {
      return thoughtNode && thoughtNode.data.relations ? thoughtNode.data.relations.map(itm => {
        return itm;
      }) : [];
    },
    filterByCollection(sourceData, targetData) {
      forEach(sourceData, parentItm => {
        forEach(targetData, (filteredItm, idx) => {
          if(filteredItm && filteredItm.data.thought_id === parentItm.data.thought_id) {
            targetData.splice(idx, 1);
          }
        })
      })
    },
    filterThoughtByKey(filteredItm, thoughtNode, key, relation) {
      const selectedThoughtID = thoughtNode.data.thought_id;
      const relationMatch = Number(filteredItm.data.relation) === relation;

      // PREVENT type and tag filtering
      // const isType = filteredItm.data.kind === 2 || filteredItm.data.kind === 4;
      // const isTag = filteredItm.data.meaning === 2 || filteredItm.data.meaning === 5;

      if(filteredItm[key] === selectedThoughtID && relationMatch && filteredItm.data.relations) {
        return filteredItm;
      }
    },
    findSelectedSiblings(thoughtsData) {
      const siblingsCollection = [];

      forEach(thoughtsData.thoughtParents, itm => {
        const relationsData = {};
        const parentRelations = filter(itm.data.relations, filteredItm =>
          filteredItm.data.thought_id !== thoughtsData.thoughtNodes[0].data.thought_id);

        relationsData.parent = itm;
        relationsData.relations = parentRelations;
        siblingsCollection.push(relationsData);
      });

      return siblingsCollection;
    },
    setUniqueRecords(data) {
      return data.filter((itm, idx, arr) => idx === arr.length - 1 ? itm : itm.data.thought_id !== arr[idx + 1].data.thought_id);
    },
    sortBookmarks(data) {
      return data.sort(function(a, b) {
          const valueA = a.title ? a.title.toUpperCase() : "";
          const valueB = b.title ? b.title.toUpperCase() : "";
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      });
    },
    sortThoughts(data) {
      return data.sort(function(a, b) {
          const valueA = a.data.title ? a.data.title.toUpperCase() : "";
          const valueB = b.data.title ? b.data.title.toUpperCase() : "";
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      });
    }
  }
}

export const thoughtValidators = {
  methods: {
    hasChildren(nodeItm) {
      const rels = filter(nodeItm.data.relations, itmToFilter =>
        itmToFilter.thought_id_a === nodeItm.data.thought_id);

      return rels.length > 0;
    },
    hasParents(nodeItm) {
      if(nodeItm.thoughtSibling) {
        return true;
      }

      const rels = filter(nodeItm.data.relations, itmToFilter => {
        return itmToFilter.thought_id_b === nodeItm.data.thought_id
      });

      return rels.length > 0;
    },
    hasRelatedSiblings(nodeItm) {
      const rels = filter(nodeItm.data.relations, itmToFilter =>
        Number(itmToFilter.data.relation) === 3);

      return rels.length > 0;
    }
  }
}
