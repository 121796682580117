<template>
  <!-- <div v-if="showTimelineSearch" -->
  <div v-if="false"
    :class="['timeline-search']">
    <BasicButton
      v-if="searchKeyword"
      :clickAction="closeSearch"
      :buttonClasses="['close-search']"
      :iconName="'iconClose'"
      :style="{
      }"></BasicButton>
    
    <BasicIcon
      v-if="!searchKeyword"
      :iconClasses="['search-icon']"
      :iconName="'iconSearch'"></BasicIcon>

    <form
      @submit.prevent="submitTimelineSearchKeyboard(searchKeyword)"
      action="">
      <input
        ref="timelineSearchInput"
        v-model="searchKeyword"
        :class="{}"
        @focus="setTimelineSearchInputFocus(true)"
        @blur="setTimelineSearchInputFocus(false)"
        name="search"
        type="search"
        aria-autocomplete="both" aria-haspopup="false" autocapitalize="off" autocomplete="off" autocorrect="off"
        placeholder="SEARCH TIMELINE"/>
    </form>
  </div>
</template>

<script>
// import { thoughtFilters } from "../../mixins/thoughtOperations";
// import { isAndroid } from "mobile-device-detect";
import { iconsMixin } from "../../mixins/iconsMixin";
import BasicButton from "../../components/Buttons/BasicButton";
import BasicIcon from "../../components/BasicIcon";
// import deviceDetection from "../../mixins/deviceDetection";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BasicButton,
    BasicIcon
  },
  computed: {
    ...mapGetters([
    ])
  },
  data() {
    return {
      displayResults: false,
      searchKeyword: "",
      showTimelineSearch: false
    }
  },
  name: "TimelineSearch",
  mixins: [iconsMixin],
  props: {
  },
  destroyed() {
  },
  mounted() {
    this.componentInit();
  },
  updated() {
  },
  watch: {
    "$route"() {
      this.toggleSearchInput();
    },
    searchKeyword(val) {
      if(!val) {
        this.submitTimelineSearchKeyboard("");
      }
    }
  },
  methods: {
    ...mapActions([
      "queryTimelineSearch",
      "setTimelineSearchInputFocus"
    ]),
    componentInit() {
      this.toggleSearchInput();
    },
    closeSearch() {
      this.searchKeyword = "";
      this.submitTimelineSearchKeyboard("");
    },
    submitTimelineSearchKeyboard(evt) {
      this.queryTimelineSearch(evt)
    },
    toggleSearchInput() {
      this.showTimelineSearch = this.$route.name !== "timeline" ? false : true;
      if(this.$route.name !== "timeline") {
        this.searchKeyword = "";
      }
    },
  }
}
</script>

<style lang="scss">
@import "../../stylesheets/timeline-search.module.scss";
</style>
