<template>
  <div :class="[
    'basic-panel',
    panelDirection,
    panelClasses,
    {
      'bookmarks-shown': bookmarksShown,
      'draggable-panel': draggablePanel,
      opened: panelOpened
    }
  ]">
    <BasicButton
      v-if="draggablePanel"
      :buttonClasses="['panel-handle', 'draggable-handle']"
      :iconName="'iconDrag'">
    </BasicButton>
    <BasicButton
      v-if="handleIcon"
      :buttonClasses="['panel-handle', 'click-handle']"
      :clickAction="togglePanel"
      :clickParams="'.panel-wrapper.' + panelDirection"
      :iconName="handleIcon">
    </BasicButton>
    <div
      :class="[
        'panel-wrapper',
        {
          opened: panelOpened
        }
      ]">
        <slot></slot>
      </div>
  </div>
</template>
<script>
import panelsEvents from "./mixins/panelsEvents";
import { iconsMixin } from "../../mixins/iconsMixin";
import BasicButton from "../Buttons/BasicButton";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BasicButton
  },
  computed: {
    ...mapGetters([
      "getFeaturedContent",
      "getHierarchyData",
      "getPanelsInfo",
      "getWindowInfo"
    ])
  },
  data() {
    return {
      bookmarksShown: false,
      displayDragButton: false,
      handleIcon: undefined,
      panelWidth: 0,
      panelOpened: false,
    }
  },
  props: ["closeIcon", "draggablePanel", "openIcon", "openedWidth", "panelDirection", "panelClasses"],
  mixins: [panelsEvents, iconsMixin],
  name: "basic-panel",
  mounted() {
    this.componentInit();
  },
  watch: {
    getFeaturedContent(featuredContent) {
      const currentPanelWidth = this.getPanelsInfo[this.panelDirection].width;
      const titleWidthVal = this.getWidestTitle(featuredContent[0].featured) * 8;
      const newWidthVal = titleWidthVal < window.innerWidth / 5 ?
        window.innerWidth / 5 : titleWidthVal;

      if(this.panelDirection === "left" && currentPanelWidth === 0) {
        this.setPanelWidth({
          panelInstance: this.panelDirection,
          width: newWidthVal
        });
      }

    },
    getHierarchyData() {
      const panelSelection = ".basic-panel." + this.panelDirection + " .panel-wrapper";
      this.resetScrollTop(panelSelection);
    },
    getPanelsInfo(panelsInfo) {
      this.panelWidth = panelsInfo[this.panelDirection].width;
      this.displayDragButton = this.panelWidth > 0 && this.draggablePanel;
      this.updatePanelWidth(".basic-panel." + this.panelDirection, this.panelWidth);
      this.toggleHandleIcon();
    },
    getWindowInfo() {
      const widthValue = this.panelDirection === "right" ? window.innerWidth * 0.5 : this.panelWidth
      const newWindowSize = this.panelWidth > 0 ? widthValue : 0;

      this.setPanelWidth({
        panelInstance: this.panelDirection,
        width: newWindowSize
      });
    }
  },
  methods: {
    ...mapActions([
      "centerMap",
      "fetchFeaturedSearchContent",
      "setPanelWidth"
    ]),
    adjustLayout(bookmarkData) {
      this.bookmarksShown = bookmarkData ? true : false;
    },
    componentInit() {
      this.toggleHandleIcon();
    },
    getWidestTitle(featuredList) {
      return featuredList.reduce((a, b) => {
        return a > b.title.length ? a : b.title.length;
      });
    },
    toggleHandleIcon() {
      this.handleIcon = this.panelWidth > 0 ? this.closeIcon : this.openIcon;
    },
    togglePanel() {
      const currentPanelWidth = this.getPanelsInfo[this.panelDirection].width;

      if(this.panelDirection === "right") {
        this.setPanelWidth({
          panelInstance: this.panelDirection,
          width: currentPanelWidth > 0 ? 0 : window.innerWidth * 0.5
        });
      }

      if(this.panelDirection === "left") {
        currentPanelWidth === 0 ? this.fetchFeaturedSearchContent() :  this.setPanelWidth({
          panelInstance: this.panelDirection,
          width: 0
        });
      }
    }
  }
}
</script>
<style lang="scss">
@import "../../stylesheets/panels.module.scss";
</style>
