import * as d3 from "d3";

export default {
  methods: {
    getSelectionProps(selection) {
      return selection.node().getBoundingClientRect();
    },
    scrollLeftToEnd(selection) {
      const scrollTimeout = setTimeout(() => {
        const container = d3.select(selection);

        if(!container.empty() && window.innerWidth > 767) {
          container._groups[0][0].scrollLeft = container._groups[0][0].scrollWidth - this.getSelectionProps(container).width;
        } else {
          this.resetScroll(selection);
        }

        clearTimeout(scrollTimeout);
      }, 300);
    },
    resetScroll(selection) {
      const scrollTimeout = setTimeout(() => {
        const container = d3.select(selection);

        if(!container.empty()) {
          container._groups[0][0].scrollLeft = 0;
          container._groups[0][0].scrollTop = 0;
        }

        clearTimeout(scrollTimeout);
      });
    }
  }
}