import * as d3 from "d3";

export default {
  methods: {
    positionChildren() {
      const mainThoughtElem = d3.select(".main-thought");
      const mainThoughtElemProps = this.getSelectionProps(mainThoughtElem);
      const relatedSiblings = d3.selectAll(".thought-related-siblings " + this.thoughtNodeSelection);
      const childrenNodes = d3.selectAll(".thought-children " + this.thoughtNodeSelection);
      const childrenWrapper = d3.selectAll(".thought-children .thoughts-wrapper");
      const parentNodes = d3.selectAll(".thought-parents " + this.thoughtNodeSelection);
      const hasSiblings = parentNodes.data().filter(itm => itm.data.relations && itm.data.relations.length > 0).length > 0;

      if(!childrenNodes.empty()) {
        const widestChildProps = this.getSelectionProps(this.getWidestThought(childrenNodes));
        const maxChildrenColumns = Math.floor(window.innerWidth / widestChildProps.width);
        const sidePanelInstance = d3.select(".basic-panel.right .panel-wrapper");
        const sidePanelGap = sidePanelInstance.empty() ? 0 : this.getSelectionProps(sidePanelInstance).width;

        let childrenCalculatedColumns = childrenNodes.size() <= maxChildrenColumns && childrenNodes.size()
            ? childrenNodes.size()
            : maxChildrenColumns;

        if(childrenNodes.size() > 2) {
          childrenCalculatedColumns = relatedSiblings.size() > 0
            ? childrenCalculatedColumns - 1
            : childrenCalculatedColumns;

          childrenCalculatedColumns = hasSiblings && childrenCalculatedColumns > 1
            ? childrenCalculatedColumns - 1
            : childrenCalculatedColumns;
        }

        const childrenWrapperY = mainThoughtElemProps.y + 100;
        const childrenWrapperX = (window.innerWidth - (childrenCalculatedColumns * widestChildProps.width)) * 0.5 - sidePanelGap * 0.5;

        this.breakIntoColumns(
          childrenWrapper,
          childrenCalculatedColumns,
          widestChildProps,
          { x: childrenWrapperX, y: childrenWrapperY }
        );
      }
    }
  }
}