export const getDeviceOrientation = state => {
  return state.deviceOrientation;
};
export const getHierarchyData = state => {
  return state.hierarchyData;
};
export const getMapLoaded = state => {
  return state.mapLoaded;
};
export const getSiblingsData = state => {
  return state.siblingsData;
};
export const getThoughtsRawData = state => {
  return state.thoughtsRawData;
};
export const getWindowInfo = state => {
  return state.windowInfo;
};
export const getZoomEvent = state => {
  return state.zoomEvent;
};
export const getZoomInfo = state => {
  return state.zoomInfo;
};

