var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.iconPath
    ? _c(
        "svg",
        {
          class: ["basic-icon", _vm.iconClassesData],
          attrs: { viewBox: _vm.iconViewBox }
        },
        [_c("path", { attrs: { d: _vm.iconPath } })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }