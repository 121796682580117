// import { forEach, forOwn } from "lodash";
import { fireStoreDatabase } from "../../../configs/firebase/databaseConfig";
import "firebase/firestore";
// import * as d3 from "d3";

export const fetchTourInfo = ({commit}) => {
  const userPreferences = fireStoreDatabase.firestore().collection("app");
  return userPreferences.doc(process.env.VUE_APP_CHANNEL_PREFIX)
    .get().then(queryDoc => {
        const tourInfo = queryDoc.data()[process.env.VUE_APP_CHANNEL_SETTINGS_PATH].tour;

        commit("mutate", {
          property: "tourInfo",
          value: tourInfo
        });
    });
}
export const setCurrentStep = ({commit}, payload) => {
  commit("mutate", {
    property: "currentTourStep",
    value: payload
  });
}
export const setModalInstance = ({commit}, payload) => {
  commit("mutate", {
    property: "tourInstance",
    value: payload
  });
}
export const setTourSeen = ({commit}, payload) => {
  commit("mutate", {
    property: "tourSeen",
    value: payload
  });
}
export const setTourShown = ({commit}, payload) => {
  commit("mutate", {
    property: "tourShown",
    value: payload
  });
}
