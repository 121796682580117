import * as getters from "./tourGetters";
import * as mutations from "./tourMutations";
import * as actions from "./tourActions";

export default {
  state: {
    currentTourStep: 0,
    currentTourSettings: undefined,
    UIresets: [],
    UItransformations: [],
    mobileUItransformations: [],
    tourSeen: false,
    tourShown: false,
    tourInfo: {},
    tourInstance: {}
  },
  getters,
  mutations,
  actions,
}
