import * as getters from "./thoughtsGetters";
import * as mutations from "./thoughtsMutations";
import * as thoughtsActions from "./thoughtsActions";
import * as zoomActions from "./zoomActions";

export default {
  state: {
    deviceOrientation: {},
    hierarchyData: {},
    mapLoaded: false,
    thoughtsRawData: [],
    thoughtsRendered: false,
    siblingsData: [],
    windowInfo: {},
    zoomEvent: {},
    zoomInfo: {
      x: 0,
      y: 0,
      z: 1
    }
  },
  getters,
  mutations,
  actions: {
    ...thoughtsActions,
    ...zoomActions
  }
}
