<script>
import { forEach } from "lodash";
import * as d3 from "d3";
import * as generalConfig from "../../configs/generalConfig";
import { d3Operations } from "../../mixins/d3Operations";
import childrenMixins from "./mixins/childrenMixins";
import parentsMixins from "./mixins/parentsMixins";
import siblingsMixins from "./mixins/siblingsMixins";
import relatedSiblingsMixins from "./mixins/relatedSiblingsMixins";

import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "getHierarchyData",
      "getSiblingsData",
      "getZoomInfo"
    ])
  },
  data() {
    return {
      cellModel: {},
      columnCount: 0,
      thoughtsData: [],
    }
  },
  mixins: [d3Operations, childrenMixins, parentsMixins, relatedSiblingsMixins, siblingsMixins],
  name: "ThoughtsHierarchyTree",
  render() {
    return {};
  },
  mounted() {
    this.componentInit();
  },
  watch: {
    getHierarchyData() {
      this.renderTree();
    },
    getSiblingsData(siblingsData) {
      this.positionSiblings(siblingsData);
    }
  },
  methods: {
    componentInit() {
      this.thoughtNodeSelection = "[class^=\"thought-node-\"]";
    },
    breakIntoColumns(thoughtsWrapper, colCount, cellModel, elementsOffset) {
      let colSum = 0;
      let rowSum = 0;
      const offsetX = elementsOffset && elementsOffset.x ? elementsOffset.x : 0;
      const offsetY = elementsOffset && elementsOffset.y ? elementsOffset.y : 0;

      const methodResult = new Promise((resolve) => {
        thoughtsWrapper.selectAll(this.thoughtNodeSelection)
          .transition()
          .duration(generalConfig.thoughtsExpansionTime)
          .ease(d3.easeQuadInOut)
          .attr("transform", () =>  {
            const nodeX = (colSum * (cellModel.width + 20)) + offsetX;
            const nodeY = (rowSum * 55 + 20) + offsetY;

            if(colSum === (colCount - 1)) {
              colSum = 0;
              rowSum += 1;
            } else {
              colSum += 1;
            }

            return "translate(" + nodeX + "," + nodeY + ")";
          })
          .style("opacity", 1)
          .on("end", () => {
            this.resolveRenders(resolve);
          });
      });

      return methodResult;
    },
    breakIntoRows(thoughtsWrapper, rowCount, cellModel, elementsOffset) {
      let colSum = 0;
      let rowSum = 0;

      const offsetX = elementsOffset && elementsOffset.x ? elementsOffset.x : 0;
      const offsetY = elementsOffset && elementsOffset.y ? elementsOffset.y : 0;

      const methodResult = new Promise((resolve) => {
        thoughtsWrapper.selectAll(this.thoughtNodeSelection)
          .transition()
          .duration(generalConfig.thoughtsExpansionTime)
          .ease(d3.easeQuadInOut)
          .attr("transform", () =>  {
            const nodeX = (colSum * (cellModel.width + 20)) + offsetX;
            const nodeY = (rowSum * 55) + offsetY;

            if(rowSum === (rowCount - 1)) {
              rowSum = 0;
              colSum += 1;
            } else {
              rowSum += 1;
            }

            return "translate(" + nodeX + "," + nodeY + ")";
          })
          .style("opacity", 1)
          .on("end", () => {
            this.resolveRenders(resolve);
          });
      });

      return methodResult;
    },
    getWidestThought(nodeGroup) {
      let widestThoughtElement;
      let widestWidth = 0;

      forEach(nodeGroup._groups[0], elem => {
        if(this.getSelectionProperties(elem).width > widestWidth) {
          widestThoughtElement = elem;
          widestWidth = this.getSelectionProperties(elem).width;
        }
      })

      return d3.select(widestThoughtElement);
    },
    renderTree() {
      this.positionChildren();
      this.positionParents();
      this.positionRelatedSiblings();
    },
    resolveRenders(resolve) {
      const delayRender = setTimeout(() => {
        clearTimeout(delayRender);
        resolve();
      });
    }
  }
}
</script>

<style>
</style>
