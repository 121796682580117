import * as getters from "./localSettingsGetters";
import * as mutations from "./localSettingsMutations";
import * as actions from "./localSettingsActions";

export default {
  state: {
    tourStatus: {},
    panelsStatus: {}
  },
  getters,
  mutations,
  actions,
}
