<template>
  <div class="thoughts-bookmarks">
    <div class="bookmarks-wrapper" v-if="bookmarksShown">
      <BookmarksIndicator :sectionData="selectedSection"></BookmarksIndicator>
      <BookmarksBar
        :selectedSection="selectedSection"
        :contentData="bookmarksContent"
        :deleteItemEvent="confirmDeletePin"></BookmarksBar>

      <div class="bookmark-actions">
        <LabeledButton
          :buttonLabel="selectedSection.title"
          :buttonClasses="['type-a', 'show-icon']"
          :clickAction="selectedSection.actionClick"
          :iconName="selectedSection.actionIcon"></LabeledButton>
      </div>
    </div>
  </div>
</template>

<script>
import { forEach } from "lodash";
import * as generalConfig from "../../configs/generalConfig";
import LabeledButton from "../Buttons/LabeledButton";
import BookmarksIndicator from "./BookmarksIndicator";
import BookmarksBar from "./BookmarksBar";
import bookmarksMixins from "./mixins/bookmarksMixins";
import bookmarksEvents from "./mixins/bookmarksEvents";
import { iconsMixin } from "../../mixins/iconsMixin";
import { thoughtFilters } from "../../mixins/thoughtOperations";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BookmarksIndicator,
    BookmarksBar,
    LabeledButton,
  },
  computed: {
    ...mapGetters([
      "getBookmarksShown",
      "getCurrentBookmarksSection",
      "getCurrentUserPreferencesSnapshot",
      "getCurrentUserPreferencesSubscription",
      "getHierarchyData",
      "getMainSettingsInfo",
      "getWindowInfo"
    ])
  },
  data () {
    return {
      sectionsData: {
        pinned: {
          id: 0,
          actionIcon: 'iconPlus',
          actionClick: this.addPinnedItem,
          icon: "iconPinned",
          label: "pinned",
          selected: false,
          title: generalConfig.addPinnedButtonTitle,
          viewBox: "0 0 400 500"
        },
        history: {
          id: 1,
          actionIcon: 'iconBin',
          actionClick: this.confirmDiscardHistory,
          icon: "iconHistory",
          label: "history",
          selected: false,
          title: generalConfig.deleteHistoryButtonTitle,
          viewBox: "0 0 500 500"
        }
      },
      bookmarksShown: false,
      componentReady: false,
      currentSnapshot: {},
      selectedSection: {},
      bookmarksContent: [],
      userPreferencesChannel: process.env.VUE_APP_USER_PREFERENCES_CHANNEL
    }
  },
  mixins: [bookmarksEvents, bookmarksMixins, iconsMixin, thoughtFilters],
  name: "thought-bookmarks",
  watch: {
    getHierarchyData() {
      this.addLatestThoughtTo("history");
    },
    getBookmarksShown(bookmarksShown) {
      this.bookmarksShown = bookmarksShown;
      if(!bookmarksShown) {
        forEach(this.sectionsData, sectionData => { sectionData.selected = false });
      } else {
        this.scrollToEnd();
      }
    },
    getCurrentBookmarksSection(currentBookmarksSection) {
      this.selectedSection = this.sectionsData[currentBookmarksSection];
      this.toggleBookmarks();
    },
    getCurrentUserPreferencesSnapshot(snapShot) {
      const selectedSectionData = snapShot.data()["user_" + this.selectedSection.label];

      if(selectedSectionData) {
        this.renderBookmarksContent(selectedSectionData[this.userPreferencesChannel]);
      }
    },
    getCurrentUserPreferencesSubscription() {
      this.componentInit();
    },
    getMainSettingsInfo() {
      const selectedSectionData = this.getCurrentUserPreferencesSnapshot.data()["user_" + this.selectedSection.label];

      if(selectedSectionData){
        this.renderBookmarksContent(selectedSectionData[this.userPreferencesChannel]);
      }
    },
    getWindowInfo() {
      this.setBookmarksShown(false);
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      "createMarketPlaceSection",
      "fetchMainSettings",
      "setCurrentMobilePanel",
      "setMobileSearchShown",
      "setModalShown",
      "setModalInstance",
      "setBookmarksShown",
      "writeSection"
    ]),
    componentInit() {
      this.checkBookmarksSections();
      this.fetchMainSettings();
    },
    checkBookmarksSections(){
      forEach(this.sectionsData, buttonData => {
        this.createBookmarksSection(buttonData.label)
      });
    },
    createBookmarksSection(section) {
      const fbSection = this.getCurrentUserPreferencesSnapshot.data()["user_" + section];
      const fbCollection = fbSection ? fbSection[this.userPreferencesChannel] : undefined;

      if(!fbSection || fbSection.length === 0) {
        let payload = {
          user: this.$auth.user
        }
        let dataObject = {};

        dataObject[`user_${section}`] = {};
        dataObject[`user_${section}`][this.userPreferencesChannel] = [];

        payload.data = dataObject;

        return this.createMarketPlaceSection(payload);
      }

      if (!fbCollection){
        let payload = {
          user: this.$auth.user
        }
        let dataObject = {...this.getCurrentUserPreferencesSnapshot.data()};
        dataObject[`user_${section}`][this.userPreferencesChannel] = [];

        payload.data = dataObject;

        return this.createMarketPlaceSection(payload);
      }
    },
    processHistoryItem(selectedThought){
      const selectedThoughtCopy = {...selectedThought.data};

      if(selectedThought.data.thought_id !== "") {
        delete selectedThoughtCopy.gates;
        delete selectedThoughtCopy.kind;
        delete selectedThoughtCopy.notes;0
        delete selectedThoughtCopy.relations;
      }
      return selectedThoughtCopy;
    },
    spliceHistoryItems(historyData) {
      const historyMaxItems = Number(this.getMainSettingsInfo.number_history_thoughts);
      const historyDataClone = [...historyData];

      if(historyData.length >  historyMaxItems) {
        const transformedData = historyDataClone.reverse().slice(0, historyMaxItems);
        const updateObject = {};
        updateObject.user_history = {};
        updateObject.user_history[this.userPreferencesChannel] = transformedData.reverse();

        this.writeSection({
          user: this.$auth.user,
          data: updateObject
        });

        return window.innerWidth > 767
          ? transformedData
          : transformedData.reverse();
      }

      return window.innerWidth > 767
          ? historyDataClone
          : historyDataClone.reverse();
    },
    scrollToEnd() {
      if(this.selectedSection.label === "history") {
        this.scrollLeftToEnd(".thoughts-bookmarks .ps-container");
      } else {
        this.resetScroll(".thoughts-bookmarks .ps-container");
      }
    },
    toggleBookmarks() {
      this.setBookmarksShown(true);
      this.setMobileSearchShown(false);
      this.setCurrentMobilePanel(this.selectedSection.label);
      this.renderBookmarksContent(this.getCurrentUserPreferencesSnapshot.data()["user_" + this.selectedSection.label][this.userPreferencesChannel]);
    },
    renderBookmarksContent(currentPreferences) {
      const sectionData = currentPreferences || [];

      if(this.selectedSection.label == "history") {
        this.bookmarksContent = this.spliceHistoryItems(sectionData);
        this.scrollToEnd();
      }

      if(this.selectedSection.label == "pinned") {
        this.bookmarksContent = this.sortBookmarks(sectionData);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../stylesheets/bookmarks.module.scss";
</style>
