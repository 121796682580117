import * as d3 from "d3";

export default {
  methods: {
    positionRelatedSiblings() {
      const mainThoughtElem = d3.select(".main-thought");
      const mainThoughtElemProps = this.getSelectionProps(mainThoughtElem);
      const relatedSiblingNodes = d3.selectAll(".thought-related-siblings " + this.thoughtNodeSelection);
      const childrenWrapper = d3.selectAll(".thought-children .thoughts-wrapper");
      const childrenWrapperProps = this.getSelectionProps(childrenWrapper);
      const relatedSiblingsWrapper = d3.selectAll(".thought-related-siblings .thoughts-wrapper");
      const childrenGap = this.getHierarchyData.thoughtChildren.length > 0
        ? childrenWrapperProps.x + 20 : mainThoughtElemProps.x + 20;

      if(!relatedSiblingNodes.empty()) {
        const widestRelatedSiblingProps = this.getSelectionProps(this.getWidestThought(relatedSiblingNodes));

        const rowScreenCount = Math.floor((window.innerHeight - 62) * 0.5 / 43) + 1;
        const calculatedRelSiblingsW = Math.ceil(relatedSiblingNodes.size()/rowScreenCount) * widestRelatedSiblingProps.width;

        const relatedSiblingsWrapperX = childrenGap - calculatedRelSiblingsW - 100;
        const relatedSiblingsWrapperY = mainThoughtElemProps.y - mainThoughtElemProps.height + 5;

        this.breakIntoRows(
          relatedSiblingsWrapper,
          rowScreenCount,
          widestRelatedSiblingProps,
          {
            x: relatedSiblingsWrapperX,
            y: relatedSiblingsWrapperY
          }
        );
      }
    }
  }
}