export const iOSzoomEvents = {
  methods: {
    preventElementTouchZoom(sourceElement) {
      sourceElement.addEventListener("gesturestart", event => {
        event.preventDefault();
        event.stopPropagation();
      });

      sourceElement.addEventListener("gesturechange", event => {
        event.preventDefault();
        event.stopPropagation();
      });

      sourceElement.addEventListener("gestureend", event => {
        event.preventDefault();
        event.stopPropagation();
      });
    },
    preventTouchZoom() {
      window.addEventListener("gesturestart", event => {
        event.preventDefault();
        event.stopPropagation();
      });

      window.addEventListener("gesturechange", event => {
        event.preventDefault();
        event.stopPropagation();
      });

      window.addEventListener("gestureend", event => {
        event.preventDefault();
        event.stopPropagation();
      });
    }
  }
}
