<template>
  <div class="featured-search">
    <div class="search-box">
      <form
        @submit.prevent="filterSearch()"
        action="">
        <input 
          :placeholder="searchPlaceHolder"
          ref="featuredSearchInput"
          name="search"
          type="search"
          aria-autocomplete="both" aria-haspopup="false" autocapitalize="off" autocomplete="off" autocorrect="off"
          v-model="searchInputModel"
          @change="filterSearch"/>
      </form>
      <BasicButton
        v-if="searchInputModel !== ''"
        :clickAction="resetSearch"
        :buttonClasses="['search-companies-close-button']"
        :iconName="'iconClose'"></BasicButton>
    </div>
    <div :class="[
      'search-list',
      {
        'bookmarks-shown': bookmarksShown
      }
    ]">
      <div class="search-item"
        v-for="thought in filteredThoughts"
        @click="goToThought(thought.thought_id)"
        :key="thought.thought_id">{{ thought.title }}</div>
    </div>
  </div>
</template>

<script>
import panelsEvents from "../Panel/mixins/panelsEvents";
import { d3Operations } from "../../mixins/d3Operations";
import BasicButton from "../Buttons/BasicButton";
import { filter } from "lodash";
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      "getMainSettingsInfo",
      "getMobilePanelsInfo",
      "getHierarchyData",
      "getFeaturedContent",
      "getWindowInfo"
    ])
  },
  data() {
    return {
      bookmarksShown: false,
      featuredThoughts: [],
      filteredThoughts: [],
      searchInputModel: "",
      searchPlaceHolder: "",
    }
  },
  components: {
    BasicButton,
  },
  mixins: [d3Operations, panelsEvents],
  mounted() {
    this.componentInit();
  },
  watch: {
    getFeaturedContent(featuredContent) {
      this.clearSearchValues();
      this.panelOpened(featuredContent);
    },
    getHierarchyData() {
      this.hideSearchPanel();
    },
    getMobilePanelsInfo(panelInfo) {
      if(panelInfo.left.height > 0) {
        const panelInstanceInfo = {
          panelInstance: "left"
        };
        this.fetchFeaturedSearchContent();
      } else {
        this.panelClosed();
      }
    },
    getWindowInfo() {
      // this.hideSearchPanel();
    },
    searchInputModel() {
      this.filterSearch();
    }
  },
  methods: {
    ...mapActions([
      "fetchFeaturedSearchContent",
      "setCurrentMobilePanel",
      "setMobilePanelHeight",
      "setPanelWidth",
      "setMapLoaded"
    ]),
    adjustLayout(bookmarkData) {
      this.bookmarksShown = bookmarkData ? true : false;
    },
    componentInit() {
    },
    clearSearchValues() {
      this.searchInputModel = "";
      this.featuredThoughts = [];
      this.filteredThoughts = [];
    },
    filterSearch() {
      const filtered = filter(this.featuredThoughts, itm => {
        const allUp = itm.title.toUpperCase();

        return allUp.search(this.searchInputModel.toUpperCase()) > -1;
      });

      this.filteredThoughts = filtered;
    },
    goToThought(thoughtId) {
      this.setMapLoaded(false);
      this.hideSearchPanel();

      this.$router.push({ name: "thought", params: { id: thoughtId } })
        .then(() => {
        })
        .catch(() => {
          this.setMapLoaded(true);
        });
    },
    hideSearchPanel() {
      this.setPanelWidth({
        panelInstance: "left",
        width: 0
      });

      this.setMobilePanelHeight({
        panelInstance: "left",
        height: 0
      });

      this.updatePanelHeight(".basic-mobile-panel.left", 0, 0);
    },
    panelClosed() {
      this.clearSearchValues();
      this.featuredThoughts = [];
      this.filteredThoughts = [];
    },
    panelOpened(featuredContent) {
      this.featuredThoughts = featuredContent[0].featured;
      this.filteredThoughts = featuredContent[0].featured;
      this.searchPlaceHolder = this.getMainSettingsInfo.view_all_search_label || featuredContent[0].search_label;
    },
    resetSearch() {
      this.clearSearchValues();
      this.panelOpened(this.getFeaturedContent);
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../stylesheets/featured-content.module.scss";
</style>