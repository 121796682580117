import Vue from "vue";
import VueRouter from "vue-router";
import VueResource from "vue-resource";
import App from "./App.vue";
import Vuex from 'vuex'
import TimelinePlugin from "../node_modules/@fgi/timeline/dist/fenix-timeline-plugin/FenixTimelinePlugin.common"
import { store } from "./store/store";

import { routes } from "./routes";
import { firestorePlugin } from "vuefire";

import { domain, clientId } from "../auth_config.json";
import { Auth0Plugin } from "./auth";
//Vue analytics
import VueAnalytics from 'vue-analytics'

Vue.use(Vuex);

Vue.use(VueResource);
Vue.use(VueRouter);
Vue.use(firestorePlugin);

Vue.use(TimelinePlugin, store);

const router = new VueRouter({
  routes,
  // mode: "history"
});

Vue.use(Auth0Plugin, {
  domain,
  clientId
});

Vue.config.productionTip = false;

if(process.env.VUE_APP_GAID) {
  console.log('ga installed')
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GAID,
    router
  })
}

new Vue({
  store,
  render: h => h(App),
  router
}).$mount("#app")
