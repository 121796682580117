<template>
  <div :class="[
    'basic-mobile-panel',
    panelDirection,
    panelClasses,
    {
      'bookmarks-shown': bookmarksShown,
    }
  ]">
    <div
      :class="[
        'panel-wrapper',
        {
          opened: panelOpened
        }
      ]">
        <slot></slot>
    </div>
    <BasicButton
      :buttonClasses="['close-button']"
      :clickAction="togglePanel"
      :iconName="closeIcon">
    </BasicButton>
    <LabeledButton
      v-if="tourShown || displayHandle && !getMobileSearchShown"
      :buttonClasses="['panel-handle', 'mobile-panel-button', panelClasses]"
      :buttonLabel= "panelInstance"
      :clickAction="togglePanel"
      :clickParams="'.panel-wrapper.' + panelDirection"
      :iconName="handleIcon">
    </LabeledButton>
  </div>
</template>
<script>
import { filter } from "lodash";
// import { isMobile } from 'mobile-device-detect';
import panelsEvents from "./mixins/panelsEvents";
import BasicButton from "../Buttons/BasicButton";
import LabeledButton from "../Buttons/LabeledButton";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BasicButton,
    LabeledButton
  },
  computed: {
    ...mapGetters([
      "getHierarchyData",
      "getCurrentMobilePanel",
      "getMobileMenuShown",
      "getMobilePanelsInfo",
      "getMobileSearchShown",
      "getWindowInfo",
      "getTourShown"
    ])
  },
  data() {
    return {
      bookmarksShown: false,
      closeIcon: "iconCloseThick",
      displayHandle: false,
      panelHeight: 0,
      panelOpened: false,
      tourShown: false
    }
  },
  props: ["draggablePanel", "handleIcon", "panelDirection", "panelClasses", "panelInstance"],
  mixins: [panelsEvents],
  name: "basic-mobile-panel",
  mounted() {
    this.componentInit();
  },
  watch: {
    getHierarchyData() {
      const panelSelection = ".basic-panel." + this.panelClasses + " .panel-wrapper";
      this.resetScrollTop(panelSelection);
    },
    getMobilePanelsInfo(panelsInfo) {
      this.setPanelsState(panelsInfo);
      const panelsInfoCopy = { ...panelsInfo };
      delete panelsInfoCopy.currentPanel;
      const panelsClosed = filter(panelsInfoCopy, panel => panel.height === 0).length === 2;

      this.displayHandle = panelsClosed;
    },
    getWindowInfo() {
      const currentPanelHeight = this.getMobilePanelsInfo[this.panelClasses[0]].height;
      if(currentPanelHeight > 0) {
        this.updatePanelHeight(".basic-mobile-panel." + this.panelClasses, window.visualViewport.height, 0);
      }

      this.setPanelsState(this.getMobilePanelsInfo);
    },
    getCurrentMobilePanel(currentPanel) {
      if(
        !currentPanel ||
        currentPanel === "search" ||
        currentPanel === "pinned" ||
        currentPanel === "history"
      ) {
        if(this.getMobileMenuShown && this.tourShown) {
          this.closeMobileMenu();
        }
      }
    },
    getTourShown(tourShown) {
      this.tourShown = tourShown;
    }
  },
  methods: {
    ...mapActions([
      "centerMap",
      "closeMobileMenu",
      "setCurrentMobilePanel",
      "setMobilePanelHeight"
    ]),
    adjustLayout(bookmarkData) {
      this.bookmarksShown = bookmarkData ? true : false;
    },
    componentInit() {
    },
    setPanelsState(panelsInfo) {
      const panelsInfoCopy = { ...panelsInfo };
      delete panelsInfoCopy.currentPanel;
      const panelsClosed = filter(panelsInfoCopy, panel => panel.height === 0).length === 2;

      this.displayHandle = panelsClosed;
    },
    togglePanel() {
      const currentPanelHeight = this.getMobilePanelsInfo[this.panelClasses[0]].height;

      this.setCurrentMobilePanel(
        currentPanelHeight > 0 ? undefined : this.panelInstance
      );

      this.setMobilePanelHeight({
        panelInstance: this.panelClasses[0],
        height: currentPanelHeight > 0 ? 0 : window.innerHeight
      });

      this.updatePanelHeight(".basic-mobile-panel." + this.panelClasses, currentPanelHeight > 0 ? 0 : window.innerHeight);
    }
  }
}
</script>
<style lang="scss">
@import "../../stylesheets/mobile-panels.module.scss";
</style>
