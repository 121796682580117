var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return false
    ? _c(
        "div",
        { class: ["timeline-search"] },
        [
          _vm.searchKeyword
            ? _c("BasicButton", {
                style: {},
                attrs: {
                  clickAction: _vm.closeSearch,
                  buttonClasses: ["close-search"],
                  iconName: "iconClose"
                }
              })
            : _vm._e(),
          !_vm.searchKeyword
            ? _c("BasicIcon", {
                attrs: { iconClasses: ["search-icon"], iconName: "iconSearch" }
              })
            : _vm._e(),
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitTimelineSearchKeyboard(_vm.searchKeyword)
                }
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchKeyword,
                    expression: "searchKeyword"
                  }
                ],
                ref: "timelineSearchInput",
                class: {},
                attrs: {
                  name: "search",
                  type: "search",
                  "aria-autocomplete": "both",
                  "aria-haspopup": "false",
                  autocapitalize: "off",
                  autocomplete: "off",
                  autocorrect: "off",
                  placeholder: "SEARCH TIMELINE"
                },
                domProps: { value: _vm.searchKeyword },
                on: {
                  focus: function($event) {
                    return _vm.setTimelineSearchInputFocus(true)
                  },
                  blur: function($event) {
                    return _vm.setTimelineSearchInputFocus(false)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchKeyword = $event.target.value
                  }
                }
              })
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }