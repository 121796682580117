<template>
  <g id="thoughtsMutation" class="thoughts-placeholder"></g>
</template>

<script>
import { d3Operations } from "../../mixins/d3Operations";
import { thoughtFilters } from "../../mixins/thoughtOperations";
import deviceDetection from "../../mixins/deviceDetection";
import thoughtsEvents from "./mixins/thoughtsEvents"
import thoughtsOptions from "./mixins/thoughtsOptions"
import thoughtLinks from "./mixins/thoughtLinks"

import * as d3 from "d3";
import { filter, forEach } from "lodash";

import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "getHierarchyData",
      "getSiblingsData",
      "getZoomEvent",
      "getZoomInfo",
    ])
  },
  data() {
    return {
      thoughtsData: [],
      thoughtLinkSelection: "",
      thoughtNodeSelection: "",
    }
  },
  mixins: [d3Operations, deviceDetection, thoughtFilters, thoughtsEvents, thoughtLinks, thoughtsOptions],
  name: "thoughts",
  mounted() {
    this.componentInit();

    // Observe tree changes and render link lines as they happen
    const targetNode = document.getElementById('thoughtsMutation');
    const observerConfig = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(() => {
      this.debounceLinkRendering();
    });
    observer.observe(targetNode, observerConfig);
  },
  watch: {
    getHierarchyData(hierarchyData) {
      d3.selectAll(this.thoughtNodeSelection).interrupt("clickedBlink");
      d3.selectAll(".thought-siblings " + this.thoughtNodeSelection).remove();
      d3.select(".thought-links-placeholder").selectAll(this.thoughtLinkSelection).remove();
      d3.select("[class^=\"thought-siblings-\"]").remove();

      this.render(hierarchyData);
    },
    getSiblingsData() {
      this.setThoughtsActions();
      this.renderSiblingsOptions();
    }
  },
  methods: {
    ...mapActions([
      "setMapLoaded",
      "setSiblingsData",
      "siftHierarchyData",
      "resetZoom",
      "stopZoom"
    ]),
    componentInit() {
      this.thoughtLinkSelection = "[class^=\"thought-link-\"]";
      this.thoughtNodeSelection = "[class^=\"thought-node-\"]";
      this.thoughtSiblingsSelection = "[class^=\"thought-siblings-\"]";
    },
    calculateSiblings(parentItm) {
      let renderedSiblings = [];

      if(parentItm.data.relations) {
        renderedSiblings = [...parentItm.data.relations]
      }

      const filteredSelectedThought =  filter(renderedSiblings, itm => itm.data.thought_id !== this.$route.params.id);
      const filteredSiblings = this.setUniqueRecords(this.sortThoughts(filteredSelectedThought));

      this.filterByCollection(this.thoughtsData.thoughtParents, filteredSiblings);
      this.filterByCollection(this.thoughtsData.thoughtRelatedSiblings, filteredSiblings);
      forEach(filteredSiblings, itm => itm.thoughtSibling = true);
      //This filter can be optimized, but has a partial fix according to a conversation with Andi
      const filterNoParents = filter(filteredSiblings, itm => itm.data.gates["has_parents"]);
      return filterNoParents;
    },
    debounceLinkRendering() {
      const debounceTimeout = setTimeout(() => {
        d3.selectAll(this.thoughtLinkSelection).remove();
        this.setLinkPaths(this.thoughtNodeSelection);
        clearTimeout(debounceTimeout);
      }, 125);
    },
    renderSiblings(parentData, filteredSiblings) {
      const selectedParent = d3.select(".thought-node-" + parentData.data.thought_id);
      const selectedParentProps = this.getSelectionProps(selectedParent);
      const selectedParentId = selectedParent.datum().data.thought_id;

      if(filteredSiblings.length > 0) {
        this.renderThoughtNodes(filteredSiblings, "thought-siblings thought-siblings-" + selectedParentId);

        d3.selectAll(".thought-siblings-" + selectedParentId + " " + this.thoughtNodeSelection)
          .attr("transform", "translate(" + selectedParentProps.x + "," + (selectedParentProps.y - 37) + ")");

        const siblingsData = {
          parentId: selectedParentId,
          filteredSiblings
        }

        this.setSiblingsData(siblingsData);
      } else {
        const siblingsData = {
          parentId: selectedParentId,
        }

        this.setSiblingsData(siblingsData);
      }
    },
    render(thoughtsData) {
      this.thoughtsData = thoughtsData;

      this.renderThoughtNodes(thoughtsData.thoughtParents, "thought-parents");
      this.renderThoughtNodes(thoughtsData.thoughtRelatedSiblings, "thought-related-siblings");
      this.renderThoughtNodes([thoughtsData.thoughtNodes[0]], "main-thought");
      this.renderThoughtNodes(thoughtsData.thoughtChildren, "thought-children");

      d3.selectAll(this.thoughtNodeSelection)
        .style("opacity", 0);
      d3.select(".main-thought")
        .selectAll(this.thoughtNodeSelection)
        .style("opacity", 1);

      this.renderOptions();
      this.setThoughtsActions();
      this.setMapLoaded(true);
      d3.select(".thought-links-placeholder").style("display", "block");
    },
    renderThoughtNodes(thoughtNodes, selector) {
      d3.select(".map-canvas .thoughts-group").select(".thoughts-placeholder ." + selector).remove();

      const thoughtsGroup = d3.select(".map-canvas .thoughts-group").select(".thoughts-placeholder")
        .append("g")
        .classed(selector, true);

      const thoughtsSvg = thoughtsGroup
        .append("g")
        .attr("class", "thoughts-wrapper")

      const thoughtsSelected = thoughtsSvg
        .selectAll(".thought-texts")
        .data(thoughtNodes)
        .enter()
        .append("g")
        .attr("class", d => "thought-node-" + d.data.thought_id)

      thoughtsSelected
        .append("g")
        .classed("thought-texts", true)
        .append("text")
        .classed("thought-label", true)
        .attr("x", itm => itm.data.icon ? 24 : 0)
        .attr("y", 1)
        .html(itm => itm.data.title);

      thoughtsSelected
        .append("g")
        .classed("thought-box", true)
        .append("rect")
        .attr("rx", () => 5)
        .attr("ry", () => 5)
        .attr("height", (itm, idx, elem) => {
          const thoughtNode = d3.select(d3.select(d3.select(elem[idx]).node().parentNode).node().parentNode);
          const nodeTextProps = this.getSelectionProps(thoughtNode.select(".thought-label"));
          return nodeTextProps.height + 13;
        })
        .attr("width", (itm, idx, elem) => {
          const thoughtNode = d3.select(d3.select(d3.select(elem[idx]).node().parentNode).node().parentNode);
          const nodeTextProps = this.getSelectionProps(thoughtNode.select(".thought-label"));
          const boxWidth = itm.data.icon ? nodeTextProps.width + 44 : nodeTextProps.width + 20;

          return boxWidth;
        })
        .attr("transform", (itm, idx, elem) => {
          const thoughtNode = d3.select(d3.select(d3.select(elem[idx]).node().parentNode).node().parentNode);
          const nodeTextProps = this.getSelectionProps(thoughtNode.select(".thought-label"));
          return "translate(-10," + (-(nodeTextProps.height * 0.75) - 6) + ")";
        });

      thoughtsSelected
        .classed("selected", itm => itm.selected ? true : false)
        .classed("restricted", itm => itm.restricted ? true : false);

      thoughtsSelected.append("g")
        .classed("thought-gates" , true);

      d3.selectAll(this.thoughtNodeSelection + " .thought-texts").raise();

      thoughtsSelected
        .append("g")
        .classed("thought-hotspot", true)
        .append("rect")
        .attr("class", "hotspot")
        .attr("height", (data, idx, elem) => {
          const thoughtNode = d3.select(d3.select(d3.select(elem[idx]).node().parentNode).node().parentNode);
          const nodeBoxProps = this.getSelectionProps(thoughtNode.select(".thought-box rect"));
          return nodeBoxProps.height + 10;
        })
        .attr("width", (data, idx, elem) => {
          const thoughtNode = d3.select(d3.select(d3.select(elem[idx]).node().parentNode).node().parentNode);
          const nodeBoxProps = this.getSelectionProps(thoughtNode.select(".thought-box rect"));
          return nodeBoxProps.width + 10;
        })
        .attr("x", -15)
        .attr("y", -25)
        .style("opacity", 0);

      d3.selectAll(this.thoughtNodeSelection + " .thought-hotspot").raise();

      const sidePanelInstance = d3.select(".basic-panel.right .panel-wrapper");
      const sidePanelGap = sidePanelInstance.empty() ? 0 : sidePanelInstance.node().getBoundingClientRect().width;

      thoughtsSelected.attr("transform", (itm, idx, elem) => {
        const nodeSelection = d3.select(elem[idx]);
        const nodeSelectionProps = this.getSelectionProps(nodeSelection);
        const nodeX = (window.innerWidth - nodeSelectionProps.width - sidePanelGap) * 0.5;
        const nodeY = (window.innerHeight - nodeSelectionProps.height) * 0.5;

        return "transform", "translate(" + nodeX + "," + nodeY + ")";
      });
    }
  }
}
</script>

<style lang="scss">
@import "../../stylesheets/thoughts.module.scss";
</style>
