var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isTimelineRoute
    ? _c(
        "div",
        {
          class: [
            "mobile-panels-content",
            {
              "bookmarks-shown": _vm.bookmarksShown
            }
          ]
        },
        [
          _c(
            "BasicMobilePanel",
            {
              attrs: {
                handleIcon: _vm.leftPanelOpenIcon,
                panelDirection: "bottom",
                panelClasses: ["left"],
                panelInstance: "companies"
              }
            },
            [_c("FeaturedSearchContent")],
            1
          ),
          _c(
            "BasicMobilePanel",
            {
              attrs: {
                handleIcon: _vm.rightPanelOpenIcon,
                panelClasses: ["right"],
                panelDirection: "bottom",
                panelInstance: "details"
              }
            },
            [
              _vm.isMobile && !_vm.rotatedMobile
                ? _c("ThoughtDetailsContent")
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }