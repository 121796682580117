<template>
  <div v-if="showMapSearch"
    :class="['thoughts-search', {tourShown}]">
    <BasicButton
      v-if="displayResults && searchKeyword"
      :clickAction="closeMobileSearch"
      :buttonClasses="['close-search']"
      :iconName="'iconClose'"
      :style="{
        right: searchInputCloseRight
      }"></BasicButton>

    <form
      @submit.prevent="getThoughtsByKeyword($event.target.value)"
      action="">
      <input
        ref="mobileSearchInput"
        v-model="searchKeyword"
        :class="{
          hide: !showSearchInput && !mobileSearchMode
        }"
        :style="{
          right: searchInputRight
        }"
        name="search"
        type="search"
        aria-autocomplete="both" aria-haspopup="false" autocapitalize="off" autocomplete="off" autocorrect="off"
        placeholder="Search"/>
    </form>

    <div
      v-if="displayResults && searchKeyword"
      class="search-overlay"
      @click="closeSearch()"></div>

    <div v-if="displayResults && searchKeyword || mobileSearchMode" class="search-list">
      <div class="search-results-tab-group">
        <div class="tab-button"
          :class="{'selected': section.selected}"
          v-on:click="toggleSelectedSection(section)"
          v-for="section in searchSections"
          v-bind:key="section.id">
            <div class="tab-wrapper">
              <span class="tab-label">{{ section.label }}</span>
              <span class="tab-quantity">{{"(" + getSectionRecords(section) + ")"}}</span>
            </div>
        </div>
      </div>

      <div
        v-if="selectedSection.id === 0 && thoughtsByKeyword.length === 0 || selectedSection.id === 1 && relatedThoughts.length === 0"
        class="no-results">
        <span>{{"no results to display"}}</span>
      </div>

      <div v-if="selectedSection.id === 0" class="results-box">
        <ul>
          <li
            v-for="result in thoughtsByKeyword"
            v-bind:key="result.thought_id">
            <a @click="navigateToThought(result.thought_id)">{{result.title}}</a>
          </li>
        </ul>
      </div>
      <div v-if="selectedSection.id === 1" class="results-box">
        <ul>
          <li
            v-for="result in relatedThoughts"
            v-bind:key="result.thought_id">
            <a @click="navigateToThought(result.thought_id)">{{result.title}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { thoughtFilters } from "../../mixins/thoughtOperations";
import { isAndroid } from "mobile-device-detect";
import { iconsMixin } from "../../mixins/iconsMixin";
import BasicButton from "../../components/Buttons/BasicButton";
import BasicIcon from "../../components/BasicIcon";
import deviceDetection from "../../mixins/deviceDetection";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BasicButton,
    BasicIcon
  },
  computed: {
    ...mapGetters([
      "getCurrentTourStep",
      "getMainSettingsInfo",
      "getMobileSearchSelected",
      "getMobileSearchShown",
      "getSearchKeyword",
      "getSearchResult",
      "getTourShown",
      "getWindowInfo"
    ])
  },
  data() {
    return {
      displayResults: false,
      mobileSearchMode: false,
      relatedThoughts: [],
      searchInputRight: "0",
      searchInputCloseRight: "0",
      searchKeyword: undefined,
      searchSections: [],
      selectedSection: {id: 0, label: "Results by keyword", selected: true},
      showMapSearch: true,
      showSearchInput: false,
      thoughtsByKeyword: [],
      thoughtsSearchApi: process.env.VUE_APP_SEARCH_API,
      tourShown: false
    }
  },
  name: "ThoughtsSearch",
  mixins: [deviceDetection, iconsMixin, thoughtFilters],
  props: {
  },
  destroyed() {
  },
  mounted() {
    this.componentInit();
  },
  updated() {
  },
  watch: {
    "$route"() {
      this.toggleSearchInput();
    },
    getMobileSearchShown(showMobileSearch) {
      if(showMobileSearch) {
        this.showSearchInput = showMobileSearch;
        this.mobileSearchMode = showMobileSearch;

        setTimeout(() => {
          if(this.$refs.mobileSearchInput && window.innerWidth < 768 && !this.getTourShown) {
            this.$refs.mobileSearchInput.focus();
          }
        });
      }
      if(!showMobileSearch && !this.getMobileSearchSelected && this.mobileSearchMode) {
        this.closeMobileSearch();
      }
    },
    getSearchResult(result) {
      if(this.searchKeyword) {
        this.displayResults = true;
        const thoughtsByKeyword = result.data.filter(itm =>
          itm.title.toLowerCase().search(this.searchKeyword.toLowerCase()) !== -1);
        const relatedThoughts = result.data.filter(itm =>
          itm.title.toLowerCase().search(this.searchKeyword.toLowerCase()) === -1);

        this.thoughtsByKeyword = this.sortBookmarks(thoughtsByKeyword);
        this.relatedThoughts = this.sortBookmarks(relatedThoughts);
      }
    },
    getTourShown(tourShown) {
      this.tourShown = tourShown;
    },
    getWindowInfo() {
      const sTO = setTimeout(() => {
        this.setInputRightPosition();
        clearTimeout(sTO);
      }, 1000);

      // if(!this.isIOS() && window.innerWidth < 767) {
      //   this.closeMobileSearch();
      // }
    },
    getMainSettingsInfo() {
      const sTO = setTimeout(() => {
        this.setInputRightPosition();
        clearTimeout(sTO);
      }, 1000);
    },
    getSearchKeyword(searchKeyword) {
      this.searchKeyword = searchKeyword;
    },
    searchKeyword(val) {
      if(!val && !this.mobileSearchMode) {
        this.closeMobileSearch();
      }
    }
  },
  methods: {
    ...mapActions([
      "setCurrentMobilePanel",
      "setMapLoaded",
      "setMobileSearchShown",
      "setSearchKeyword",
      "searchThoughtByString"
    ]),
    componentInit() {
      this.searchSections = [
        {id: 0, label: "Results by keyword", selected: true},
        {id: 1,label: "Related content"}
      ];
      this.toggleSearchInput();
      setTimeout(() => {
        this.setInputRightPosition();
      }, 1000);

      this.showSearchInput = isAndroid || window.innerWidth < 768 ? false : true;
    },
    closeMobileSearch() {
      this.closeSearch();
      this.mobileSearchMode = false;

      if(
        !this.isIOS() ||
        this.isIOS() && window.innerWidth < 768
      ) {
        this.showSearchInput = false;
      }

      this.setMobileSearchShown(false);
      this.setCurrentMobilePanel(undefined);

      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
      const isFirefoxAndroid = isAndroid && isFirefox;

      if(isFirefoxAndroid) {
        this.$refs.mobileSearchInput.blur();
      }
    },
    closeSearch() {
      this.displayResults = false;
      this.relatedThoughts = [];
      this.thoughtsByKeyword = [];

      if(this.$refs.mobileSearchInput && window.innerWidth < 768 && !this.getTourShown) {
        this.$refs.mobileSearchInput.blur();
      }

      this.searchKeyword = undefined;
      this.setSearchKeyword(undefined);
      this.resetSearchTabSelections();
    },
    getSectionRecords(section) {
      return section.id === 0 ? this.thoughtsByKeyword.length : this.relatedThoughts.length;
    },
    getThoughtsByKeyword() {
      this.searchThoughtByString(this.searchKeyword);
    },
    navigateToThought(thoughtId) {
      this.setMapLoaded(false);

      this.$router.push({ name: "thought", params: { id: thoughtId } })
        .then(() => {
          this.closeMobileSearch();
        })
        .catch(err => {
          this.closeMobileSearch();
          this.setMapLoaded(true);
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (err.name != "NavigationDuplicated") {
            // But print any other errors to the console
            console.error(err);
          }
        });
    },
    resetSearchTabSelections() {
      this.searchSections.map(itm => {
        if(itm.id === 0) {
          itm.selected = true;
          this.selectedSection = itm;
        } else {
          itm.selected = false;
        }
      });
    },
    setInputRightPosition() {
      // const headerButtonsMenu = document.getElementsByClassName("header-buttons-menu")[0];
      // this.searchInputRight = `${headerButtonsMenu.offsetWidth}px`;
      // this.searchInputCloseRight = `${headerButtonsMenu.offsetWidth + 12}px`;
      this.searchInputRight = `${500}px`;
      this.searchInputCloseRight = `${512}px`;
    },
    toggleSearchInput() {
      this.showMapSearch = this.$route.name === "thought" || this.$route.name === "browseThought" ? true : false;
    },
    toggleSelectedSection(clickedTab) {
      if(!clickedTab.selected) {
        const newSections = this.searchSections.map(itm => {
          itm.selected = !itm.selected
          if(itm.selected) {
            this.selectedSection = itm;
          }
          return itm;
        });

        this.searchSections = newSections;
      }
    },
  }
}
</script>

<style lang="scss">
@import "../../stylesheets/search.module.scss";
</style>
