export const getMobileSearchShown = state => {
  return state.mobileSearchShown;
};
export const getSearchKeyword = state => {
  return state.searchKeyword;
};
export const getSearchResult = state => {
  return state.searchResult;
};
export const getMobileSearchSelected = state => {
  return state.mobileSearchSelected;
};