<template>
  <div class="bookmarks-indicator">
    <BasicIcon :iconName="sectionData.icon"></BasicIcon>
    <span class="selected-section-label no-select">{{ sectionData.label }}</span>
    <BasicIcon :iconName="'iconChevronRight'"
      :iconClasses="'chevron'"></BasicIcon>
  </div>
</template>

<script>
import BasicIcon from "../BasicIcon";

export default {
  components: {
    BasicIcon,
  },
  data () {
    return {
    }
  },
  name: "bookmarks-indicator",
  props:["sectionData"],
  mounted() {
    this.componentInit();
  },
  methods: {
    componentInit() {
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../stylesheets/bookmarks.module.scss";
</style>
