export const closeMobileMenu = ({commit}) => {
  commit("mutate", {
    property: "mobileMenuShown",
    value: false
  });
}
export const fetchFeaturedSearchContent = ({commit}) => {
  fetch(process.env.VUE_APP_FEATURED_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
  })
  .then(stream => {
    return stream.json();
  })
  .then(data => {
    commit("mutate", {
      property: "featuredContent",
      value: data
    });
  })
  .catch(error => {
    console.error(error);
  });
};
export const fetchFeaturedSearchLabel = ({commit}) => {
  fetch(process.env.VUE_APP_FEATURED_LABEL_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
  })
  .then(stream => {
    return stream.json();
  })
  .then(data => {
    commit("mutate", {
      property: "featuredContent",
      value: data
    });
  })
  .catch(error => {
    console.error(error);
  });
};
export const setPanelWidth = ({commit, state}, payload) => {
  const panelState = { ...state.panelsInfo };

  panelState[payload.panelInstance].width = payload.width;

  commit("mutate", {
    property: "panelsInfo",
    value: panelState
  });
};
export const setMobilePanelHeight = ({commit, state}, payload) => {
  const panelState = { ...state.mobilePanelsInfo };

  panelState[payload.panelInstance].height = payload.height;

  commit("mutate", {
    property: "mobilePanelsInfo",
    value: panelState
  });
};
// eslint-disable-next-line
export const setCurrentMobilePanel = ({commit, state}, payload) => {
  commit("mutate", {
    property: "currentMobilePanel",
    value: payload
  });
};
export const toggleMobileMenu = ({commit, state}) => {
  commit("mutate", {
    property: "mobileMenuShown",
    value: !state.mobileMenuShown
  });
}