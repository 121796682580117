import * as d3 from "d3";
import { forEach } from "lodash";
import { iconsMixin } from "../../../mixins/iconsMixin";
import { thoughtFilters, thoughtValidators } from "../../../mixins/thoughtOperations";

export default {
  mixins: [iconsMixin, thoughtFilters, thoughtValidators],
  methods: {
    renderOptions() {
      this.renderIcons();
      this.renderGates();
      this.renderTreeButtons();
    },
    renderGates() {
      d3.select(".map-canvas .thoughts-group").selectAll(".thought-gates *").remove();

      d3.select(".map-canvas").selectAll(this.thoughtNodeSelection)
        .each((itm, idx, elem) => {
          const nodeItm = d3.select(elem[idx]);
          this.renderGateIndicator(nodeItm);
        })
    },
    renderSiblingsOptions() {
      this.renderIcons();
      this.renderGates();
    },
    renderGateIndicator(nodeItm) {
      const gatesGroup = nodeItm.select(".thought-gates");
      const nodeItmProps = this.getSelectionProps(nodeItm);
      const hasChildren = nodeItm.data()[0].data.gates.has_children;
      const hasRelatedSiblings = nodeItm.data()[0].data.gates.has_siblings;
      const hasParents = nodeItm.data()[0].data.gates.has_parents;

      // eslint-disable-next-line
      let thoughtGate;

      const isFirefox = navigator.userAgent.search("Firefox") > -1;
      const isMobile = navigator.userAgent.search("Mobile") > -1;

      if(hasRelatedSiblings) {
        const isRelatedSibling = nodeItm.datum().thoughtRelatedSibling;

        thoughtGate = gatesGroup
          .append("path")
          .attr("d", isRelatedSibling ? this.iconCaretRight.d : this.iconCaretLeft.d)
          .attr("transform", () => {
            const xFactor = nodeItmProps.width - 20;

            return isRelatedSibling
              ? "translate(" + xFactor + ", -15) scale(0.0415)"
              : "translate(-18, -15) scale(0.0415)";
          });
      }

      if(hasParents) {
        const thoughtBoxProps = this.getSelectionProps(nodeItm.select(".thought-box rect"));

        thoughtGate = gatesGroup
          .append("path")
          .attr("d", this.iconCaretUp.d)
          .attr("transform", () =>  {
            const yGap = isFirefox && !isMobile ? 4.3 : 4.1;
            const xFactor = nodeItmProps.width * 0.5 - 22.5;
            const yFactor = thoughtBoxProps.height + yGap;

            return "translate(" + xFactor + ",-" + yFactor + ") scale(0.0415)";
          });
      }

      if(hasChildren) {
        thoughtGate = gatesGroup
          .append("path")
          .attr("d", this.iconCaretDown.d)
          .attr("transform", () =>  {
            const yGap = isFirefox && !isMobile ? 2.2 : 2.8;
            const xFactor = nodeItmProps.width * 0.5 - 22.5;
            return "translate(" + xFactor + ", " + yGap + ") scale(0.0415)";
          });
      }
    },
    renderIcons() {
      d3.selectAll(this.thoughtNodeSelection).select(".thought-icon").remove();

      d3.selectAll(this.thoughtNodeSelection)
        .append("g")
        .attr("class", "thought-icon")
        .append("svg:image")
        .attr("xlink:href", (itm) => {
          let iconVal;

          if(itm.data.icon && itm.data.icon.search("https") > -1) {
            iconVal = itm.data.icon;
          } else {
            iconVal = itm.data.icon ? itm.data.icon.replace("http", "https") : "";
          }
          return iconVal;
        })
        .attr("y", -11)
        .attr("height", 14)
        .attr("width", itm => {
          const widthValue = itm.data.icon ? 14 : 0;

          return widthValue;
        })
        .on("mousedown", itm => {
          this.$router.push({ name: "thought", params: { id: itm.data.thought_id } });
        })

      // Render icon when no records are returned from the BE
      d3.selectAll(this.thoughtNodeSelection)
        .each((itm, idx, elem) => {
          const thoughtBoxProps = this.getSelectionProps(d3.select(elem[idx]).select(".thought-box rect"));
          const thoughtNodeProps = this.getSelectionProps(d3.select(elem[idx]));

          if(d3.select(elem[idx]).classed("restricted")) {
              d3.select(elem[idx]).select(".thought-label")
                .style("fill", "#FFF");

              const restrictedIcon = d3.select(elem[idx]).select(".thought-icon")
                .append("path")
                .attr("d", this.iconRestricted)
                .style("fill", "#FFF");

                restrictedIcon.attr("transform", "translate(0," + -16 + ")")
                .attr("height", 19)
                .attr("width", 19);

              d3.select(elem[idx]).select(".thought-box rect")
                .attr("width", thoughtBoxProps.width + 30);

              d3.select(elem[idx]).select(".thought-label")
                .attr("transform", "translate(30,0)");

              const exitLink = d3.select(elem[idx]).select(".thought-texts")
                .append("text")
                .text("go to default thought")
                .style("font-size", "12px")
                .style("font-weight", "bold")
                .style("text-decoration", "underline")

              d3.select(elem[idx])
                .append("rect")
                .attr("height", thoughtNodeProps.height + 35)
                .attr("width", thoughtNodeProps.width + 35)
                .attr("x", -15)
                .attr("y", (thoughtNodeProps.height + 25) * -0.5)
                .style("opacity", 0)
                .on("mouseover", () => exitLink.style("fill", "#FFF"))
                .on("mouseout", () => exitLink.style("fill", "#000"))
                .on("mousedown", () => this.$router.push({ name: "thought", params: { id: undefined } }));

              const exitLinkProps = this.getSelectionProps(exitLink);

              exitLink.attr("transform", "translate("
                + (thoughtBoxProps.width * 0.5 - exitLinkProps.width * 0.5) + ",30)");
            }
        });
    },
    renderTreeButtons() {
      forEach(this.thoughtsData.thoughtParents,
        itm => {
          const parentNode = d3.select(".thought-node-" + itm.data.thought_id);

          parentNode.select(".thought-ctas").remove();

          const ctaGroups = parentNode.append("g")
            .attr("class", "thought-ctas")

          if(this.calculateSiblings(itm).length > 0) {
            this.renderTreeButton(itm, ctaGroups);
          }
        });
    },
    renderTreeButton(itm, ctaGroups) {
      const parentNode = d3.select(".thought-node-" + itm.data.thought_id);
      const parentNodeProps = this.getSelectionProps(parentNode);

      const treeButton = ctaGroups.append("g")
        .attr("class", "tree-button");

      treeButton
        .append("circle")
        .attr("r", 18)
        .style("fill", "transparent")
        .attr("transform", () =>  {
          const itmX = parentNodeProps.width * 0.5 - 16.5;
          const itmY = 17.5;
          return "translate(" + itmX + ", " + itmY + ")"
        });

      treeButton
        .append("circle")
        .attr("r", 9)
        .attr("class", "closed")
        .attr("transform", () =>  {
          const itmX = parentNodeProps.width * 0.5 - 16.5;
          const itmY = 17.5;
          return "translate(" + itmX + ", " + itmY + ")"
        });

      treeButton
        .append("path")
        .attr("d", this.iconPlus.d)
        .attr("class", "closed")
        .attr("transform", () =>  {
          const itmX = parentNodeProps.width * 0.5 - 24;
          const itmY = 10;
          return "translate(" + itmX + ", " + itmY + ") scale(0.03024193548)"
        });

      if(!this.isTouchDevice()) {
        treeButton
          .on("click", () => {
            this.toggleTreeSiblings(treeButton, itm);
          });
      } else {
        treeButton
          .on("touchstart", () => {
            this.toggleTreeSiblings(treeButton, itm);
          })
          .on("touchend", () => {
            let touchTimeout;

            clearTimeout(touchTimeout);
            touchTimeout = setTimeout(() => {
              d3.selectAll(".thought-links-placeholder path")
                .classed("highlight", false);
            }, 100);
          });
      }
    },
    toggleTreeSiblings(treeButton, itm) {
      d3.event.stopPropagation();

      d3.selectAll(".thought-parents " + this.thoughtNodeSelection)
        .each((parentItm, idx, elems) =>  {
          if(parentItm.data.thought_id !== itm.data.thought_id) {
            const nodeTreeButton = d3.select(elems[idx]).select(".tree-button");
            nodeTreeButton.select("path")
            .attr("d", this.iconPlus.d)
            .attr("class", "closed");

            const siblingsLinks = d3.selectAll(".thought-links-placeholder path[class^=\"thought-link-" + parentItm.data.thought_id + "\"]");
            parentItm.isOpen = false;
            d3.select(".thought-siblings-" + parentItm.data.thought_id).remove();
            siblingsLinks.remove();
          }
        });

      const currentIcon = treeButton.select("path").attr("d");
      const toggledIcon = currentIcon === this.iconClose.d
        ? this.iconPlus.d : this.iconClose.d;
      const isOpen = currentIcon === this.iconClose.d
        ? false : true;

      treeButton.select("path")
        .attr("d", toggledIcon);

      itm.isOpen = isOpen;
      treeButton.select("path").attr("class", itm.isOpen ? "open" : "closed");

      const siblingsInfo = this.calculateSiblings(itm);
      this.renderSiblings(itm, itm.isOpen ? siblingsInfo : []);
    }
  }
}
