<template>
  <div class="app-header">
    <div class="header-logo-wrapper"
      @click="goHome">
      <img class="logo-desktop" :src="headerLogo">
      <img class="logo-mobile" :src="headerLogoMobile">
    </div>

    <MobileHeaderMenu
      :buttonList="headerButtonsList"
      ref="mobileMenu" />

    <div class="mobile-header-buttons-group">
      <LabeledButton
        :class="'header-button-mobile-home'"
        :clickAction="goHome"
        :iconName="'iconHome'"></LabeledButton>
      <LabeledButton
        :class="'header-button-hamburguer'"
        :clickAction="toggleMobileMenuShown"
        :iconName="'iconHamburguer'"></LabeledButton>
    </div>

    <LabeledButton
        :buttonLabel="'SEARCH'"
        :buttonClasses="['type-a', 'bookmark-cta-search']"
        :class="{ selected: searchSelected }"
        :clickAction="toggleMobileSearch"
        :iconName="'iconSearch'"></LabeledButton>
  </div>
</template>

<script>
import { iconsMixin } from "../../mixins/iconsMixin";
import { iOSzoomEvents } from "../../mixins/iosTouchEvents";
import LabeledButton from "../Buttons/LabeledButton";
import MobileHeaderMenu from "./MobileHeaderMenu";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    LabeledButton,
    MobileHeaderMenu,
  },
  computed: {
    ...mapGetters([
      "getCurrentBookmarksSection",
      "getBookmarksShown",
      "getCurrentMobilePanel",
      "getMainSettingsInfo",
      "getMobileSearchSelected",
      "getSkinConfig",
      "getWindowInfo"
    ])
  },
  data () {
    return {
      buttonGroupData: [],
      headerButtonsList: [],
      headerLogo: "",
      headerLogoMobile: "",
      mobileMenuOpen: false,
      searchSelected: false,
      timelineButtonShown: false
    }
  },
  mixins: [iconsMixin, iOSzoomEvents],
  name: "app-header",
  mounted() {
    this.componentInit();
    this.preventTouchZoom();
    this.preventElementTouchZoom(this.$el);
  },
  watch: {
    "$route"() {
      this.hideMobileSearch();
    },
    getBookmarksShown(shown) {
      if(shown) {
        this.setMobileSearchSelected(false);
      }
    },
    getCurrentBookmarksSection(currentBookmarksSection) {
      this.setButtonSelected(currentBookmarksSection);
    },
    getCurrentMobilePanel(currentPanel) {
      this.setMobileSearchSelected(currentPanel === "search");
    },
    getMainSettingsInfo(mainSettingsInfo) {
      this.timelineButtonShown = mainSettingsInfo["show_timeline_button"];
      this.componentInit();
    },
    getMobileSearchSelected(mobileSearchSelected) {
      this.searchSelected = mobileSearchSelected;
    },
    getSkinConfig(skinConfig) {
      this.headerLogo = skinConfig["header-logo-desktop"].value;
      this.headerLogoMobile = skinConfig["header-logo-mobile"].value;
    },
    getWindowInfo() {
      this.componentInit();
      if(window.innerWidth > 767) {
        this.closeMobileMenu();
      }
    }
  },
  methods: {
    ...mapActions([
      "closeMobileMenu",
      "setBookmarksShown",
      "setCurrentBookmarksSection",
      "setCurrentMobilePanel",
      "setMobileSearchSelected",
      "setMobileSearchShown",
      "setTourShown",
      "toggleMobileMenu"
    ]),
    componentInit() {
      this.headerButtonsList = [
        {
          label: "home",
          icon: "iconHome",
          clickAction: this.goHome
        },
        {
          label: "pinned",
          icon: "iconPinned",
          clickAction: this.toggleBookmarks,
          clickParams: "pinned"
        },
        {
          label: "history",
          icon: "iconHistory",
          clickAction: this.toggleBookmarks,
          clickParams: "history"
        },
        {
          label: "timeline",
          icon: "iconTimeline",
          clickAction: this.goToTimeline,
        },
        {
          label: "tour",
          icon: "iconTour",
          clickAction: this.startTour
        }
      ];

      if(!this.timelineButtonShown) {
        this.headerButtonsList = [
          {
            label: "home",
            icon: "iconHome",
            clickAction: this.goHome
          },
          {
            label: "pinned",
            icon: "iconPinned",
            clickAction: this.toggleBookmarks,
            clickParams: "pinned"
          },
          {
            label: "history",
            icon: "iconHistory",
            clickAction: this.toggleBookmarks,
            clickParams: "history"
          },
          {
            label: "tour",
            icon: "iconTour",
            clickAction: this.startTour
          }
        ];
      }
    },
    goHome() {
      this.$router.push({ path: "/" }).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (err.name != "NavigationDuplicated") {
        // But print any other errors to the console
          console.error(err);
        }
      });
      this.setBookmarksShown(false);
      this.setCurrentMobilePanel(undefined);
      this.setMobileSearchSelected(false);
      this.setMobileSearchShown(false);
    },
    goToMobileTimeline() {
      this.toggleMobileMenu();
    },
    goToTimeline() {
      this.$router.push({ path: "/timeline" }).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (err.name != "NavigationDuplicated") {
        // But print any other errors to the console
          console.error(err);
        }
      });
      this.setTourShown(false);
      this.setBookmarksShown(false);
      this.setMobileSearchShown(false);
      this.setCurrentMobilePanel(undefined);
      this.setMobileSearchSelected(false);
      this.toggleMobileMenu();
    },
    hideMobileSearch() {
      this.setMobileSearchShown(false);
      this.setCurrentMobilePanel(undefined);
    },
    setButtonSelected(selectedBookmarksSection) {
      this.headerButtonsList.forEach(button => {
        if(button.label === selectedBookmarksSection && !button.selected) {
          button.selected = true;
        } else {
          delete button.selected;
        }
      });
    },
    toggleMobileSearch() {
      if(!this.searchSelected) {
        this.setMobileSearchSelected(true);
        this.setBookmarksShown(false);
        this.setMobileSearchShown(true);
        this.setCurrentMobilePanel("search");
      } else {
        this.hideMobileSearch();
      }
    },
    startTour() {
      this.setTourShown(true);
      this.setBookmarksShown(false);
      this.setMobileSearchShown(false);
      this.toggleMobileMenu();
      this.goHome();
    },
    toggleBookmarks(event, bookmarkSection) {
      this.setButtonSelected(this.getCurrentBookmarksSection);
      this.setBookmarksShown(!this.getBookmarksShown);
      this.setCurrentBookmarksSection(bookmarkSection);
      this.toggleMobileMenu();
      if(this.$route.name === "timeline") {
        this.goHome();
      }
    },
    toggleMobileMenuShown() {
      this.setBookmarksShown(false);
      this.setMobileSearchShown(false);
      this.toggleMobileMenu();
    }
  }
}
</script>

<style lang="scss">
@import "../../stylesheets/header.module.scss";
</style>
