import * as d3 from "d3";
import * as generalConfig from "../../../configs/generalConfig";

export default {
  methods: {
    navigateToClicked(itm) {
      this.$router.push({ name: "thought", params: { id: itm.data.thought_id } });
    },
    dimUnselectedThoughts(itm) {
      const unselectedThoughts = d3.selectAll(this.thoughtNodeSelection + ":not(.thought-node-" + itm.data.thought_id + ")");

      d3.select(".selected")
        .select(".tree-button").remove();

      d3.select(".selected")
        .selectAll(".thought-gates *").remove();

      d3.select(".thought-links-placeholder").style("display", "none");

      unselectedThoughts
        .transition()
        .duration(generalConfig.thoughtsFadeTime)
        .style("opacity", 0);

        this.navigateToClicked(itm);
    },
    panSelectedToCenter(itm) {
      const clickedThought = d3.select(".thought-node-" + itm.data.thought_id);
      const clickedThoughtProps = this.getSelectionProps(clickedThought);

      const sidePanelInstance = d3.select(".basic-panel.right .panel-wrapper");
      const sidePanelGap = sidePanelInstance.empty() ? 0 : sidePanelInstance.node().getBoundingClientRect().width;
      const nodeX = ((window.innerWidth * 0.5 - clickedThoughtProps.x) - clickedThoughtProps.width * 0.5) - 15 + this.getZoomInfo.x - sidePanelGap * 0.5;
      const nodeY = (window.innerHeight - clickedThoughtProps.height) * 0.5 - clickedThoughtProps.y + 40 + this.getZoomInfo.y;

      d3.selectAll(this.thoughtNodeSelection + ".selected").style("opacity", 1);

      const zIdentity = d3.zoomIdentity.translate(nodeX, nodeY).scale(1);

      d3.select(".map-canvas")
        .transition()
        .duration(generalConfig.panningTime)
        .ease(d3.easeQuadInOut)
        .call(this.getZoomEvent.transform, zIdentity)
        .on("end", () => {
          this.dimUnselectedThoughts(itm);
        });
    },
    setThoughtsActions() {
      if(!this.isTouchDevice()) {
        d3.selectAll(this.thoughtNodeSelection)
          .on("click", itm => {
            if(d3.select(d3.event.target).attr("class") && !itm.selected) {
              this.toggleSelected(itm);
              this.panSelectedToCenter(itm);
            }
        })
        .on("mousedown", (itm, idx, elem) => {
          d3.select(elem[idx]).style("cursor", "move");
        })
        .on("mouseup", (itm, idx, elem) => {
          d3.select(elem[idx]).style("cursor", "pointer");
        })
        .on("mouseover", itm => {
          d3.selectAll("path[class*=\"" + itm.data.thought_id + "\"]")
            .classed("highlight", true)
            .raise();
        })
        .on("mouseout", (itm, idx, elem) => {
          d3.select(elem[idx]).style("cursor", "pointer");

          d3.selectAll(this.thoughtLinkSelection)
            .classed("highlight", false);
          d3.select(".thought-node-" + itm.data.thought_id)
            .classed("highlight", false);
        });
      } else {
        let touchStartCords;

        d3.selectAll(this.thoughtNodeSelection)
          .on("touchstart", () => {
            const clientX = d3.event.changedTouches[0].clientX;
            const clientY = d3.event.changedTouches[0].clientY;

            touchStartCords = { clientX, clientY };
          })
          .on("touchend", itm => {
            const clientX = d3.event.changedTouches[0].clientX;
            const clientY = d3.event.changedTouches[0].clientY;

            const clientX_a = touchStartCords.clientX + 2.5;
            const clientX_b = touchStartCords.clientX - 2.5;
            const clientY_a = touchStartCords.clientY + 2.5;
            const clientY_b = touchStartCords.clientY - 2.5;

            if(
              clientX < clientX_a && clientX > clientX_b &&
              clientY < clientY_a && clientY > clientY_b ||
              clientX === touchStartCords.clientX || clientY === touchStartCords.clientY
            ) {
              if(d3.select(d3.event.target).attr("class") && !itm.selected) {
                this.panSelectedToCenter(itm);
              }
            }

            d3.selectAll(".thought-links-placeholder path")
              .classed("highlight", false);
            this.toggleSelected(itm);
          });
      }
    },
    toggleSelected(itm) {
      const clickedThought = d3.select(".thought-node-" + itm.data.thought_id);

      d3.select(".selected")
        .classed("selected", false);
      clickedThought.classed("selected", true);
    }
  }
}